import { Password } from '@mui/icons-material';
import React, { Component } from 'react';
import { Stepper } from 'react-form-stepper';
import i18next, { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import './SingUp.css';

class ServiceDetails extends Component {
  continue = e => {
    e.preventDefault(); 
    const isWebsite = this.props.validateWebsite();
    const isDescription = this.props.validateDescription();
    const isSocialMedia = this.props.validateSocialMedia();
    console.log(isWebsite, isDescription, isSocialMedia)

    if (isWebsite && isDescription && isSocialMedia) {
      this.props.nextStep();
    }
  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }


  render() {
    const { t } = this.props;
    const { 
      website,
      describe,
      socialmedia,
      handleChange, 
      validateWebsite,
      validateDescription,
      validateSocialMedia,
      isErrorDescription,
      isErrorWebsite,
      isErrorSocialMedia,
      errorMessageDescription,
      errorMessageWebsite,
      errorMessageSocialMedia,
    } = this.props;

    return (
      <div className='form'>
        <form>

          <Stepper
            steps={[{ label: t("Summary_company_personal_details") }, { label: t('Summary_company_information')}, { label: t('Summary_company_summary') }]}
            activeStep={2}
            styleConfig={{
              activeBgColor: '#2b7cff',
              activeTextColor: '#fff',
              inactiveBgColor: '#fff',
              inactiveTextColor: '#2b7cff',
              completedBgColor: '#fff',
              completedTextColor: '#2b7cff',
              size: '3em'
            }}
            className={'stepper'}
            stepClassName={'stepper__step'}
          />

          <div className='form-group'>
          <div className='form-group__element'>
              <label htmlFor='website' className='form-group__label'>
              {t("ServiceDetails_company_website")} {t("CourseDeatils_phone_number_not_obligatory")}
              </label>
              <input type='url' placeholder='ex. https://www.snowypine.ro/en/home.html' value={website} name='website' onChange={handleChange('website')} className='form-group__input' />
              <p className='error'>{isErrorWebsite && errorMessageWebsite}</p>
            </div>
            <div className='form-group__element'>
              <label htmlFor='describtion' className='form-group__label'>
              {t("ServiceDetails_company_decription")} {t("CourseDeatils_phone_number_obligatory")}
              </label>
                <textarea type='text' rows="7" cols="50" placeholder='ex. A vállalkozásunk teljeskörű házfelújításokat vállal, legyen szó külső, vagy belső munkálatokról.
                ex. Compania noastră se ocupă de renovarea completă a locuinței, fie că este vorba de renovare exterioară sau interioară.' value={describe} name='describe' onChange={handleChange('describe')} onBlur={validateDescription} className='form-group__input'/>
              <p className='error'>{isErrorDescription && t(errorMessageDescription)}</p>
            </div>
            <div className='form-group__element'>
              <label htmlFor='socialmedia' className='form-group__label'>
              {t("ServiceDetails_company_social_media_link")}  {t("CourseDeatils_phone_number_not_obligatory")}
              </label>
              <input type='url'  placeholder='ex. https://www.facebook.com/' value={socialmedia} name='socialmedia' onChange={handleChange('socialmedia')} className='form-group__input' />
              <p className='error'>{isErrorSocialMedia && errorMessageSocialMedia}</p>
            </div>
          </div>
          <div style={{textAlign: 'center'}}>
          <button className='buttons__button buttons__button--back' onClick={this.back}>{t("Regist_provider_steps_back")}</button>
            <button className='buttons__button buttons__button--next' onClick={this.continue}>{t("Regist_provider_steps_next")}</button>
          </div>
        </form>
      </div>
    )
  }
}

export default withTranslation()(ServiceDetails);