import React, { useState, useEffect, createContext, useMemo } from "react";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import Footer from "./components/Navbar/Footer/Footer";
import TermsAndConditions from "./components/Navbar/Footer/TermsAndConditions";
import Login from "./components/Navbar/Pages/Login/Login";
import LoginAll from "./components/Navbar/Pages/Login/LoginAll";
import LoginProvider from "./components/Navbar/Pages/Login/LoginProvider";
import SignUp from "./components/Navbar/Pages/SignUp/SingUp";
import SignUpProvider from "./components/Navbar/Pages/SignUp/SignUpProvider";
import Home from "./components/Navbar/Pages/Home";
import SearchPage from "./components/Navbar/Pages/SearchPage";
import RegistrationPage from "./components/Navbar/Pages/RegistrationPage";
import HelpPage from "./components/Navbar/Help/HelpPage";
import Form from "./components/Navbar/Pages/SignUp/Form";
import Profile from "./components/Navbar/Pages/Profile";
import UserProfile from "./components/Navbar/Pages/UserProfile";
import { ProfileContext } from "./profile";
import { LocationContext } from "./location";
import { LanguageContext } from "./languages";
import { UserRoleContext } from "./userRole"
import { useTranslation } from "react-i18next";
import MyProfile from "./components/Navbar/Pages/Login/MyProfile";
import CompanyProfile from "./components/Navbar/Pages/CompanyProfile";
import AddRequest from "./components/Navbar/Pages/AddRequest";
import ContextClass from "./components/Navbar/ContextClass";
import ReactGA from 'react-ga';
import Test from "./components/Navbar/Pages/Test";
import TestCat from "./components/profile-components/TestCat";
import API, { WebURL } from "./api";
import {DataProviderContext} from "./dataprovider";
import {GetBrowserLanguage} from './utils/utils';
import CompanyLocationEdit from "./components/profile-components/CompanyLocationEdit";
import useAnalyticsEventTracker from '../src/components/Analytics/useAnalyticsEventTracker';
import UnderConstruction from "./components/Navbar/Pages/UnderConstruction";

//elso proba go analytics
// const TRACKING_ID="UA-267961577-1"; 
// ReactGA.initialize(TRACKING_ID);
const TRACKING_ID = "UA-267961577-1";
ReactGA.initialize("TRACKING_ID");

function App(props) {

  const [profile, setProfile] = useState(null); // null - noone is loged in

  useEffect(() => {
    const loggedUser = localStorage.getItem('profile');
    if (loggedUser) {
      setProfile(JSON.parse(loggedUser));
    }
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  var dataProvider = props.dprovider;
  var lang = GetBrowserLanguage();
  if(lang != 'en' && lang != 'hu' && lang != 'ro') lang = 'ro';
  lang = dataProvider.GetAppLanguage(lang);

  console.log('App init, using language:', lang);
  const [uilanguage, setUILanguage] = useState(lang);
  const [appInitState, setAppInitState] = useState(0);


  useEffect(() => {
    dataProvider.Init(setAppInitState);
  }, [dataProvider]);

  setTimeout(() => {
    if(appInitState <= 0) setAppInitState(1);
  }, 1500);
  
  var logoImg = WebURL + "/img/logo.png";
  if(appInitState === -1) {
    return (<div><img src={logoImg} /></div>);
  }

  if(appInitState === 0) {
      return (<div><img src={logoImg} /></div>);
  }

  return (
    <ProfileContext.Provider value={[profile, setProfile]}>
      <LanguageContext.Provider value={[uilanguage, setUILanguage]}>
        <DataProviderContext.Provider value={dataProvider}>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/searchpage" element={<SearchPage />} />
            <Route path="/registration" element={<RegistrationPage />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/userprofile" element={<UserProfile />} />
            { /*  ez a profile.role valojaban nem kell, a companyprofile-ban kell ellenorizni a belepest es eliranyitani ha nem jo */ }
            {profile && profile.role === 'provider' && <Route path="/companyprofile" element={<CompanyProfile />} /> }
            <Route path="/testCat" element={<TestCat />} />
            <Route path="/request" element={<ContextClass />} />
            <Route path="/test" element={<Test />} />
            <Route path="/LoginAll" element={<LoginAll />} />
            <Route path="/login" element={<UnderConstruction />} />
            <Route path="/loginProvider" element={<LoginProvider />} />
            <Route path="/MyProfile" element={<MyProfile />} />
            <Route path="/SingUp" element={<SignUp />} />
            <Route path="/SingUpProvider" element={<SignUpProvider />} />
            <Route path="/HelpPage" element={<HelpPage />} />
            <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
            <Route path="/companyprofile/location/edit" element={<CompanyLocationEdit />} />
            {/* <Route path="/statistics" element={<Statistics />} />
          <Route path="/CraftMenList" element={<CraftMenList />} />
          <Route path="/services" element={<OurServices />} />
          <Route path="/login" element={<Login />} />
          <Route path="/SingUp" element={<SignUp />} /> */}
          </Routes>
          <Footer />
        </DataProviderContext.Provider>
      </LanguageContext.Provider>
    </ProfileContext.Provider>
  );
}

export default App;
