import React from 'react'

const RegistrationPage = () => {
  return (
    <div>
      <h2>Felhasznalo</h2>
    </div>
  )
}

export default RegistrationPage
