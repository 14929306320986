import React from "react";
import { Carousel } from 'react-bootstrap';
import './Hero.css';
// import image1 from '../../../assets/7.jpg';
// import image2 from '../../../assets/6.jpg';
// import image3 from '../../../assets/4.jpg';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import 'react-multi-carousel/lib/styles.css';



const Hero = ({imageSource, name, text, button}) => {
  const { t } = useTranslation();
  return (
    <div className="hero">
      <img className='heropictures' src={imageSource} alt=""/>
        <div className="content">
            <h1>{name}</h1>
            <p className="text">{text}</p>
            <Link to='/SearchPage'>
            <button className="btn2">{t("frontPage_hero_buttom")}</button>
            </Link>
        </div>
    </div>
  //   <Carousel>
  //   <Carousel.Item interval={1000}>
  //     <img
  //       className="d-block w-100"
  //       src={image1}
  //       alt="First slide"
  //     />
  //     <Carousel.Caption>
  //     <div className="content">
  //     <h1>Segítség baj van!</h1>
  //           <p className="text">Javítjuk, szereljük, cseréljük a vízvezetékeket a villanyt, akár egy háztartási gépet is. Forduljon bizalommal a szerelők csapatához.</p>
  //           <button className="btn2">Kérek segítséget!</button>
  //     </div>
  //     </Carousel.Caption>
  //   </Carousel.Item>
  //   <Carousel.Item interval={500}>
  //     <img
  //       className="d-block w-100"
  //       src={image2}
  //       alt="Second slide"
  //     />
  //     <Carousel.Caption>
  //       <h3>Second slide label</h3>
  //       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
  //     </Carousel.Caption>
  //   </Carousel.Item>
  //   <Carousel.Item>
  //     <img
  //       className="d-block w-100"
  //       src={image3}
  //       alt="Third slide"
  //     />
  //     <Carousel.Caption>
  //       <h3>Third slide label</h3>
  //       <p>
  //         Praesent commodo cursus magna, vel scelerisque nisl consectetur.
  //       </p>
  //     </Carousel.Caption>
  //   </Carousel.Item>
  // </Carousel>
  );
}

export default Hero;



