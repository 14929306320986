import React, { Component, useContext } from 'react';

import PersonalDetails from './PersonalDetails';
import CourseDetails from './CourseDetails';
import Summary from './Summary';
import ServiceDetails from './ServiceDetails';
import { APIQuery } from '../../../../api';
import { Redirect } from "react-router-dom";
import { DataProviderContext } from '../../../../dataprovider';
import { withTranslation } from 'react-i18next';
import * as serviceWorker from './ServiceWorker';
import { type } from '@testing-library/user-event/dist/type';
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";

//Sample data

const regexpUrl = /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
const regexpEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const regexpNumber = /^\d*$/;
const regexpAlfaNumeric = /^[\w\-\.\s]+$/;

class Form extends Component {
  static contextType = DataProviderContext;
 
  state = {
    step: 1,
    companyname: '',
    email: '',
    password: '',
    passwordVerify: '',
    contactPhone: '',
    contactemail: '',
    cui: '',
    address: '',
    bankName: 'Example Bank',
    zip: '',
    bankAccount: 'RO06BTRLRONCRT0642428797',
    website: '',
    describe: 'This is an example description',
    socialmedia: '',
    level: '',
    accountType: '',
    isErrorCompanyName: true,
    isErrorEmail: true,
    isErrorPassword: true,
    isErrorPasswordVerify: true,
    isErrorPhone: true, 
    isErrorContactEmail: true,
    isErrorCui: true,
    isErrorAddress: true,
    isErrorbankName: true,
    isErrorbankAccount: true,
    isErrorWebsite: true,
    isErrorAccountType: true,
    isErrorDescription: true,
    isErrorSocialMedia: true,
    errorMessageCompanyName: '',
    errorMessageEmail: '',
    errorMessagePassword: '',
    errorMessagePasswordVerify: '',
    errorMessagePhone: '',
    errorMessageContactEmail: '',
    errorMessageCui: '',
    errorMessageAddress: '',
    errorMessagebankName: '',
    errorMessagebankAccount: '',
    errorMessageWebsite: '',
    errorMessageAccountType: '',
    errorMessageDescription: '',
    errorMessageSocialMedia: '',
    type: '',
    value: '',
    selCityObj: null,
    isErrorSignup: false,
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    })
  }

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    })
  }

  handleChangeRadioGroup = (e) => {
    
    this.state.type(e.target.value);
  }

  // handleChange = (event) => {
  //   this.state.value=event.target.value;
  // };

  handleChange = input => e => {

    console.log('handleChange', e.target.value, input);
    this.setState({
      [input]: e.target.value
    })

    if(input === 'selCityObj') {
      // console.log("--------------", e.target.value);
      // TODO
    }

    if (input === 'accountType') {
      const accType = this.state.accountType
      if (accType !== "") {
        this.setState({
          isErrorAccountType: false
        })
      }
    }
    if (input === 'companyname') {
      if (this.state.companyname.match(regexpAlfaNumeric)) {
        this.setState({
          isErrorCompanyName: false
        })
      }
    }
   if (input === 'contactPhone') {
      if (this.state.contactPhone.match(regexpNumber)) {
        this.setState({
          isErrorPhone: false
        })
      }
    }
   if (input === 'contactemail') {
      if (this.state.contactemail.match(regexpEmail)) {
        this.setState({
          isErrorContactEmail: false
        })
      }
    }
  if (input === 'cui') {
      if (this.state.cui.match(regexpNumber)) {
        this.setState({
          isErrorCui: false
        })
      }
    }
    if (input === 'address') {
      if (this.state.address.length >= 1) {
        this.setState({
          isErrorAddress: false
        })
      }
    }

    if (input === 'bankName') {
      if (this.state.bankName.match(regexpAlfaNumeric)) {
        this.setState({
          isErrorbankName: false
        })
      }
    }
    if (input === 'bankAccount') {
      if (this.state.bankAccount.match(regexpAlfaNumeric)) {
        this.setState({
          isErrorbankAccount: false
        })
      }
    }
    if (input === 'website') {
      if (this.state.website.match(regexpUrl)) {
        this.setState({
          isErrorWebsite: false
        })
      }
    }

    if (input === 'describe') {
      if (this.state.describe.length >= 10) {
        this.setState({
          isErrorDescription: false
        })
      }
    }
    if (input === 'socialmedia') {
      if (this.state.socialmedia.match(regexpUrl)) {
        this.setState({
          isErrorSocialMedia: false
        })
      }
    }

    if (input === 'email') {
      if (this.state.email.match(regexpEmail)) {
        this.setState({
          isErrorEmail: false
        })
      }
    }

    if (input === 'password') {
      if (this.state.password.length >= 6) {
        this.setState({
          isErrorPassword: false
        })
      }
    }
    else if (input === 'passwordVerify') {
      if (this.state.passwordVerify.length >= 6) {
        this.setState({
          isErrorPasswordVerify: false
        })
      }
    }
    
  }
  

  // addLevel = e => {
  //   const levelChosen = e.target.value;
  //   this.setState({
  //     level: levelChosen
  //   });
  // };

  // addCourse = data => {
  //   const id = data.map(v => v.id);
  //   this.setState({
  //     courses: id
  //   })
  // };

  validateCompanyName = () => {
    if (!this.state.companyname.match(regexpAlfaNumeric) || this.state.companyname.length < 5) {
      this.setState({
        isErrorCompanyName: true,
        errorMessageCompanyName: 'Singupprovider_personal_details_error_companyname'
      });
      return false;
    }
    return true;
  }

  validateEmail = () => {
    var email = this.state.email;
    var mailformat = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var test2 = mailformat.test(email);
    console.log(test2)
    if(!test2 && email.length < 5){
      this.setState({
        isErrorEmail: true,
        errorMessageEmail: 'Singupprovider_personal_details_error_email'
      });
      return false;
    }
    return true;
  } 

  validateAccountType = () => {
    console.log(this.state.accountType)

    if (this.state.accountType == '') {
      this.setState({
        isErrorAccountType: true,
        errorMessageAccountType: 'Ki kell valasztani valamelyiket!'
      });
      return false;
    }
    return true;
  }

  validatePhone = () => {
    var contactPhone = this.state.contactPhone;
    // var contactPhoneValid = /^(\(\d{3}\)|\d{3})-?\d{3}-?\d{4}$/;
    // var test = contactPhoneValid.test(contactPhone); 
    if (contactPhone.length < 10 || contactPhone.length > 15 || !contactPhone.match(regexpNumber)) {
      this.setState({
        isErrorPhone: true,
        errorMessagePhone: 'Singupprovider_personal_details_error_contactPhone_number'
      });
      return false;
    }
    return true;
  }

  validateContactEmail = () => {
    var contactemail = this.state.contactemail;
    var test2 = regexpEmail.test(contactemail);
    // console.log(test2)
    if(!test2 && contactemail.length < 5){
      this.setState({
        isErrorContactEmail: true,
        errorMessageContactEmail: 'Singupprovider_personal_details_error_email'
      });
      console.log(this.state.isErrorContactEmail)
      return false;
    }
    return true;
  } 

  //Regex 0-99 szamok
  validateCui = () => {
    var cui = this.state.cui;
    var CuiValid = /\b([0-9]|[1-9][0-9])\b/;
    var test = CuiValid.test(cui); 
    if (!(cui.length === 8) && !test) {
      this.setState({
        isErrorCui: true,
        errorMessageCui: 'Singupprovider_personal_details_error_cui'
      });
      return false;
    }
    return true;
  }

  //A-Z matches any uppercase letter.
  //a-z matches any lowercase letter.
  //0-9 matches any digit.
  //space
  validateAddress = () => {
    var address = this.state.address;
    // var AddressValid = /[A-Za-z0-9\s!@#$%^&*()_+=-`~\\\]\[{}|';:/.,?><]*$/;
    // var test = AddressValid.test(address); 
    if (address.length < 5) {
      this.setState({
        isErrorAddress: true,
        errorMessageAddress: 'Singupprovider_personal_details_error_address'
      });
      return false;
    }
    return true;
  }

  validateZIP = () => {
    var zip = this.state.zip;
    console.log(zip)
    if (zip.length < 6) {
      this.setState({
        isErrorZIP: true,
        errorMessageZIP:  'Singupprovider_personal_details_error_zip',
        errorMessageAddress: 'Singupprovider_personal_details_error_sediu_address'
      });
      return false;
    }
    return true;
  }
  validatebankName = () => {
    if (this.state.bankName.length < 2 && this.state.accountType != 'individual') {
      this.setState({
        isErrorbankName: true,
        errorMessagebankName: 'Singupprovider_personal_details_error_bank_name'
      });
      return false;
    }
    return true;
  }
  validatebankAccount = () => {
    var bankAccount = this.state.bankAccount;
    // var AccountValid = /[A-Za-z0-9\s!@#$%^&*()_+=-`~\\\]\[{}|';:/.,?><]*$/;
    // var test1 = AccountValid.test1(bankAccount);
    if (!(bankAccount.length === 24) && this.state.accountType != 'individual' ) {
      this.setState({
        isErrorbankAccount: true,
        errorMessagebankAccount: 'Singupprovider_personal_details_error_bank_account'
      });
      return false;
    }
    return true;
  }
  validateWebsite = () => {
    var website = this.state.website;
    if(website.length > 0) {
      var siteformat = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
      var test = siteformat.test(website);
      console.log(test)
      if(!test){
        this.setState({
          isErrorWebsite: true,
          errorMessageWebsite: 'URL kell'
        });
        return false;
      }
    }

    return true;
  } 

  validateDescription = () => {
    if (this.state.describe.length < 10) {
      this.setState({
    
        isErrorDescription: true,
        errorMessageDescription: 'Singupprovider_personal_details_error_company_dedscription'
      });
      return false;
    }
    return true;
  }

  validateSocialMedia = () => {
    var socialmedia = this.state.socialmedia;
    if (socialmedia > 0) {
      var siteformat = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
      var test = siteformat.test(socialmedia);
      console.log(test)
      if(!test){
        this.setState({
          isErrorSocialMedia: true,
          errorMessageSocialMedia: 'URL kell'
        });
        return false;
      }
    }
    return true;
  } 

  //validate password nagybetu kisbetu szam karaketr  lenght
  validatePassword = () => {
    var pass = this.state.password;
    var reg = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$+%&? "]).*$/;
    var test = reg.test(pass);
    if (!test && pass.length < 8) {
      this.setState({
        isErrorPassword: true,
        errorMessagePassword: 'Singupprovider_personal_details_error_password'
      });
      return false;
    }
    return true;
  } 
  //passavrodverifaction nem ugyanaz
  validatePasswordVerify = () => {
    var pass = this.state.password;
    var passVerify = this.state.passwordVerify;
    console.log(pass, passVerify, pass === passVerify)
    if (!(pass === passVerify)) {
      this.setState({
        isErrorPasswordVerify: true,
        errorMessagePasswordVerify: 'Singupprovider_personal_details_error_password_verify'
      });
      return false;
    }
    return true;
  } 



  submitData = e => {
    e.preventDefault();
    const state = this.state;
    const props_class = this.props;

    var sha1 = require('sha1');

    var data = {
      // Name, email, type (l = 1)
      name: state.companyname,
      regemail: state.email,
      type: (state.accountType === 'individual') ? 0 : 1,
      // Password (l = 2)
      pw: sha1(state.password),
      // Address (l=3)
      cityid: state.selCityObj.id,
      address: state.address,
      zip: state.zip,
      // Contact (l=4 - contactPhone, l5 - email)
      c_phone: state.contactPhone,
      c_email: state.email,
      // Fiscal (l=6 if type is 1 alias 'company')
      fiscal: {
        cui: state.cui,
        account: state.bankAccount,
        bank: state.bankName,
      },
    };

    // console.log(data);
    APIQuery(
      '/register/s',
      {data: data},
      (data) => {
        console.log(data);
        console.log(props_class.history);
        console.log(props_class);
        //redirect 
        // props_class.history.push("/");
        window.location='/LoginProvider';
        // return useNavigate("/LoginProvider");
        // TODO: sikeres feliratkozas, helyes uzenet megjelenitese
      },
      err => {
        console.error(err, 'Error field group: ' + err.l);
        this.setState({
          isErrorSignup: true
        });
        //redirect 
        // TODO: err.ec kod kezelese es megfelelo nyelven hibauzenet megjelenitese
      }
    );
  }

  componentDidMount() {
  }

  render() {
    var dataProvider = this.context;
    var uiLang = dataProvider.GetAppLanguage();

    const props_history = this.props.history;

    const { t } = this.props;
    const {
      accountType,
      step,
      companyname,
      email,
      password,
      passwordVerify,
      contactPhone,
      contactemail,
      cui,
      cityObj,
      address,
      zip,
      bankName,
      bankAccount,
      website,
      describe,
      socialmedia,
      isErrorAccountType,
      isErrorCompanyName,
      isErrorEmail,
      isErrorPassword,
      isErrorPasswordVerify,
      isErrorPhone,
      isErrorContactEmail,
      isErrorCui,
      isErrorAddress,
      isErrorZIP,
      isErrorbankName,
      isErrorbankAccount,
      isErrorWebsite,
      isErrorDescription,
      isErrorSocialMedia,
      errorMessageAccountType,
      errorMessagePassword,
      errorMessagePasswordVerify,
      errorMessageCompanyName,
      errorMessageEmail,
      errorMessagePhone,
      errorMessageContactEmail,
      errorMessageCui,
      errorMessageAddress,
      errorMessageZIP,
      errorMessagebankName,
      errorMessagebankAccount,
      errorMessageWebsite,
      errorMessageDescription,
      errorMessageSocialMedia,
      isErrorSignup
    } = this.state;

    // const coursesOptions = coursesData.map(el => ({
    //   course: el.courseName,
    //   id: el.id,
    //   category: el.category
    // }));

    // const coursesChosen = coursesData.filter(el => courses.includes(el.id));
    // const coursesChosenSummary = coursesChosen.map(el => (
    //   <p key={el.id}>
    //     {el.courseName} - {el.category} 
    //   </p>
    // ));

    // const chosenLevel = level;
    
    // const levelOptions = levelsData.map((el, index) => (
    //   <option key={index} value={el}>
    //     {el}
    //   </option>
    // ));
    
    // TODO: change steps to 1-2
    switch(step) {
      case 1: 
        return (
          <PersonalDetails 
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            companyname={companyname}
            email={email}
            password={password}
            passwordVerify={passwordVerify}
            contactPhone={contactPhone}
            validateCompanyName={this.validateCompanyName}
            validateEmail={this.validateEmail}
            validatePassword={this.validatePassword}
            validatePasswordVerify={this.validatePasswordVerify}
            validateZIP={this.validateZIP}
            isErrorCompanyName={isErrorCompanyName}
            isErrorEmail={isErrorEmail}
            isErrorPassword={isErrorPassword}
            isErrorPasswordVerify={isErrorPasswordVerify}
            errorMessageCompanyName={errorMessageCompanyName}
            errorMessageEmail={errorMessageEmail}
            errorMessagePassword={errorMessagePassword}
            errorMessagePasswordVerify={errorMessagePasswordVerify}
          />
        )
      case 2:
        return (
          <CourseDetails 

            dataProvider={dataProvider}
            uiLang={uiLang}

            nextStep={this.nextStep}
            handleChange={this.handleChange}
            cityObj={cityObj}
            address={address}
            zip={zip}
            accountType={accountType}
            contactPhone={contactPhone}
            contactemail={contactemail}
            describe={describe}
            cui={cui}
            bankName={bankName}
            bankAccount={bankAccount}
            validateAccountType={this.validateAccountType}
            validatePhone={this.validatePhone}
            validateContactEmail={this.validateContactEmail}
            validateCui={this.validateCui}
            validateDescription={this.validateDescription}
            validateAddress={this.validateAddress}
            validatebankName={this.validatebankName}
            validatebankAccount={this.validatebankAccount}
            prevStep={this.prevStep}
            validateZIP={this.validateZIP}
            isErrorAccountType={this.validateAccountType}
            isErrorPhone={isErrorPhone}
            isErrorCui={isErrorCui}
            isErrorContactEmail={isErrorContactEmail}
            isErrorAddress={isErrorAddress}
            isErrorZIP={isErrorZIP}
            isErrorbankName={isErrorbankName}
            isErrorbankAccount={isErrorbankAccount}
            isErrorDescription={isErrorDescription}
            errorMessageAccountType={isErrorAccountType}
            errorMessagePhone={errorMessagePhone}
            errorMessageContactEmail={errorMessageContactEmail}
            errorMessageCui={errorMessageCui}
            errorMessageAddress={errorMessageAddress}
            errorMessageZIP={errorMessageZIP}
            errorMessagebankName={errorMessagebankName}
            errorMessagebankAccount={errorMessagebankAccount}
            errorMessageDescription={errorMessageDescription}
            // addCourse={this.addCourse}
            // coursesOptions={coursesOptions}
            // addLevel={this.addLevel}
            // levelOptions={levelOptions}
            // level={level}
          />
        )
        // case 3:
        //   return (
        //     <ServiceDetails
        //     nextStep={this.nextStep}
        //     handleChange={this.handleChange}
        //     website={website}
        //     describe={describe}
        //     socialmedia={socialmedia}
        //     prevStep={this.prevStep}
        //     validateWebsite={this.validateWebsite}
        //     validateDescription={this.validateDescription}
        //     validateSocialMedia={this.validateSocialMedia}
        //     isErrorWebsite={isErrorWebsite}
        //     isErrorDescription={isErrorDescription}
        //     isErrorSocialMedia={isErrorSocialMedia}
        //     errorMessageWebsite={errorMessageWebsite}
        //     errorMessageDescription={errorMessageDescription}
        //     errorMessageSocialMedia={errorMessageSocialMedia}
        //   />
        //   )
      case 3:
        return (
          <Summary 
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            companyname={companyname}
            email={email}
            password={password}
            passwordVerify={passwordVerify}
            phone={contactPhone}
            cui={cui}
            address={address}
            bankName={bankName}
            bankAccount={bankAccount}
            website={website}
            socialmedia={socialmedia}
            isErrorSignup={isErrorSignup}
            // coursesChosenSummary={coursesChosenSummary}
            // chosenLevel={chosenLevel}
            submitData={this.submitData}
          />
        )
      default: return null
    }
  }
}

export default withTranslation()(Form);
