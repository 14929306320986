import { useState, useEffect, useContext } from "react";
import { LanguageContext } from "../../languages";
import { useTranslation } from 'react-i18next';
import { Button, Divider, Input } from '@mui/material';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import './profile.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { TranslateEC } from "../../utils/utils";
import { APIQuery, WebURL } from "../../api";


import { withStyles } from '@material-ui/core/styles';


const CompanyGallery = () => {

    // --------------- defs ---------------
    const limitTotalImages = 10;
    const {t} = useTranslation();
    const uiLangContext = useContext(LanguageContext);
    const uiLang = uiLangContext[0];

    const [galleryData, setGalleryData] = useState([]);
    const [dispErr, setDispErr] = useState('');
    const [forceRefresh, setForceRefresh] = useState(1);
    const [uid, setUID] = useState('');

    useEffect(() => {
        APIQuery('/supplier/gallery', {a: 'g'}, 
        (resp) => {
            console.warn(resp);
            setUID(resp.uid); // User UUID for directory location
            if(!Array.isArray(resp.items)) { 
                setDispErr(t('label_generic_err'));
                return;
            }
            setGalleryData(resp.items[0]);
        }, 
        (err) => {
            console.error(err);
        });
    }, [forceRefresh]);

    if(dispErr !== '') 
        return (<div className="error">{dispErr}</div>);
    
    // Image upload
    const doImageUpload = (e) => {
        console.log(e.target.files);
        if(e.target.files.length === 0) return; // No image available
        var file = e.target.files[0];
        console.log('upload', file);
        var postData = new FormData();
        postData.append('a', 'g');
        postData.append('sa', 'gai');
        postData.append('i', file);
        APIQuery('/supplier/gallery', postData, 
            (data) => {
                console.log(data);
                setForceRefresh(forceRefresh+1);
            }, 
            (err) => {
                var errStr = t('label_generic_err');
                if(err.msg) errStr = err.msg;
                if(err.ec) errStr = TranslateEC(err.ec, uiLang);

                setDispErr(t('gallery_operation_fail') + ': ' + errStr);
            }
        );
    }

    // Image remove
    const removeImageAction = (iid) => {
        APIQuery('/supplier/gallery', {'a': 'g', 'sa': 'gri', 'iid': iid}, 
            (data) => { setForceRefresh(forceRefresh+1); },
            (err) => { 
                console.error(err); 
                setDispErr(err.msg ? err.msg : t('label_generic_err')); 
            }
        );
    }

    const removeImageDialog = (iid) => {
        confirmAlert({
            title: t("label_delete"),
            message: t("dialog_delete_msg"),
            buttons: [
              {
                label: t('label_yes'),
                onClick: () => removeImageAction(iid)
              },
              {
                label: t('label_no')
              }
            ]
        });
        
    }

    // Generate gallery page content
    var ImageUploadForm = '';
    var GalleryImages = [];
    if(galleryData.items) {

        // Generate gallery
        galleryData.items.forEach( el => {

            var imgLink = WebURL + 'data/' + uid + '/g/' + el['img-s'];
            var imgid = el.id;
            GalleryImages.push(
            <Grid item xs={3}>
                <div className="gImage">
                    <a href="#" className="removeBtn" onClick={(e) => { e.preventDefault(); removeImageDialog(el.id); }}><DeleteIcon /></a>
                    <img src={imgLink} alt="" />
                </div>
            </Grid>);
        });

        // Display message if no images
        if(galleryData.items.length === 0)
            GalleryImages.push(<Grid item xs={12}>{t('gallery_noimages')}</Grid>);
        
        // Upload form
        if(galleryData.items.length < limitTotalImages) {
            ImageUploadForm = (<Grid item xs={12} display="flex" justifyContent="center">
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    onChange={doImageUpload}
                />
                <label htmlFor="raised-button-file">
                    <Button variant="outlined" component="span">{t('label_upload')}</Button>
                </label> 
            </Grid>);
        } else {
            ImageUploadForm = t('gallery_maximagereached');
        }

        // Display max images
        var strNrImages = (t('gallery_currentimages').replace('[nr]', galleryData.items.length)).replace('[total]', limitTotalImages);
    }

    return (
    <div className="p-container main-container">
        <div className="p-element p-element-left">
            <Grid container spacing={2} alignItems="center" columns={{ xs: 3, sm: 6, md: 12 }}>
                <Grid item xs={12}><h2>{t('gallery_title')}</h2></Grid>
                <Grid item xs={12}><i>{strNrImages}</i></Grid>
                <Grid item xs={12}><Divider variant="middle" sx={{ marginTop: 1, marginBottom: 1 }} /></Grid>
                {GalleryImages}
                <Grid item xs={12}><Divider variant="middle" sx={{ marginTop: 1, marginBottom: 1 }} /></Grid>
                <Grid item xs={12}><h2>{t('gallery_uploadimage_title')}</h2></Grid>
                {ImageUploadForm}
            </Grid>
        </div>
    </div>
    );
}

export default CompanyGallery;