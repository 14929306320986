import { Password } from '@mui/icons-material';
import React, { Component } from 'react';
import { Stepper } from 'react-form-stepper';
// import i18next, { t } from 'i18next';
import { withTranslation } from 'react-i18next';

import './SingUp.css';

class PersonalDetails extends Component {
  continue = e => {
    e.preventDefault(); 
    const isCompanyNameValid = this.props.validateCompanyName();
    const isEmailValid = this.props.validateEmail();
    const isPasswordValid = this.props.validatePassword();
    const isPasswordVerifyValid = this.props.validatePasswordVerify();
    console.log(isEmailValid, isEmailValid, isPasswordValid, isPasswordVerifyValid)

    if (isCompanyNameValid && isEmailValid && isPasswordValid && isPasswordVerifyValid) {
      this.props.nextStep();
    }
  }

  render() {
    const { t } = this.props;
    const { 
      companyname, 
      email,
      password, 
      passwordVerify,
      phone, 
      handleChange, 
      validateCompanyName,
      validateEmail,
      validatePassword,
      validatePasswordVerify,
      isErrorCompanyName,
      isErrorEmail,
      isErrorPassword,
      isErrorPasswordVerify,
      errorMessageCompanyName,
      errorMessageEmail,
      errorMessagePassword,
      errorMessagePasswordVerify
    } = this.props;

    return (
      <div className='form'>
        <form>

          <Stepper
            steps={[{ label: t("Summary_company_personal_details") }, { label: t('Summary_company_information') }, { label: t('Summary_company_summary') }]}
            activeStep={0}
            styleConfig={{
              activeBgColor: '#2b7cff',
              activeTextColor: '#fff',
              inactiveBgColor: '#fff',
              inactiveTextColor: '#2b7cff',
              completedBgColor: '#fff',
              completedTextColor: '#2b7cff',
              size: '3em'
            }}
            className={'stepper'}
            stepClassName={'stepper__step'}
          />

          <div className='form-group'>
            <div className='form-group__element'>
              <label htmlFor='company name' className='form-group__label'>
              {t("Personal_details_companyname")}
              </label>
              <input type='text' placeholder='ex. Home Renovation SRL' value={companyname} name='company name' onChange={handleChange('companyname')} onBlur={validateCompanyName} className='form-group__input'/>
              <p className='error'>{isErrorCompanyName && t(errorMessageCompanyName)}</p>
            </div>
            <div className='form-group__element'>
              <label htmlFor='email' className='form-group__label'>
              Email
              </label>
              <input type='email' placeholder='ex. homerenovationsrl@gmail.com' value={email} name='email' onChange={handleChange('email')} className='form-group__input' />
              <p className='error'>{isErrorEmail && t(errorMessageEmail)}</p>
            </div>
            <div className='form-group__element'>
              <label htmlFor='password' className='form-group__label'>
              {t("Personal_details_password")}
              </label>
              <input type='password' value={password} name='password' onChange={handleChange('password')} className='form-group__input' />
              <p className='error'>{isErrorPassword && t(errorMessagePassword)}</p>
            </div>
            <div className='form-group__element'>
              <label htmlFor='password_again' className='form-group__label'>
              {t("Personal_details_confirm_password")}
              </label>
              <input type='password' value={passwordVerify} name='passwordVerify' onChange={handleChange('passwordVerify')} className='form-group__input' />
              <p className='error'>{isErrorPasswordVerify && t(errorMessagePasswordVerify)}</p>
            </div>
          </div>
          <div style={{textAlign: 'center'}}>
            <button className='buttons__button buttons__button--next' onClick={this.continue}>{t("Regist_provider_steps_next")}</button>
          </div>

        </form>
      </div>
    )
  }
}

export default withTranslation()(PersonalDetails);