import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { ProfileContext } from "../../profile";
import {useContext} from "react";

const SearchLocationSaved = ({ onLocationValueUpdate }) => {
  const user = useContext(ProfileContext);

  const [selectedLocation, setSelectedLocation] = useState({ id:100, label: "" });
  const handleChangeLogged = (e, v) => {
    if (v == null) {
      setSelectedLocation({ id:100, label: "" });
    } else {
      setSelectedLocation(v);
    }
  };

  const [btnDisables, setBtnDisables] = useState(true);

  useEffect(() => {
    if (selectedLocation.label !== "") {
      setBtnDisables(false);
      //onContinue(true)
    } else {
      setBtnDisables(true);
      //onContinue(false);  
    }
  }, [selectedLocation]);

  return (
    <form className="search-form">
      <div className="search-logged">
        <Autocomplete
          disablePortal
          id="combo-box-logged"
          className="input-combo"
          options={user[0][0].myaddresses}
          onChange={handleChangeLogged}
          value={selectedLocation}
          sx={{ width: 300 }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label="Haztartasok" />
          )}
        />
      </div>
      {/* <Button
        variant="contained"
        disabled={btnDisables}
        className="continue-btn"
        onClick={() => onContinue(true)}
      >
        Tovabb
      </Button> */}
    </form>
  );
};

export default SearchLocationSaved;