import { ProfileContext } from "../../profile";
import { LocationContext } from "../../location";
import {useContext} from "react";
import AddRequest from "./Pages/AddRequest";

const ContextClass = (props) => {
    const location = useContext(LocationContext);
  const user = useContext(ProfileContext);
  console.log(location)
  return (<AddRequest location={location} user={user} />
  )
}

export default ContextClass
