import { useState, useEffect, useContext } from "react";
import { LanguageContext } from "../../languages";
import { useTranslation } from 'react-i18next';
import { TextField } from "@mui/material";
import { Button, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { APIQuery } from "../../api";
import { MapContainer, Marker, Popup, TileLayer, useMapEvent, useMapEvents } from "react-leaflet";
import { DataProviderContext } from '../../dataprovider';
import {TranslateCountyList, TranslateCityList, TranslateCategoryList} from '../../utils/utils'
import {useLocation, useNavigate} from 'react-router-dom';
import './profile.css';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/*
loc Index	point
cats	longtext
scats	longtext
addr
citid
cc
zip
label
contact
open

*/


// TODO: pass company type with login

const CompanyLocationEdit = (props) => {
    
    // --------------- defs ---------------
    const {t} = useTranslation();
    const uiLangContext = useContext(LanguageContext);
    const uiLang = uiLangContext[0];
    const dataProvider = useContext(DataProviderContext);

    // map related
    var map = null;
    const [mapPos, setMapPos] = useState([46.35, 25.8])
    const [markPos, setMarkPos] = useState(null)
    const [locData, setLocData] = useState({
        id: null,
        loc: mapPos,
        cats: [],
        scats: [],
        addr: '',
        citid: '',
        cc: '',
        zip: '',
        label: {},
        contact: {},
        open: []
    });

    // city related
    // Raw list from API
    const [rawCountyList, setRawCountyList] = useState([]);
    const [rawCityList, setRawCityList] = useState([]);
    // Combo display list
    const [currentCountyList, setCurrentCountyList] = useState([]);
    const [currentCityList, setCurrentCityList] = useState([]);
    // Combo selected values
    const [selCountyObj, setSelCountyObj] = useState({id: 'HR', label: 'Harghita'});
    const [selCityObj, setSelCityObj] = useState({id: null, label: ''});
    useEffect(() => {

        // Get county list
        var _countyList = dataProvider.ListCounties();
    
        // Saved default county
        var _defCounty = dataProvider.GetDefaultCounty(_countyList);
    
        // Get city list
        var _defCity = '';
        var _cityList = dataProvider.ListCitiesForCounty(_defCounty, (data) => {
          // Get default or first city
          _defCity = dataProvider.GetDefaultCity(data[0]);
          if(typeof(_defCity.labels) === 'object') {
            _defCity.label = _defCity.labels[uiLang] ? _defCity.labels[uiLang] : _defCity.labels[Object.keys(_defCity.labels)[0]];
          }
          setSelCityObj(_defCity);
        });
        
        setRawCountyList(_countyList);
        setRawCityList(_cityList);
    
        setCurrentCountyList(TranslateCountyList(_countyList, uiLang));
        setCurrentCityList(TranslateCityList(_cityList, uiLang));
    
        setSelCountyObj(_defCounty);
        setSelCityObj(_defCity);
        
    
      }, []);
    
      function handleCountyChange(e, v) {
        if(v === null) return;
        if(typeof(v.id) !== 'string' || typeof(v.label) !== 'string') {
          console.error('Invalid call to SearchLocation.handleCountyChange:', v)
          return;
        }
        setSelCountyObj(v);
    
        // Change city list
        dataProvider.ListCitiesForCounty(v.id, (list) => {
            setRawCityList(list);
            var cmbList = TranslateCityList(list, uiLang)
            setCurrentCityList( cmbList );
            setSelCityObj(cmbList[0]);
            UpdateData('city', cmbList[0]);
        });
    
      }
    
      function handleCityChange(e, v) {
        if(v === null) return;
        if(typeof(v.id) !== 'string' || typeof(v.label) !== 'string') {
          console.error('Invalid call to SearchLocation.handleCityChange:', v)
          return;
        }
        console.log(v);
        setSelCityObj(v);
        UpdateData('city', v);
      }

    // category related
    const cmbCatList = TranslateCategoryList(dataProvider.ListCategories(), uiLang);
    const [selMainCat, setSelMainCat] = useState(cmbCatList[0]);
    const [selSubCats, setSelSubCats] = useState([]);

    // other
    const [dispErr, setDispErr] = useState('');
    const [forceRefresh, setForceRefresh] = useState(1);

    const navigateTo = useNavigate();
    const location = useLocation();
    
    var isANewLocation = (location.state !== null && location.state.id !== undefined) ? false : true;

    // --------------- functions ---------------
    
    const UpdateData = (type, v) => {

        var ld = locData;
        // console.log(ld);

        switch(type) {
            case 'label_ro':
                ld.label.ro = v;
                setLocData(ld);
                break;
            case 'label_hu':
                ld.label.hu = v;
                setLocData(ld);
                break;
            case 'label_en':
                ld.label.en = v;
                setLocData(ld);
                break;
            case 'cat':
                ld.cats = [v.id];
                setLocData(ld);
                break;
            case 'scat':
                if(ld.cats.length === 0) ld.cats.push([selMainCat]);
                ld.scats = [];
                v.forEach(x => ld.scats.push(x.id));
                setLocData(ld);
                break;
            case 'loc':
                if((ld.loc[0] == v[0]) && (ld.loc[1] == v[1])) break;
                ld.loc = v;
                setLocData(ld);
                setMarkPos(ld.loc);
                break;
            case 'loc_lat':
                if(ld.loc[0] == v) break;
                ld.loc[0] = v;
                setLocData(ld);
                setMarkPos(ld.loc);
                break;
            case 'loc_long':
                if(ld.loc[1] == v) break;
                ld.loc[1] = v;
                setLocData(ld);
                setMarkPos(ld.loc);
                break;
            case 'city':
                if(ld.citid === v.id) break;
                ld.citid = v.id;
                ld.cc = v.cc;
                setLocData(ld);
                map.flyTo(v.loc);
                break;
            case 'addr':
                ld.addr = v;
                setLocData(ld);
                break;
            case 'zip':
                ld.zip = v;
                setLocData(ld);
                break;
            default:
                console.error('Handler not set');
        }

        setForceRefresh(forceRefresh+1);
        // ValidateData();
    }

    const ValidateData = () => {
        var errArr = [];

        if(locData.scats.length === 0)
            errArr.push(<div>{t('suploc_err_cat')}</div>);
        
        if(locData.addr.length < 4)
            errArr.push(<div>{t('suploc_err_addr')}</div>);

        if(locData.citid === '' || locData.cc === '')
            errArr.push(<div>{t('suploc_err_city')}</div>);

        if(locData.zip.length < 4 || locData.zip.length > 12)
            errArr.push(<div>{t('suploc_err_zip')}</div>);

        if(locData.loc[0] < 43.52 || locData.loc[0] > 48.29 || locData.loc[1] < 19.8 || locData.loc[1] > 29.8)
            errArr.push(<div>{t('suploc_err_gps')}</div>);
        
        if(errArr.length === 0) 
            errArr = '';
        else
            errArr = <div className="error">{errArr}</div>;
        setDispErr(errArr);

        return errArr === '' ? true : false;
    }

    const SaveLocData = (data) => {

        if(!ValidateData()) return;

        console.log(data);
        
        // Compatibility with API V1
        data.wid = data.id;
        data.geo = data.loc;
        data.cityid = data.citid;
        APIQuery('/supplier/profile', {a: (isANewLocation) ? 'wa' : 'ws', data: data}, 
            (data) => {
                // All ok
                navigateTo('/companyprofile');
            },
            (err) => {
                console.error(err);
                setDispErr(<div className="error">{t('label_generic_err')}</div>);
            }
        );
    }
    
    // --------------- hooks ---------------

    useEffect(() => {
        if(isANewLocation) return;

        // Load location data
        APIQuery('/supplier/profile', 
            {a: 'wgd', lid: location.state.id}, 
            (resp) => {
                var data = resp.data;
                data.id = location.state.id;
                if(Array.isArray(data.label)) data.label = {}; // Stupid PHP bug fix; do not remove line
                
                setLocData(data);
                setMarkPos(data.loc);
                map.flyTo({lat: data.loc[0], lng: data.loc[1]}, map.getZoom());
                // Setup categories
                if(data.cats.length > 0) {
                    let _mcat = cmbCatList.find(x => x.id === data.cats[0]);
                    setSelMainCat(_mcat);
                    let _scat = [];
                    data.scats.forEach(x => {
                        let _sc = _mcat.subCategories.find(xi => xi.id === x);
                        if(_sc) _scat.push(_sc);
                    });
                    setSelSubCats(_scat);
                }
            },
            (err) => {
                console.error(err);
                setDispErr(<div className="error">{t('label_generic_err')}</div>);
            }
        );
    }, []);

    // category related
    /* TODO: remove 

    const CategoryUnselect = (elid) => {
        setSelSubCats(selSubCats.filter( x => x.id !== elid ));
    } */
    
    // --------------- Helper functions ---------------

    const CategoryToggleOption = (el) => {

        var list = selSubCats;
        if(list.find(x => x.id === el.id))
            list = list.filter(x => x.id !== el.id);
        else
            if(selSubCats.length < 2) 
                list.push(el); 
            else 
                return false;
        
        setSelSubCats(list);
        UpdateData('scat', list);
        return true;
    }

    const CategoryCheckedOption = (el) => (selSubCats.find(x => x.id === el.id) !== undefined) ? true : false;

    const CategoryMainSelected = (el) => {
        setSelMainCat(el);
        setSelSubCats([]);
        UpdateData('cat', el);
        UpdateData('scat', []);
    }

    const SubCategoryList = () => {
        if(selMainCat !== null) {
            var res = [];

            selMainCat.subCategories.forEach( (el) => {
                res.push(<Grid item xs={4} key={'gk_'+el.id}>
                        <Checkbox
                            key={el.id}
                            onClick={(event) => { event.target.checked = CategoryToggleOption(el); }}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={CategoryCheckedOption(el)}
                        />
                        <span>{el.label}</span>
                </Grid>);
            });

            return res;
        }
        return (<div></div>);
    }

    // --------------- map and marker related ---------------
    // lat -90 - 90; long -180 to 180

    const eventHandlerMarkerDrag = {
        dragend(e) {
          var loc = e.target.getLatLng();
          UpdateData('loc', [loc.lat, loc.lng]);
        },
    };

    // --------------- content generation ---------------
    
    var form_title = (isANewLocation) ? t('suploc_title_new') : t('suploc_title_edit');

    const LocationMarker = () => {
        map = useMapEvents({
            click(e) {
                setMarkPos(e.latlng);
                UpdateData('loc', [e.latlng.lat, e.latlng.lng]);
            },
            locationfound(e) {
                setMarkPos(e.latlng);
                map.flyTo(e.latlng, map.getZoom());
            },
        });
        
        return markPos === null ? null : (
          <Marker position={markPos} draggable={true} eventHandlers={eventHandlerMarkerDrag}>
            <Popup>You are here</Popup>
          </Marker>
        );
    }

    return (
    <div className="p-container main-container">
        <div className="p-element p-element-left">

        <Grid container spacing={2} alignItems="center" columns={{ xs: 4, sm: 6, md: 12 }}>
            <Grid item xs={12}>
                <h2>{form_title}</h2>
            </Grid>

            <Grid item xs={12}>
                {dispErr}
            </Grid>
            
            <Grid item xs={12}><Divider sx={{ marginTop: 1, marginBottom: 1 }} /></Grid>
            <Grid item xs={12}><h3>{t('suploc_name')}</h3></Grid>
            
            <Grid item xs={4}>{t('label_ro')}</Grid>
            <Grid item xs={8}>
                <TextField style = {{width: '100%'}} value={locData.label ? locData.label.ro: null} onChange={(e)=>{ UpdateData('label_ro', e.target.value); }}></TextField>
            </Grid>
            <Grid item xs={4}>{t('label_hu')}</Grid>
            <Grid item xs={8}>
                <TextField style = {{width: '100%'}} value={locData.label ? locData.label.hu: null} onChange={(e)=>{ UpdateData('label_hu', e.target.value); }}></TextField>
            </Grid>
            <Grid item xs={4}>{t('label_eu')}</Grid>
            <Grid item xs={8}>
                <TextField style = {{width: '100%'}} value={locData.label ? locData.label.en: null} onChange={(e)=>{ UpdateData('label_en', e.target.value); }}></TextField>
            </Grid>

            <Grid item xs={12}><Divider sx={{ marginTop: 1, marginBottom: 1 }} /></Grid>

            <Grid item xs={12}><h3>{t('suploc_cat')}</h3></Grid>
            <Grid item xs={12}>
                <Autocomplete
                    id="cat-select"
                    noOptionsText="No labels"
                    options={cmbCatList}
                    onChange={(_, option) => { CategoryMainSelected(option); }}
                    value={selMainCat}
                    autoHighlight
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(e1, e2) => e1.id === e2.id} 

                    renderInput={(params) => {
                        return (
                        <TextField
                            {...params}
                            label={t('suploc_cat')}
                            variant="outlined"
                        />
                        );
                    }}
                />
            </Grid>

            <SubCategoryList />

            <Grid item xs={12}><Divider sx={{ marginTop: 1, marginBottom: 1 }} /></Grid>
            <Grid item xs={12}><h3>{t('suploc_addr')}</h3></Grid>

            <Grid item xs={2}>{t('label_county')}</Grid>
            <Grid item xs={4}>
                <Autocomplete
                    disablePortal
                    disableClearable
                    id="combo-box-county"
                    className="input-combo"
                    options={currentCountyList}
                    value={selCountyObj}
                    onChange={handleCountyChange}
                    sx={{ width: '100%' }}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => (option && value) && (option.cc === value.cc) }
                    renderInput={(params) => <TextField {...params} label={t("Permis_search_firstsection_county")}/>}
                />
            </Grid>
            <Grid item xs={2}>{t('label_city')}</Grid>
            <Grid item xs={4}>
                <Autocomplete
                    disablePortal
                    id="combo-box-city"
                    noOptionsText="No labels"
                    className="input-combo m15"
                    value={selCityObj}
                    options={currentCityList}
                    onChange={handleCityChange}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => (option && value) && (option.id === value.id)}
                    sx={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} label={t("Permis_search_firstsection_location")} />}
                />
            </Grid>

            <Grid item xs={2}>{t('suploc_addr')}</Grid>
            <Grid item xs={10}>
                <TextField variant="outlined" style = {{width: '100%'}} value={locData.addr} onChange={(e)=>{ UpdateData('addr', e.target.value); }}></TextField>
            </Grid>

            {/* <Grid item xs={2}>{t('suploc_description')}</Grid>
            <Grid item xs={10}>
                <TextField variant="outlined" style = {{width: '100%'}} value={locData.addr} onChange={(e)=>{ UpdateData('addr', e.target.value); }}></TextField>
            </Grid> */}


            <Grid item xs={2}>{t('suploc_zip')}</Grid>
            <Grid item xs={4}>
                <TextField variant="outlined" style = {{width: '100%'}} value={locData.zip} onChange={(e)=>{ UpdateData('zip', e.target.value); }}></TextField>
            </Grid>

            

            <Grid item xs={12}><Divider sx={{ marginTop: 1, marginBottom: 1 }} /></Grid>
            <Grid item xs={12}><h3>{t('suploc_coord')}</h3></Grid>

            <Grid item xs={2}>
                {t('gps_lat')}
            </Grid>
            <Grid item xs={4}>
                <TextField variant="outlined" style = {{width: '100%'}} value={locData.loc ? locData.loc[0] : null} onChange={(e)=>{ UpdateData('loc_lat', e.target.value); }}></TextField>
            </Grid>
            <Grid item xs={2}>
                {t('gps_long')}
            </Grid>
            <Grid item xs={4}>
                <TextField variant="outlined" style = {{width: '100%'}} value={locData.loc ? locData.loc[1] : null} onChange={(e)=>{ UpdateData('loc_long', e.target.value); }}></TextField>
            </Grid>

            <Grid item xs={12}>
                <MapContainer center={mapPos} zoom={12} style={{ height: '50vh'}}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <LocationMarker />
                </MapContainer>
            </Grid>

            <Grid item xs={12}><Divider sx={{ marginTop: 1, marginBottom: 1 }} /></Grid>

            <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                <Button variant="contained" onClick={() => SaveLocData(locData)}>{t('label_save')}</Button>
            </Grid>
        </Grid>

        </div>
    </div>);

}

export default CompanyLocationEdit;