import './Login.css';
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import { Navigate } from "react-router-dom";
import './LoginAll.css'
import { FaRegUser } from "react-icons/fa";
import { GrUserWorker } from "react-icons/gr";
import { useTranslation } from "react-i18next";

export default function LoginAll() {

  const { i18n } = useTranslation();

  function changeLanguage(e) {
    i18n.changeLanguage(e.currentTarget.value);
  }
  const { t } = useTranslation();

  return (

    <div>
      <h1 className='loginTitle'>{t("LoginAll_login_tiltle")}</h1>
      <div className='logAll'>
        <div className='butt1'>
          <div className='buttons'>
            <FaRegUser size={40}></FaRegUser>

            {t("LoginAll_login_as_user")}
            <Link to='../Login'><button className="btn" type="submit">
              {t("LoginAll_login_login")}
            </button></Link>
          </div>
        </div>
        <div className='butt2'>
          <div className='buttons'>
            <GrUserWorker size={40}></GrUserWorker>
            {t("LoginAll_login_as_provider")}

            <Link to='../LoginProvider'><button className="btn" type="submit">
              {t("LoginAll_login_login")}
            </button></Link>
          </div>
        </div>
      </div>
    </div>
  )


}