import i18n from 'i18next';
import {useContext} from "react";
import { initReactI18next } from 'react-i18next';

// import { LanguageContext } from '../languages';
// import { useContext } from "react";

 // const [language, setLanguage] = useContext(LanguageContext);
//todo: contextet itt haszalom
i18n.use(initReactI18next).init({
  fallbackLng: 'hu',
  lng: 'hu',
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    ro: {
      translations: require('./locales/ro/translations.json')
    },
    hu: {
      translations: require('./locales/hu/translations.json')
    },
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'ro', 'hu'];

export default i18n;