import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";
import {useContext} from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../languages";
import { DataProviderContext } from '../../dataprovider';
import {TranslateCountyList, TranslateCityList, TranslateLabel} from '../../utils/utils'

const SearchLocation = ({onLocationValueUpdate}) => {

  function GetCountyObjByCC(list, cc) {
    for(var i = 0; i < list.length; i++)
      if(list[i].cc === cc) return list[i];
    return null;
  }
 
  const [cititesDisabled, setCititesDisabled] = useState(true);

  // --------------------------------------------
  const dataProvider = useContext(DataProviderContext);
  // Raw list from API
  const [rawCountyList, setRawCountyList] = useState([]);
  const [rawCityList, setRawCityList] = useState([]);
  // Combo display list
  const [currentCountyList, setCurrentCountyList] = useState([]);
  const [currentCityList, setCurrentCityList] = useState([]);
  // Combo selected values
  const [selCountyObj, setSelCountyObj] = useState({cc: 'HR', label: 'Harghita'});
  const [selCityObj, setSelCityObj] = useState(null);

  // Get UILang
  const uiLangContext = useContext(LanguageContext);
  var uiLang = uiLangContext[0];
  
  useEffect(() => {

    // Get county list
    var _countyList = dataProvider.ListCounties();

    // Saved default county
    var _defCounty = dataProvider.GetDefaultCounty(GetCountyObjByCC(_countyList, 'HR'));
    if(_defCounty === undefined) {
      console.error('This should not happen');
      return;
    }

    // Update labels on default county (in cache of language change)
    var t = GetCountyObjByCC(_countyList, _defCounty.cc ?? _defCounty.id);
    _defCounty.id = t.cc;
    _defCounty.label = TranslateLabel(t.labels, uiLang);
    dataProvider.SetDefaultCounty(_defCounty); // Save locally
    // End refresh label

    // Get city list
    dataProvider.ListCitiesForCounty(_defCounty, (dataList) => {
      var _defCity = null;

      // Get default or first city
      _defCity = dataProvider.GetDefaultCity(dataList[0]);
      if(typeof(_defCity.labels) === 'object') {
        _defCity.label = TranslateLabel(_defCity.labels, uiLang);
      }
      setSelCityObj(_defCity);
      dataProvider.SetDefaultCity(_defCity);

      // Set State variables for ComboBoxes
      setRawCountyList(_countyList);
      setRawCityList(dataList);

      setCurrentCountyList(TranslateCountyList(_countyList, uiLang));
      setCurrentCityList(TranslateCityList(dataList, uiLang));

      setSelCountyObj(_defCounty);
      setSelCityObj(_defCity);

      onLocationValueUpdate({type: 'loc', 'cc': _defCity.cc, 'cid': _defCity.id, 'mc': _defCity.loc });

      setCititesDisabled(false);
    });

  }, [dataProvider, uiLang]);

  // --------------------------------------------

  function handleCountyChange(e, v) {
    if(v === null) return;
    if(typeof(v.cc) !== 'string' || typeof(v.label) !== 'string') {
      console.error('Invalid call to SearchLocation.handleCountyChange:', v)
      return;
    }
    setSelCountyObj(v);
    dataProvider.SetDefaultCounty(v);

    // Change city list
    dataProvider.ListCitiesForCounty(v.cc, (list) => {
      setRawCityList(list);
      var cmbList = TranslateCityList(list, uiLang)
      setCurrentCityList( cmbList );
      setSelCityObj(cmbList[0]);
      dataProvider.SetDefaultCity(cmbList[0]);
      // update search data
      onLocationValueUpdate({type: 'loc', 'cc': v.cc, 'cid': cmbList[0].id, 'mc': cmbList[0].loc });
    });

  }

  function handleCityChange(e, v) {
    if(v === null) return;
    if(typeof(v.id) !== 'string' || typeof(v.label) !== 'string') {
      console.error('Invalid call to SearchLocation.handleCityChange:', v)
      return;
    }
    console.log('Selected city:', v);
    setSelCityObj(v);
    dataProvider.SetDefaultCity(v);
    onLocationValueUpdate({type: 'loc', 'cc': v.cc, 'cid': v.id, 'mc': v.loc });
  }

  const { t } = useTranslation();

  return (<form className="search-form">
    <div className="searched-notlogged">
      <Autocomplete
        disablePortal
        disableClearable
        id="combo-box-county"
        className="input-combo"
        options={currentCountyList}
        value={selCountyObj}
        onChange={handleCountyChange}
        sx={{ width: 300 }}
        getOptionLabel={(option) => option.label ? option.label : ''}
        isOptionEqualToValue={(option, value) => (option && value) && (option.cc === value.cc) }
        renderInput={(params) => <TextField {...params} label={t("Permis_search_firstsection_county")}/>}
      />

      <Autocomplete
        disablePortal
        id="combo-box-city"
        noOptionsText="No labels"
        className="input-combo m15"
        value={selCityObj}
        options={currentCityList}
        onChange={handleCityChange}
        disabled={cititesDisabled}
        getOptionLabel={(option) => option.label ? option.label : ''}
        isOptionEqualToValue={(option, value) => (option && value) && (option.id === value.id)}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={t("Permis_search_firstsection_location")} />}
      />
    </div>
  </form>);
}

export default SearchLocation;