import React from 'react'
import './SingUp.css'
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import jwt_decode from "jwt-decode";
import Box from "@mui/material/Box";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";


import { Navigate } from "react-router-dom";




const SingUp = () => {

  // for tabcontext
  const [value, setValue] = useState("1");

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

   // for tabcontext
   const [value2, setValue2] = useState("1");
  const handleChangeTab2 = (event, newValue) => {
    setValue2(newValue);
  };

  const [user, setUser] = useState({});

  function handleCallbackResponse(response) {
    console.log("Encoded JWT ID token: " + response.credential);
    var userObject = jwt_decode(response.credential);
    console.log(userObject);
    setUser(userObject);
    //a bejelentkezes utan eltunik
    // document.getElementById("signInDiv").hidden = true;
  }


  function handleSignOut(event) {
    //ures, mert nincs bejelentkezve
    setUser({});
    document.getElementById("signInDiv").hidden = false;
  }

  useEffect(() => {
    /*global google*/
    google.accounts.id.initialize({
      client_id: "462680852852-ol132osfk1li90mn8g6gn2qpn7c4p232.apps.googleusercontent.com",
      callback: handleCallbackResponse
    });

    google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      { theme: "outline", size: "large" }
    );
    //megjeleniti a jobb felso sarokban a lehetosegeket.
    google.accounts.id.prompt();
  }, []);


  const [type, setType] = useState('');

  const handleChange = (e) => {
    setType(e.target.value);
  }



  return (

    <div className="maincontainer">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="Felhasznalo" value="1" />
              <Tab label="Szerelo adatok" value="2" />
            </TabList>
          </Box> */}
          <TabList onChange={handleChangeTab}></TabList>
          <TabPanel value="1">
            <div className="form">
              <h3>Regisztrálás Felhasználóként</h3>
              <div id="signInDiv"></div>
              {Object.keys(user).length != 0 &&
                <Navigate replace to="/MyProfile" state={{ user }} />}

              <div className="form-body">

                <div className="username">
                  <label className="form__label" for="firstName">Felhasználónév </label>
                  <input className="form__input" type="text" id="firstName" placeholder="Felhasználónév" />
                </div>
                <div className="tel">
                  <label className="form__label" for="phoneNumber">Telefonszám </label>
                  <input type="phone" name="" id="number" className="form__input" placeholder="Telefonszám" />
                </div>
                <div className="email">
                  <label className="form__label" for="email">Email </label>
                  <input type="email" id="email" className="form__input" placeholder="Email" />
                </div>
                <div className="password">
                  <label className="form__label" for="password">Jelszó </label>
                  <input className="form__input" type="password" id="password" placeholder="Jelszó" />
                </div>
                <div className="confirm-password">
                  <label className="form__label" for="confirmPassword">Jelszó Megerősítés </label>
                  <input className="form__input" type="password" id="confirmPassword" placeholder="Jelszó Megerősítés" />
                </div>
              </div>
              <div class="Footer">
                <button type="submit" class="btn">Regisztrálás</button>
                <p class="text-center">Már van fiókod? <Link to='/Login'>Bejelentkezés</Link> </p>
              </div>
            </div>
          </TabPanel>

          <TabPanel value="2">
          <div className="form">
        <h3>Regisztrálás Szerelőként</h3>
        <div className='radioButton'>

<Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value2}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChangeTab2} aria-label="lab API tabs example">
            <Tab label="Ceges adatok" value="1" />
            <Tab label="Egyeni vallalkozo" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
        <div className="form-body">
          <div className="username">
            <label className="form__label" for="firstName">Felhasználónév </label>
            <input className="form__input" type="text" id="firstName" placeholder="First Name" />
          </div>
          <div className="tel">
            <label className="form__label" for="phoneNumber">Telefonszám </label>
            <input type="phone" name="" id="number" className="form__input" placeholder="phoneNumber" />
          </div>
          <div className="email">
            <label className="form__label" for="email">Email </label>
            <input type="email" id="email" className="form__input" placeholder="Email" />
          </div>
             <div className="codunic">
            <label className="form__label" for="cod">CUI </label>
            <input className="form__input" type="number" id="cui" placeholder="cui" />
          </div>
          <div className="username">
            <label className="form__label" for="firstName">Nr. Inreg </label>
            <input className="form__input" type="text" id="firstName" placeholder="reg number" />
          </div>
          <div className="password">
            <label className="form__label" for="password">Jelszó </label>
            <input className="form__input" type="password" id="password" placeholder="Password" />
          </div>
          <div className="confirm-password">
            <label className="form__label" for="confirmPassword">Jelszó Megerősítés </label>
            <input className="form__input" type="password" id="confirmPassword" placeholder="Confirm Password" />
          </div>
          <div class="Footer">
          <button type="submit" class="btn">Regisztálás</button>
          <p class="text-center">Már van fiókod? <Link to='/Login'>Bejelentkezés</Link> </p>
        </div> 
        </div>
        </TabPanel>
        <TabPanel value="2">
        <div className="form-body">
          <div className="username">
            <label className="form__label" for="firstName">Felhasználónév </label>
            <input className="form__input" type="text" id="firstName" placeholder="First Name" />
          </div>
          <div className="phoneNumber">
            <label className="form__label" for="phoneNumber">Telefonszám </label>
            <input type="phone" name="" id="number" className="form__input" placeholder="phoneNumber" />
          </div>
          <div className="email">
            <label className="form__label" for="email">Email </label>
            <input type="email" id="email" className="form__input" placeholder="Email" />
          </div>
          <div className="password">
            <label className="form__label" for="password">Jelszó </label>
            <input className="form__input" type="password" id="password" placeholder="Password" />
          </div>
          <div className="confirm-password">
            <label className="form__label" for="confirmPassword">Jelszó Megerősítés </label>
            <input className="form__input" type="password" id="confirmPassword" placeholder="Confirm Password" />
          </div>
          <div class="Footer">
          <button type="submit" class="btn">Regisztálás</button>
          <p class="text-center">Már van fiókod? <Link to='/Login'>Bejelentkezés</Link> </p>
        </div>
        </div>
        
        </TabPanel>
      </TabContext>
    </Box>
        </div>
      </div>
          </TabPanel>
        </TabContext>
      </Box>

    </div>
  )
}

export default SingUp