import { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "../../profile-components/profile.css";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@mui/material/IconButton";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import { useTranslation } from "react-i18next";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import LanguageIcon from "@mui/icons-material/Language";
import { APIQuery } from "../../../api";
import { ProfileContext } from "../../../profile";

//constants for calling the API

//url of the needed endpoint
//in the documentation you can find it at the top of all sections like API URL:
//this is needed to for the application because this determines where should it send the request
const PROFILE_URL = '/supplier/profile';

//actions
//in the documentation you can find it at the first column of the tabels
//this is needed for the api to know what you want to get or do and what should the api do
const GET_PROFILE = 'p';
const UPDATE_PROFILE = 'pu';
const LIST_GLOBAL_CATEGORIES = 'cl';
//TODO rest of the actions for the profile data endpoint


const useStyles = makeStyles({
  hide: {
    display: "none",
  },
  customPsition: {
    position: "absolute",
    top: "22px",
    right: "22px",
  },
  customBtn: {
    width: "100%",
    background:
      "linear-gradient(180deg, rgba(0,0,0,0) calc(50% - 1px), rgba(192,192,192,1) calc(50%), rgba(0,0,0,0) calc(50% + 1px) )",
    borderRadius: "0% !important",
  },
  customSvg: {
    backgroundColor: "white",
  },
  socialMedia: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

var nr = 10;

//todo add alert
const Profile = () => {

  const { t } = useTranslation();
  const navigateTo = useNavigate();

  const classes = useStyles();

  const [profile, setProfile] = useContext(ProfileContext);
  if(profile === null || profile.role !== 'provider') navigateTo('/LoginAll');
  // TODO: profile - ebben vannak a bejelentkezo adatai, s kellene hasznalni

  //get mechanic general stuff
  const [manualRefresh, setManualRefresh] = useState(false);
  const [mechanicProfile, setMechanicProfile] = useState({
    cid: "0000-0000-111111",
    name: "Csikszereda Pista srl",
    categories: [1],
    subcategories: [1, 3],
    loc: [46.358264, 25.805312],
    labels: {
      ro: "Sediul Vizszerelo SRL",
      hu: "Vizszerelo SRL szekhelye",
      en: "Vizsyerelo En SRL",
    },
    contact: {
      email: "kelemenhajnlak99@gmail.com",
      phone: "+40750789515",
      website: "http://vizszerelo.ro",
      verified: "1",
    },
    social: [
      {
        id: 0,
        // social media id ex Fb: 0
        socialId: 0,
        link: "https://www.facebook.com/",
      },
    ],
    county: "Hargita",
    location: "Csikszereda",
    lng: "",
    lat: "",
    premises: [
      {
        id: 500,
        county: "Hargita",
        location: "Csikszereda",
        lng: "",
        lat: "",
      },
      {
        id: 501,
        county: "Hargita",
        location: "Csikszentkiraly",
        lng: "",
        lat: "",
      },
    ],
    rating: {
      value: 45,
      count: 152,
    },
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis eleifend sapien, malesuada commodo velit aliquam a. Aliquam eu dolor hendrerit, commodo lectus quis, feugiat neque. Aenean id augue urna. Nulla blandit lectus in sem condimentum, vel semper metus tincidunt. Ut eget ante massa. Vestibulum ac sem magna. Cras euismod rhoncus imperdiet.",
    type: 1,
    logo: "",
    cui: 665465,
    register: "j19/562/rfejr",
    bank: "OTP",
    iban: "RO66565szamla00",
    open: {
      1: [8, 16],
      2: [8, 16],
      3: [8, 16],
      4: [8, 12, 14, 18], // 8-12 es 14-18
      5: [8, 16],
      6: [8, 13],
      7: [], // zarva
    },
  });

  //Get labes for company
  const [companyLabels, setCompanyLabels] = useState({
    ro: "Sediul Vizszerelo SRL",
    hu: "Vizszerelo SRL szekhelye",
    en: "Vizsyerelo En SRL",
  });

  //textfield states for cancel
  //general company stuff saved for cancel
  //name
  const [name, setCompany2] = useState({ name: mechanicProfile.name });
  const [contact2, setContact2] = useState(mechanicProfile.contact);
  const [companyLabels2, setCompanyLabels2] = useState(companyLabels);
  const [cui2, setCui2] = useState({ cui: mechanicProfile.cui });
  const [register2, setRegister2] = useState({
    register: mechanicProfile.register,
  });
  const [bank2, setBank2] = useState({ bank: mechanicProfile.bank });
  const [iban2, setIban2] = useState({ iban: mechanicProfile.iban });
  const [description2, setDescription2] = useState({
    description: mechanicProfile.description,
  });
  const [selectedImage, setSelectedImage] = useState(mechanicProfile.logo);

  //handle changes label
  const handleUserInput = (e, v) => {
    const name = e.target.name;
    const value = e.target.value;
    setCompanyLabels({ ...companyLabels, [name]: value });
  };

  //handle changes contact
  const handleUserInputContact = (e, v) => {
    const name = e.target.name;
    const value = e.target.value;
    const newContact = { ...mechanicProfile.contact, [name]: value };
    setMechanicProfile({ ...mechanicProfile, contact: newContact });
  };

  //handle changes contact
  const handleUserInputGeneral = (e, v) => {
    const name = e.target.name;
    const value = e.target.value;
    setMechanicProfile({ ...mechanicProfile, [name]: value });
  };

  //button stuff
  //handle edit for general comapny info
  const [edit, setEdit] = useState(true);
  const [variantType, setVariantType] = useState("standard");
  const handleEdit = () => {
    setEdit(!edit);
    setVariantType(variantType == "standard" ? "filled" : "standard");
    if (!edit) {
      //handle cancel edit
      setMechanicProfile({
        ...mechanicProfile,
        name: name.name,
        description: description2.description,
      });
      setCompanyLabels(companyLabels2);
    } else {
      //save previous value for cancel
      setContact2(mechanicProfile.contact);
      setCompany2({ ...name, name: mechanicProfile.name });
      setDescription2({
        ...description2,
        description: mechanicProfile.description,
      });
      setCompanyLabels2(companyLabels);
    }
  };

  const handleSave = () => {
    //initialize the needed data for the profile update
    //example in documentation Profile data section -> table -> action = pu -> description
    //this is needed for the api to know what he needs to set each data
    let data = {
      "fiscal": {
        "cui": mechanicProfile.cui,
        "bank": mechanicProfile.bank,
        "account": mechanicProfile.iban
      },
      "descr": {
        "en": mechanicProfile.labels.en, //TODO: change to description
        "hu": mechanicProfile.labels.hu,
        "ro": mechanicProfile.labels.ro
      },
      "cphone": mechanicProfile.contact.phone,
      "cweb": mechanicProfile.contact.website,
      "cemail": mechanicProfile.contact.email
    }

    //initialize the parameters needed to calling the API
    //This is the final array what we send to the API
    //for this profile update we need the action ( "a" : UPDATE_PROFILE (='pu') ) and the data array from above
    let params = {
      "a" : UPDATE_PROFILE,
      "data" : data
    }

    //making the call with out APIQuery function

    
  };

  //handle edit for contact info
  const [editContact, setEditContact] = useState(true);
  const [variantTypeContact, setVariantTypeContact] = useState("standard");
  const handleEditContact = () => {
    setEditContact(!editContact);
    setVariantTypeContact(
      variantTypeContact == "standard" ? "filled" : "standard"
    );
    if (!editContact) {
      //handle cancel edit
      setMechanicProfile({
        ...mechanicProfile,
        contact: contact2,
      });
    } else {
      //save previous value before edit mode for cancel
      setContact2(mechanicProfile.contact);
    }
  };
  const handleSaveContact = () => {
    //todo: store logo
    setEditContact(!editContact);
    setVariantTypeContact(
      variantTypeContact == "standard" ? "filled" : "standard"
    );
    //TODO: send new contact to server
  };
  
  //edit for picture
  const [editPic, setEditPic] = useState(true);
  const handleEditPic = () => {
    setEditPic(!editPic);
    if (!editPic) {

      // Upload logo to server
      var bData = new FormData();
      bData.append('a', 'l');
      bData.append('f', selectedImage);

      APIQuery('/supplier/logo', bData, 
      (resp) => {
        console.log(resp);
        // TODO: a resp-ben benne van a logo neve, a link ra : https://findplus.info/data/<uuid>/<logo neve>
      }, 
      (err) => {
        console.error(err);
      });
      // ----- end upload -----

      console.log("sendPic");
      //todo: store logo
      setMechanicProfile({ ...mechanicProfile, logo: selectedImage });
    }
  };

  //edit for registers
  const [editReg, setEditReg] = useState(true);
  const [variantTypeReg, setVariantTypeReg] = useState("standard");
  const handleEditReg = () => {
    
    setEditReg(!editReg);
    setVariantTypeReg(variantTypeReg == "standard" ? "filled" : "standard");
    if (!editReg) {
      setMechanicProfile({
        ...mechanicProfile,
        cui: cui2.cui,
        register: register2.register,
        bank: bank2.bank,
        iban: iban2.iban
      });
    } else {
      setCui2({ ...cui2, cui: mechanicProfile.cui });
      setRegister2({ ...register2, register: mechanicProfile.register });
      setBank2({ ...bank2, bank: mechanicProfile.bank });
      setIban2({ ...iban2, iban: mechanicProfile.iban });
    }
  };
  const handleSaveReg = () => {
    setEditReg(!editReg);
    setVariantTypeReg(variantTypeReg == "standard" ? "filled" : "standard");
    console.log(mechanicProfile);
  };

  //add social medai
  const [editSocial, setEditSocial] = useState(true);
  const [socials, setSocials] = useState([
    {
      id: 0,
      label: "Facebook",
      icon: <FacebookIcon />,
    },
    {
      id: 1,
      label: "Instagram",
      icon: <InstagramIcon />,
    },
    {
      id: 2,
      label: "Youtube",
      icon: <YouTubeIcon />,
    },
    {
      id: 3,
      label: "Twitter",
      icon: <TwitterIcon />,
    },
    {
      id: 4,
      label: "Other Site",
      icon: <LanguageIcon />,
    },
  ]);

  const handleAddSocial = () => {
    setEditSocial(!editSocial);
  };

  const handleDeleteSocial = (event, socialCompId) => {
    var newArray = mechanicProfile;
    newArray.social = newArray.social.filter(
      (item) => item.id !== socialCompId
    );
    setManualRefresh(!manualRefresh);
    setMechanicProfile(newArray);
  };

  //add scial fields
  const [newSocail, setNewSocial] = useState({});
  const [newSocailLink, setNewSocialLink] = useState("");
  const [validUrl, setValidUrl] = useState(true);

  const handleAddSocialServer = (event) => {
    var json = {
      id: nr++,
      socialId: newSocail.id,
      link: newSocailLink,
    };
    console.log(json);
    //check url format
    setValidUrl(isValidHttpUrl(newSocailLink));
    if (validUrl) {
      console.log("send data");
    }
    var newArray = mechanicProfile;
    newArray.social.push(json);
    setManualRefresh(!manualRefresh);
    setMechanicProfile(newArray);
  };
  return (
    <div className="p-container main-container">
      <div className="p-element p-element-left">
        <h2>{t('Profile_personal_details_title')}</h2>
        <img
          src={
            selectedImage == ""
              ? "https://via.placeholder.com/150"
              : URL.createObjectURL(selectedImage)
          }
          className="profile-img"
          alt="not fount"
        ></img>
        {editPic ? (
          <br></br>
        ) : (
          <input
            type="file"
            name="myImage"
            onChange={(event) => {
              console.log(event.target.files[0]);
              setSelectedImage(event.target.files[0]);
            }}
          />
        )}
        <Button onClick={handleEditPic}>
          {editPic ? "Edit Picture" : "Save"}
        </Button>
        
        <div className="relat-btn">
          <h2>{t('Profile_company_details_title')}</h2>
          <TextField
            id="outlined-read-only-input"
            label={t('Profile_company_details_company_name')}
            className="profile-input"
            name="name"
            value={mechanicProfile.name}
            InputProps={{
              readOnly: edit,
            }}
            margin="normal"
            variant={variantType}
            onChange={handleUserInputGeneral}
          />
          <TextField
            id="outlined-read-only-input"
            label={t('Profile_company_name_ro_label_title')}
            className="profile-input"
            value={companyLabels.ro}
            InputProps={{
              readOnly: edit,
            }}
            margin="normal"
            variant={variantType}
            name="ro"
            onChange={handleUserInput}
          />
          {companyLabels.hu !== "" ? (
            <TextField
              id="outlined-read-only-input"
              label={t("Profile_company_name_hu_label_title")}
              className="profile-input"
              value={companyLabels.hu}
              InputProps={{
                readOnly: edit,
              }}
              margin="normal"
              variant={variantType}
              name="hu"
              onChange={handleUserInput}
            />
          ) : null}
          {companyLabels.en !== "" ? (
            <TextField
              id="outlined-read-only-input"
              label={t("Profile_company_name_en_label_title")}
              className="profile-input"
              value={companyLabels.en}
              InputProps={{
                readOnly: edit,
              }}
              margin="normal"
              variant={variantType}
              name="en"
              onChange={handleUserInput}
            />
          ) : null}
          <TextField
            aria-label="Bemutatkozas"
            className="profile-input"
            name="description"
            minRows={10}
            multiline
            placeholder="Minimum 3 rows"
            value={mechanicProfile.description}
            InputProps={{
              readOnly: edit,
            }}
            margin="normal"
            variant={variantType}
            onChange={handleUserInputGeneral}
          />
          <IconButton
            aria-label="edit"
            onClick={handleEdit}
            color="primary"
            className="edit-btn"
          >
            <EditSharpIcon />
          </IconButton>
          <Button onClick={handleEdit} className={edit ? classes.hide : null}>
            {edit ? "Edit" : "Cancel"}
          </Button>
          <Button onClick={handleSave} className={edit ? classes.hide : null}>
            Save
          </Button>
        </div>
      </div>
      
      <div className="p-element p-element-left">
        <div className="relat-btn">
          <h2>{t("Profile_company_contacts_title")}</h2>
          <TextField
            id="outlined-read-only-input"
            label="Email"
            className="profile-input"
            name="email"
            value={mechanicProfile.contact.email}
            InputProps={{
              readOnly: editContact,
            }}
            margin="normal"
            variant={variantTypeContact}
            onChange={handleUserInputContact}
          />
          <TextField
            id="outlined-read-only-input"
            label="Website"
            className="profile-input"
            name="website"
            value={mechanicProfile.contact.website}
            InputProps={{
              readOnly: editContact,
            }}
            margin="normal"
            variant={variantTypeContact}
            onChange={handleUserInputContact}
          />
          <TextField
            id="outlined-read-only-input"
            label={t("Profile_company_contacts_phone_number")}
            className="profile-input"
            name="phone"
            value={mechanicProfile.contact.phone}
            InputProps={{
              readOnly: editContact,
            }}
            margin="normal"
            variant={variantTypeContact}
            onChange={handleUserInputContact}
          />
          <IconButton
            aria-label="delete"
            onClick={handleEditContact}
            color="primary"
            className="edit-btn"
          >
            <EditSharpIcon />
          </IconButton>
          <Button
            onClick={handleEditContact}
            className={editContact ? classes.hide : null}
          >
            {editContact ? "Edit" : "Cancel"}
          </Button>
          <Button
            onClick={handleSaveContact}
            className={editContact ? classes.hide : null}
          >
            Save
          </Button>
        </div>
        <div className="relat-btn">
          <h2>{t("Profile_company_fiscal_data_title")}</h2>
          <TextField
            id="outlined-read-only-input"
            className="profile-input"
            label="CUI"
            name="cui"
            value={mechanicProfile.cui}
            InputProps={{
              readOnly: editReg,
            }}
            margin="normal"
            variant={variantTypeReg}
            onChange={handleUserInputGeneral}
          />
          <TextField
            id="outlined-read-only-input"
            className="profile-input"
            label="register"
            name="register"
            value={mechanicProfile.register}
            InputProps={{
              readOnly: editReg,
            }}
            margin="normal"
            variant={variantTypeReg}
            onChange={handleUserInputGeneral}
          />
          <TextField
            id="outlined-read-only-input"
            className="profile-input"
            label="Bank"
            name="bank"
            value={mechanicProfile.bank}
            InputProps={{
              readOnly: editReg,
            }}
            margin="normal"
            variant={variantTypeReg}
            onChange={handleUserInputGeneral}
          />
          <TextField
            id="outlined-read-only-input"
            className="profile-input"
            label={t("Profile_company_fiscal_date_bank_account")}
            name="iban"
            value={mechanicProfile.iban}
            InputProps={{
              readOnly: editReg,
            }}
            margin="normal"
            variant={variantTypeReg}
            onChange={handleUserInputGeneral}
          />
          <IconButton
            aria-label="delete"
            onClick={handleEditReg}
            color="primary"
            className="edit-btn"
          >
            <EditSharpIcon />
          </IconButton>
          <Button
            onClick={handleEditReg}
            className={editReg ? classes.hide : null}
          >
            {editReg ? "Edit" : "Cancel"}
          </Button>
          <Button
            onClick={handleSaveReg}
            className={editReg ? classes.hide : null}
          >
            Save
          </Button>
        </div>
        <div>
          <h2>{t("Profile_company_socialmedia_title")}</h2>
          {mechanicProfile.social.length > 0 ? (
            <div>
              {/* map company social, then socials from front end for icon */}
              {mechanicProfile.social.map((socialComp) =>
                socials.map((social) =>
                  social.id == socialComp.socialId ? (
                    <div key={socialComp.id} className={classes.socialMedia}>
                      {social.icon}
                      <a href={socialComp.link}>{socialComp.link}</a>
                      <IconButton
                        aria-label="delete"
                        onClick={(event) =>
                          handleDeleteSocial(event, socialComp.id)
                        }
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ) : (
                    ""
                  )
                )
              )}
            </div>
          ) : (
            <p>Kattintis a plusz gombra a hozza adashoz</p>
          )}
          {editSocial ? (
            ""
          ) : (
            <div className={classes.socialMedia}>
              <Autocomplete
                disablePortal
                id="combo-box-social"
                options={socials}
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} label="Social Media" />
                )}
                onChange={(event, newValue) => {
                  setNewSocial(newValue);
                  console.log(newValue);
                }}
              />
              <TextField
                id="standard-basic"
                label="Link"
                variant="standard"
                value={newSocailLink}
                onChange={(e) => {
                  setNewSocialLink(e.target.value);
                  setValidUrl(true);
                }}
              />
              <IconButton
                disabled={!validUrl}
                aria-label="delete"
                onClick={handleAddSocialServer}
                color="primary"
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </div>
          )}
          {validUrl ? <p></p> : <p>Please enter a valid url</p>}

          <IconButton
            aria-label="add"
            onClick={handleAddSocial}
            color="primary"
            className={classes.customBtn}
          >
            <AddCircleOutlineIcon className={classes.customSvg} />
          </IconButton>
          
        
        </div>
        
      </div>
    </div>
  );
};

export default Profile;
