import React from 'react';
import './/TermsAndConditions.css';

const TermsAndConditions = () => (

    <div className="terms-text">
        <div className='terms-contet'></div>
        <h2>Termeni si conditii</h2>
        <h3>1. Date despre entitatea care deţine site-ul http://findplus.info (în continuare denumită Platformă,site sau pagină de web)</h3>
        <p>1.1. Pagina de web http://findplus.info este deţinută și pusă la dispoziția Dumneavoastră de Societatea SNOWYPINE
             SRL (denumită în continuare Administrator platformă, administrator sau SnowyPine), cu sediu secundar de desfășurare
            a activității: Miercurea Ciuc str. Petofi Sandor nr. 7, înregistrată la RC sub nr. J19/350/2022, având CUI:
            46319300. Site-ul funcționează în conformitate cu prevederile prezentelor Termeni şi Condiţii, care se completează
             cu prevederile legale aplicabile.
        </p>
        <p>1.2. Orice întrebare sau sugestie referitoare la prezentele termeni și condiții, sau în privința
            site-ului le puteți comunica pe adresa de email findplusapp@gmail.com.</p>
        <h3>2. Definiții</h3>
        <p>2.1. Furnizor de servicii: reprezintă entitatea, cu sau fără personalitate juridică, , care oferă
            servicii din diferite domenii determinate, prin intermediul platformei, având un cont de
            furnizor și un profil special în cadrul acestora. Prin utilizarea expresiei de furnizor sau prestator
            în cadrul prezentelor, este inclusă atât persoana juridică, cât și reprezentantul acestuia,
            drepturile și obligațiile fiind exercitate prin acesta din urmă.</p>
        <p>2.2. Utilizator: reprezintă persoana fizică, care își creează un cont de utilizator în cadrul
            platformei, cu scopul declarat de a solicita o ofertă din partea unui furnizor, și de a intra în
            raporturi contractuale cu acestea, în vederea prestării unor servicii din domeniul de activitate
            a a celui din urmă.</p>
        <p>2.3. Platforma: pagina de web Findplus.info, proprietatea Snowypine, destinat în mod expres
            constituirii unei baze de date complete a prestatorilor de servicii în diferite domenii de
            activitate din România, facilitând utilizatorilor găsirea prestatorului potrivit conform
            preferințelor individuale.
            <p>2.4. Cont de utilizator: contul special creat în cadrul paginii web de utilizator, destinat
                constituirii și gestionării unui profil de utilizator.</p>
            2.5. Cont de furnizor: contul special creat în cadrul platformei speciale în pagina web de
            Furnizorul de servicii, destinat constituirii și gestionării unui profil de furnizor, încărcând în
            cadrul acestuia informațiile aferente serviciului prestat.</p>
        <p>2.6. Administrator platformă: Snowypine S.R.L., cu datele de mai sus, care deține toate
            drepturile de proprietate, drepturile de autor și de proprietate intelectuală asupra acestora.</p>
        <h3>3. Introducere</h3>
        <p>3.1. Accesarea paginii de web, prin crearea unui cont de utilizator sau a unui cont de furnizor
            de servicii, implică acceptarea prezentelor termeni și condiții, precum și a celorlalte reguli

            afișate în cadrul acestora. Operatorul își rezervă dreptul de a modifica termenii și condițiile de
            mai jos, fără notificare prealabilă a utilizatorilor sau furnizorilor de servicii. Operatorul își
            rezervă dreptul de a modifica structura paginii de web și de a schimba compania de hosting
            fără vreo notificare în acest sens.</p>
        <p>3.2. Pentru a asigura respectarea condițiilor de acces și utilizare a platformei, persoanele care
            accesează acestea trebuie să verifice la momentul fiecărei accesări termenii și condițiile de
            utilizare.</p>
        <p>3.3. Chiar dacă omiteți să analizați termenii și condițiile, dar utilizați pagina de web, vom
            considera că ați acceptat acest set de Termeni și Condiții (inclusiv orice actualizare a acestora).
            <p>3.4. Dacă nu sunteți de acord cu Termenii și Condițiile, vă rugăm să nu utilizați pagina de web.</p>
            3.5. Utilizatorul și furnizorul de servicii din cadrul platformei declară că a înțeles pe deplin şi
            este de acord cu Termenii şi Condiţiile prezentate mai jos.</p>
        <p>3.6. Prevederile prezentelor termene și condiții, respectiv conditiile de utilizare a paginii de
            web de către orice persoană, constituie contractul (denumit in continuare &quot;Contractul&quot;) dintre
            Snowypine și persoana care utilizează pagina de web (denumită în continuare &quot;Utilizator&quot; sau
            „Furnizor de servicii”).</p>
        <h3>4. Reguli generale de utilizare a Platformei</h3>
        <p>4.1. Administratorul platformei ia toate măsurile de prudență pentru ca informațiile publicate
            în cadrul acestora, inclusiv descrierea serviciilor, a diferitelor profiluri de utilizatori și de
            furnizorii de servicii, să fie, în orice moment, corecte şi complete, realizând o informare
            deplină în acest sens. Cu toate acestea, administratorul nu poate garanta în mod absolut
            corectitudinea, precizia informațiilor oferite de către furnizorii de servicii și utilizatori în
            privința pofilelor proprii, a serviciilor încărcate, orice răspundere în acest sens aparținând
            acestuia din urmă.
            <p>4.2. Prin sondaj, reprezentanții operatorului pot solicita documente privind domeniul de
                activitate al furnizorului de servicii (acte de înființare, autorizații, certificat de înregistrare
                fiscală, etc.), dacă persoana de contact este împuternicită să reprezinte acesta sau poate
                verifica corectitudinea datelor indicate în formularul de creare a contului de Utilizator.</p>
            4.3. Nu este permisă utilizarea paginii de web în scopul introducerii în mod deliberat de viruşi
            sau orice alt program sau material care poate fi tehnic dăunător sau distructiv. Sunt interzise
            încercările de acces neautorizat la acestea, la serverul pe care este găzduit acesta sau la orice
            alt server, calculator sau bază de date în legătură cu acesta. Prin acceptarea Termenilor și
            Condițiilor vă angajați să nu atacați pagina de web prin intermediul unui atac de blocare a
            serviciului sau de blocare distribuită a serviciului. Nerespectarea dispozițiilor din prezenta
            clauză va fi raportată autorităților competente în vederea aplicării sancțiunilor legale și va
            determina suspendarea imediată a dreptului de a utiliza pagina de web.</p>
        <p>4.4. Administratorul paginii de web nu poate garanta că acestea, serverele pe care sunt
            găzduite sau e-mailurile trimise sunt fără viruși sau alte componente informatice cu caracter
            potențial dăunător, că nu conțin erori, omisiuni, defecțiuni, întârzieri sau întreruperi în

            operare, căderi de linie sau orice alți factori similari. Vă rugăm să aveți în vedere că folosiți
            pagina de web pe riscul propriu, Operatorul nefiind în niciun fel responsabil pentru
            eventualele daune directe sau indirecte cauzate de utilizarea sau accesarea/vizitarea acestuia
            sau ca urmare a utilizării informațiilor de pe acestea. Vă solicităm să vă asigurați că aveți
            instalate sisteme adecvate de apărare, menite să vă protejeze telefonul mobil sau tablet-ul de
            viruși şi/sau orice alt conținut dăunător.</p>
        <p>4.5. Administratorul paginii de web va asigura confidențialitatea datelor dumneavoastră,
            respectiv a datelor de autentificare, parolei şi tuturor celorlalte detalii în legătură cu contul
            creat pe acestea, în conformitate cu Politica de confidențialitate. Cu toate acestea, este
            responsabilitatea dumneavoastră să vă asigurați că informațiile privind contul creat rămân
            confidențiale în orice moment și că nu vor fi transmise către terți.
            <p>4.6. Pagina de web se poate accesa de pe orice sistem de operare, prin utilizarea unui browser.</p>
            <p>4.7. Administratorul nu garantează în nici un fel acuratețea, corectitudinea sau caracterul</p>
            actual al informațiilor prezentate de utilizatori sau de furnizorii de servicii în cadrul platformei.</p>
        <p>4.8. Bazele de date puse la dispoziție de administrator prin Platformă vor fi utilizate de orice
            persoană cu bună credință, cu scopul expres și declarat de a oferi/căuta un prestator de
            servicii, cu respectarea întocmai a prevederilor legale aferente securității acestor date.</p>
        <h3>5. Crearea contului de utilizator</h3>
        <p>5.1. Pentru a putea deveni Utilizator al serviciilor oferite de Platformă, respectiv pentru a
            putea găsi serviciul potrivit, oferit de furnizorii de servicii, este nevoie de accesarea domeniului
            www.findplus.info, după care va trebui să vă înregistrați prin accesarea butonului Înregistare,
            prin crearea unui cont de utilizator.
            <p>5.2. În vederea creării unui cont de utilizator, este nevoie să vă conectați la platformă exclusiv
                prin intermediul contului Facebook sau Google. Pe baza datelor de acces ale conturilor
                respective, puteți să vă logați în mod automat în cadrul Platformei.</p>
            <p>5.3. Prin acordarea permisiunii copilului dvs., sunteți de acord cu termenii acestui acord în
                numele copilului dvs. Sunteți responsabil pentru monitorizarea și supravegherea modului în
                care copilul Dumneavoastră utilizează serviciile site-ului. Dacă copilul dvs. nu are permisiunea
                Dumneavoastră, contactați-ne imediat, pentru a putea dezactiva accesul.</p>
            5.4. Sunteți responsabil pentru toate activitățile care apar în contul dvs., inclusiv activitatea
            neautorizată. Trebuie să păstrați confidențialitatea datelor de acces aferente contului. Dacă
            utilizați un telefon mobil sau un tablet la care au acces alte persoane, trebuie să vă deconectați
            din cont după fiecare sesiune. Dacă observați accesul neautorizat la contul dvs., trebuie să vă
            schimbați parola și să ne anunțați imediat.</p>
        <h3>6. Crearea contului de furnizor de servicii</h3>
        <p>6.1. În vederea utilizării serviciilor aferente Platformei, orice furnizor de servicii interesat să
            încarce serviciile prestate, va trebui să-și creeze un cont de furnizor în cadrul domeniului

            www.findplus.info, prin accesarea butonului Înregistrare. Profilul de furnizor poate fi editat,
            gestionat exclusiv în cadrul contului individual.</p>
        <p>6.2. În vederea înregistrării ca Furnizor, în cadrul paginii de web, va trebui să completați în
            mod complet datele solicitate de către Operator, respectiv: denumire entitate, adresă de
            email, parolă, sediu social, codul fiscal, denumire bancă (opțional), date bancare (opțional),
            pagină de web (opțional), descrierea serviciilor prestate, datele contului de pe rețelele sociale
            (opțional).</p>
        <p>6.3. După completarea tuturor câmpurilor aferente datelor obligatorii, arătate mai sus,
            Furnizorul va trebui să confirme aceste date sintetizate, și să accepte Termenii și condițiile
            aferente utilizării platformei prin bifarea butonului dedicat.
            <p>6.4. Contul de furnizor de servicii va fi gestionat în mod exclusiv de un reprezentant al unității
                respective, acesta purtând responsabilitate pentru conținuturile încărcate în cadrul contului.
                Numele și datele de contact ale reprezentantului vor fi comunicate operatorului platformei, în
                vederea realizării unei comunicări eficiente între acestea.</p>
            6.5. Fiecare furnizor de servicii va încărca în mod obligatoriu la contul de furnizor de servicii
            următoarele informații de bază, care vor fi publice, putând fi accesate de orice utilizator al
            paginii de web: denumire, număr de telefon, adresă de email, categoria din care face parte
            furnizorul de servicii în funcție de domeniul de activitate, o descriere amănunțită a entității și a
            serviciilor prestate, regiunea (județele, localitatea, după caz) de activitate, locațiile autorizate
            aferente furnizării serviciilor (puncte de lucru). Totodată, furnizorii de servicii vor avea
            posibilitatea de a încărca în contul propriu imagini de prezentare.</p>
        <p>6.6. Furnizorii de servicii sunt responsabili pentru toate activitățile care apar în contul propriu,
            inclusiv activitatea neautorizată. Trebuie să păstrați confidențialitatea datelor de acces
            aferente contului. Dacă utilizați un telefon mobil sau un tablet la care au acces alte persoane,
            trebuie să vă deconectați din cont după fiecare sesiune. Dacă observați accesul neautorizat la
            contul dvs., trebuie să vă schimbați parola și să ne anunțați imediat.</p>
        <p>6.7. Toate informațiile și elementele încărcate în cadrul domeniului de administrare a contului
            de furnizor de servicii, arătat la pct. 6.1, se sincronizează automat și imediat cu datele din
            pagina de web.</p>
        <h3>7. Utilizarea Paginii de web de către utilizatori</h3>
        <p>7.1. Având în vedere scopul de activitate aferentă Platformei, esența utilizării acestora constă
            în încărcarea unor oferte și cereri din partea utilizatorilor și furnizorilor de servicii în contul lor
            propriu, urmând ca acestea să se întâlnească în caz de potrivire.</p>
        <p>7.2. După crearea contului propriu, utilizatorii vor avea posibilitatea să utilizeze toate funcțiile
            Platformei, având posibilitatea navigării între toate ofertele încărcate de furnizorilor de
            servicii, să filtreze acestea conform nevoilor personale și să ia legătura cu furnizorii selectați pe
            datele de contact furnizate de acestea.</p>
        <h3>8. Utilizarea Paginii de Web de către furnizorii de servicii</h3>
        <p>8.1. După crearea contului propriu conform procedurii de la pct. 6.1., fiecare furnizor de
            servicii, prin reprezentantul indicat conform celor de mai sus, își va gestiona în mod liber
            profilul de furnizor de servicii, încărcând în mod liber elementele esențiale aferente acestuia.
            <p>8.2. În cadrul profilului de administrare specială, furnizorii de servicii vor avea posibilitatea de
                a accesa ofertele celorlalți furnizori de servicii, de a afișa categoriile de furnizori, datele
                acestora.</p>
            8.3. Furnizorul de servicii este singurul responsabil pentru orice conținut publicat de el.
            Ofertele publicate în cadrul platformei de administrare, precum și orice alt element vizual
            aferent este supus următoarelor condiții și limitări:</p>
        <p>8.3.1. NU puteți publica, transmite sau face referire în niciun fel la mesaje care conțin
            texte ilegale, amenințătoare, abuzive, indecente, discriminatorii sau care încalcă în orice
            fel drepturi ale terților sau prevederi legale.
            <p>8.3.2. Ofertele publicate trebuie să fie complete, conținând toate informațiile sau
                condițiile aflate în legătura cu ea.</p>
            <p>8.3.3. NU puteți publica, transmite sau recomanda Utilizatorilor niciun fel de mesaj
                comercial nesolicitat, indiferent că această trimitere se face sau nu prin intermediul
                sistemelor de comunicație puse la dispoziție de administrator sau prin intermediul altor
                mijloace de comunicații, dar utilizând datele obținute prin intermediul platformei.</p>
            <p>8.3.4. NU puteți publica, transmite sau face referire la niciun fel de &quot;joc piramidal&quot; sau
                orice alta activitate menită a înșela încrederea unor persoane, precum și orice activități
                similare unor fapte în privința cărora s-a probat în trecut potențialul infracțional ori
                potențialul de a înșela încrederea unui utilizator.</p>
            <p>8.4. Orice încălcare a obligațiilor și interdicțiilor de mai sus, constatat prin orice mod de către
                operatorul site-ului, va atrage după sine suspendarea sau ștergerea definitivă a contului de
                furnizor de servicii. În cazul în care încălcarea s-a realizat în așa fel, încât fapta respectivă
                constituie infracțiune, operatorul va sesiza organele abilitate ale Statului</p>
            <p>8.5. Furnizorii de servicii au obligația de a furniza informații complete și corecte în ceea ce privește
                serviciul prestat (în special privind prețul, facilitățile oferite, autorizațiile necesare, etc.). Este interzisă
                introducerea de anunțuri care conțin informatii incorecte, fictive, ireale sau care generează confuzie.</p>
            <p>8.6. Anunțurile pot fi ilustrate cu fotografii. Ofertantul se obligă să insereze în bazele de date ale
                Platformei numai acele fotografii pe care este autorizat să le utilizeze fără restricții și care nu sunt
                grevate de drepturi ale unor terți - în special de drepturi de proprietate intelectuală ale unor terți.
                Fotografiile utilizate nu trebuie să inducă în eroare și trebuie să reflecte starea actuală a cazării oferite
                în anunț. Introducerea de imagini poate reprezenta un factor determinant în procesul de închiriere.
                Este interzisă folosirea de imagini care nu au legătură cu serviciul prestat. Este interzisă folosirea de
                imagini care prezintă însemne (watermark, siglă, denumire sau altele).</p>
            8.7. În general, nu este permisă într-un anunț includerea de legături spre pagini web externe sau spre
            alte surse externe de informații. Legăturile în acest sens se referă și la pagini web neactivate (URL-uri) și
            părți ale acestora.</p>
        <p>8.8. Furnizorul de servicii este responsabil pentru orice implicație contabilă, juridică, economică a
            activității pe care îl derulează, și cu care se înregistrează pe platformă, precum și de
            respectarea/deținerea tuturor condițiilor/autorizațiilor/avizelor aferente serviciilor respective.
            Operatorul este exonerată de orice răspundere în această privință, față de pretențiile oricărui terț,
            persoană fizică/juridică sau entitate publică.</p>
        <h3>4. Proprietate intelectuală</h3>
        <p>4.1. Întreg conținutul paginii de web, incluzând fără a se limita la logo-uri, reprezentări grafice,
            simboluri comerciale, imagini statice/dinamice, text și/sau conținut multimedia, design, coduri
            sursă, este proprietatea exclusivă ale Administratorului acesteia, cu excepția elementelor care
            aparțin furnizorilor de servicii, încărcate de acestea în contul de administrare.</p>
        <p>4.2. Utilizatorului/furnizorului de servicii nu îi este permisă copierea, reproducerea, difuzarea,
            distribuirea, publicarea, transferul, modificarea, alterarea, utilizarea, crearea de legături,
            expunerea, includerea oricărui conținut, parte, element al paginii de web în orice material al
            lui sau al unui terț, fără a exista un acord scris prealabil în acest sens.
            <p>4.3. Prin accesarea acestor Termeni și Condiții utilizatorul/furnizorul de servicii înțelege și
                acceptă că este interzisă în mod expres orice utilizare a paginii de web, precum și conținutul
                acestuia în alte scopuri decât cele permise expres conform Termenilor și Condițiilor.</p>
            <p>4.4. Este strict interzisă încărcarea în pagina de web a oricărui conținut, incluzând materiale,
                comentarii, imagini, secvențe video, dar fără a se limita la acestea, care au un limbaj obscen
                sau vulgar, sunt ilegale, defăimătoare, amenințătoare, abuzive, indecente, conțin mesaje
                rasiste, șoviniste ori care ar putea discrimina orice persoane in orice mod sau care ar încălca
                orice alte drepturi si libertăți ale omului potrivit legislației în vigoare.</p>
            <p>4.5. Utilizatorii și furnizorii de servicii înțeleg si acceptă că în cazul încălcării în orice mod și în
                orice măsură a oricărora din prevederile Termenilor și Condițiilor, Administratorul va putea
                opta în mod discreționar pentru suspendarea totală sau parțială a accesului la contul său de
                utilizator sau de furnizor de servicii.</p>
            4.6. De asemenea, este interzisă utilizarea de mijloace automate, inclusiv script-uri, aplicații de
            tip robot, bot, spider, crawler și/sau sau orice aplicații / programe de calculator ce pot înșela,
            sau simula anumite activități sau statusuri în platformî sau, în orice fel, exploata anumite
            funcții sau vulnerabilități ale acesteia în vederea obținerii unor avantaje, pentru sine sau
            pentru alți Utilizatori sau terți, în ceea ce privește utilizarea paginii de web, sau accesarea și
            utilizarea oricăror servicii.</p>
        <h3>5. Termenul contractual</h3>
        <p>5.1. Raportul juridic între Dvs. și societatea noastră începe la prima utilizare a paginii de web și
            continuă atât timp cât utilizați aceasta sau aveți un cont de utilizator/furnizor de servicii la noi,
            oricare dintre acestea este mai lung. Acest raport va dura până la momentul în care veți
            solicita ștergerea contului de acces sau din motive arătate în prezentele termene și condiții,
            societatea noastră va șterge contul Dvs pentru nerespectarea acestora.</p>
        <p>5.2. În cazul încălcării prezentelor condiții Administratorul poate, la alegerea sa: (a) să rezilieze
            imediat acest raport juridic, cu sau fără notificare scrisă în avans, ștergând de îndată contul
            Dvs; (b) să suspende, șterge sau limiteze accesul Dvs la la contul dvs. sau la orice conținut al
            acestuia; și (c) să păstrez, în măsura permisă de legea aplicabilă, toate sumele care trebuie
            plătite către Dvoastră.</p>
        <p>5.3. Dacă Operatorul șterge contul Dvs. pentru încălcarea gravă a prevederilor prezentelor
            Termene și condiții, acesta va putea interzice pe un termene nelimitat reînregistrarea Dvoastră
            pe pagina de web.</p>
        <h3>6. Prevederi finale</h3>
        <p>6.1. Administratorul paginii de web nu este răspunzător în nici un fel pentru orice fel de situații
            în care întreruperea și / sau defecțiunea, eroarea serviciilor prestate se datorează unei cauze
            în afara forței de intervenție a oricărora dintre părți, inclusiv dar fără a se limita la întreruperea
            conexiunii de internet, întreruperea furnizării serviciului de electricitate, orice tip de incendiu
            al echipamentelor, cablurilor, cauzele de scurt circuit, orice defecțiune totală și iremediabilă al
            oricăror părți al echipamentului tehnic, calitatea precară al conexiunii la internet a
            Utilizatorului, defecțiuni la serveri, atacuri cibernetice, precum și orice alte situații definite prin
            lege ca fiind situații de forță majoră.</p>
        <p>6.2. Snowy Pine SRL si /sau orice alte părți implicate in crearea, execuția sau prezentarea
            acestui website în numele nostru nu vor avea nici o responsabilitate legală sau de alt tip
            pentru nici un fel de daune, costuri, pierderi sau responsabilități directe, incidentale, indirecte,
            speciale sau punitive ce pot rezulta ca urmare a accesării, utilizării, incapacitatii de utilizare, a
            schimbării conținutului acestui website, sau ca urmare a accesării de către dumneavoastră a
            unui alt website printr-un link de pe acest website sau, in măsura permisa de legislația
            aplicabila, ca urmare a acțiunilor pe care le întreprindem sau nu in urma oricărui mesaj de
            posta electronica trimis de dumneavoastră. Snowy Pine SRL si/sau orice alte părți implicate in
            crearea, execuția sau prezentarea acestui website nu vor avea nici o responsabilitate de
            menținere a materialelor si serviciilor disponibile prin intermediul acestui website sau de
            transmitere a unor modificări, actualizări sau a altor comunicate in legătura cu acestea. Dacă
            decideți să descărcați vreun material de pe acest website, o faceți pe propriul dumneavoastră
            risc.</p>
        <p>6.3. Administratorul platformei furnizează sub forma bazelor de date doar premisele tehnice, prin
            intermediul cărora sunt transmise informațiile (anunțurile) aferente diferitelor servicii încărcate de
            Ofertanți, fără a exercita nicio influenta asupra conținutului acestora. Este în special de subliniat că
            administratorul platformei nu reprezintă parte contractuală în nici un raport juridic intervenit între
            Ofertant și Beneficiar, nefiind nici intermediar în acesta. Contractele sau orice act care se va încheia
            între Ofertant și Beneficiar se va încheia fără participarea în vreun fel al administratorului
            platformei.</p>
        <p>6.4. Acești Termene și Condiții se supun prevederilor legii române. În caz de dispute, acestea
            vor fi soluționate de instanța de judecată competentă de la sediul Administratorului,

            utilizatorul/furnizorul exprimându-și acordul în ceea ce privește această alegere de
            competență.</p>
        <p>6.5. Prezentele Termene și condiții se completează cu regulamentul privind prelucrarea
            datelor personale.</p>
        <p>6.6. Dacă nu acceptați condițiile prezentelor termene și condiții, Vă rugăm să părăsiți
            platforma.</p>
        <p>6.7. Administratorul paginii de web are dreptul de a modifica oricând prezentele Termene și
            condiții, fără nicio altă notificare prealabilă.</p>

    </div>
);

export default TermsAndConditions   