import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Requests from "../../companyprofile-components/Requests";
import AcceptedRequests from "../../companyprofile-components/AcceptedRequests";
import Profile from "./Profile"
import CompanyLocation from "../../profile-components/CompanyLocation";
import Subscirbtion from "../../profile-components/Subscirbtion";
import TestCat from "../../profile-components/TestCat";
import CompanyGallery from "../../profile-components/CompanyGallery";
import { useTranslation } from 'react-i18next';

const CompanyProfile = () => {
    // for tabcontext
    const [tabSelValue, setTabSelValue] = useState("5");

    const handleChange = (event, newValue) => {
        setTabSelValue(newValue);
      };
    
    const {t} = useTranslation();

  return (
    <div className="search-container">
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={tabSelValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="tabs" variant="scrollable" scrollButtons="auto">
            { /* <Tab label="Keresek" value="1" />
            <Tab label="Elvallalt munkak" value="2" /> */ }
            {/*<Tab label={t('profile_company_title')} value="3" />*/}
            {/*<Tab label="Kategoriak" value="4" />*/}
            <Tab label={t('subploc_title')} value="5" />
            {/*<Tab label="Elofizetett csomagok" value="6" />*/}
            <Tab label={t('gallery_title')} value="7" />
          </TabList>
        </Box>
        { /* <TabPanel value="1">
            <Requests />
        </TabPanel>
        <TabPanel value="2">
            <AcceptedRequests />
          </TabPanel> */ }
        {/*<TabPanel value="3">*/}
        {/*    <Profile />*/}
        {/*</TabPanel>*/}
        <TabPanel value="4">
            <TestCat />
        </TabPanel>
        <TabPanel value="5">
            <CompanyLocation />
        </TabPanel>
        <TabPanel value="6">
            <Subscirbtion />
        </TabPanel>
        <TabPanel value="7">
            <CompanyGallery/>
        </TabPanel>
      </TabContext>
    </Box>
    </div>
  );
};

export default CompanyProfile;
