import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import DoneIcon from "@mui/icons-material/Done";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import "./profile.css";

const AddCategory = ({ cats, maxSubCat }) => {
  //selected stuff
  //id
  const [selectedMainCat, setSelectedMainCat] = useState(cats[0].id);
  const [selectedSubCat, setSelectedSubCat] = useState([]);

  //checkbox states
  const [checked, setChecked] = useState(true);
  const [unchecked, setUnchecked] = useState(false);

  //errors true if more than maxSubCat checked or less than 1
  const [errors, setErrors] = useState([]);
  const error = selectedSubCat.length > maxSubCat || selectedSubCat.length < 1;
  //setSelectedMainCat
  const handleCatInput = (e) => {
    const value = e.target.value;
    setSelectedMainCat(parseInt(value));
    setSelectedSubCat([]);
  };

  //setSelectedSubCat
  const handleChange = (event) => {
    event.target.checked
      ? setSelectedSubCat([...selectedSubCat, parseInt(event.target.value)])
      : setSelectedSubCat(
          selectedSubCat.filter((subCat) => subCat != event.target.value)
        );
  };

  //send data to server
  const handleSendData = (event) => {
    console.log(selectedMainCat);
    console.log(selectedSubCat);
  };
  return (
    <div>
      <form className="add-cat">
        <select
          name="selectedMainCat"
          id="selectedMainCat" 
          onChange={handleCatInput}
          value={selectedMainCat}
        >
          {cats.map((mainCat) => (
            <option key={mainCat.id} value={mainCat.id}>
              {mainCat.label.ro}
            </option>
          ))}
        </select>
        <FormControl
          required
          error={error}
          component="fieldset"
          sx={{ m: 3 }}
          variant="standard"
        >
          <FormGroup>
            {cats.map((cat) =>
              cat.id == selectedMainCat
                ? cat.subCategories.map((subCat) =>
                    selectedSubCat.includes(subCat.id) ? (
                      <FormControlLabel
                        key={subCat.id}
                        control={
                          <Checkbox
                            value={subCat.id}
                            checked={checked}
                            onChange={(event) => handleChange(event)}
                          />
                        }
                        label={subCat.label.ro}
                      />
                    ) : (
                      <FormControlLabel
                        key={subCat.id}
                        control={
                          <Checkbox
                            value={subCat.id}
                            onChange={(event) => handleChange(event)}
                            checked={unchecked}
                          />
                        }
                        label={subCat.label.ro}
                      />
                    )
                  )
                : ""
            )}
          </FormGroup>
          <FormHelperText>
            Maximum 3 kategoriat valasythatsy a csomagodba. Minimum 1-et jelolj.
          </FormHelperText>
        </FormControl>
        <Button
          className="btn btn-primary"
          disabled={error}
          onClick={handleSendData}
        >
          Kuldes
        </Button>
      </form>
    </div>
  );
};

export default AddCategory;
