import { useState, useEffect, useContext } from "react";
import { APIQuery } from "../../api";
import { LanguageContext } from "../../languages";
import { useTranslation } from 'react-i18next';
import { TranslateLabel } from "../../utils/utils";
import { Button, Divider } from '@mui/material';
import {useNavigate} from "react-router-dom";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './profile.css';

const CompanyLocation = () => {

  const {t} = useTranslation();
  const uiLangContext = useContext(LanguageContext);
  const uiLang = uiLangContext[0];
  const navigateTo = useNavigate();

  const [dispError, setDispError] = useState('');
  const [maxLoc, setMaxLoc] = useState(2);
  const [locations, setLocations] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(0);

  useEffect(() => {
    APIQuery('/supplier/profile', {a: 'wld'}, (data) => {
      console.log('profile refresh');
      setLocations(data.list);
    },
    (err) => {
      setDispError(t('label_generic_err'));
      if(err.redirect && err.redirect === 'login') navigateTo('/loginProvider');
    })
  }, [forceRefresh]);

  // Display error
  if(dispError !== '') {
    return (<div>
      <div className="errorMessage">{dispError}</div>
    </div>);
  }

  // Actions
  const actionDeleteLoc = (locid) => {
    
    APIQuery('/supplier/profile', {a: 'wr', wid: locid}, (data) => {
      // all ok, reload list
      console.warn(data);
      setForceRefresh(forceRefresh+1);
    }, (err) => {
      setDispError(err.msg ? err.msg : t('label_generic_err'));
      if(err.redirect && err.redirect === 'login') navigateTo('/loginProvider');
    });
  }
  const confirmDeleteLoc = (locId) => {
    confirmAlert({
      title: t("label_delete"),
      message: t("dialog_delete_msg"),
      buttons: [
        {
          label: t('label_yes'),
          onClick: () => actionDeleteLoc(locId)
        },
        {
          label: t('label_no')
        }
      ]
    });
  };

  // Render list
  var locMemo = (<div>{t('suploc_maxreached')}</div>);
  if(locations.length < maxLoc)
    locMemo = (<p><Button variant="contained" onClick={() => { navigateTo("/companyprofile/location/edit"); }}>{t('suploc_addlocation')}</Button></p>);

  var displayList = [];
  locations.forEach( (l) => {

    var label = TranslateLabel(l.label, uiLang);
    var cname = TranslateLabel(l.cname, uiLang);
    
    displayList.push(<div class="locItem">
      <div className="locItemDetail">
        <h3>{label}</h3>
        <p>{l.addr}, {cname} {l.zip}, {l.cc}</p>
      </div>
      <div className="locItemActions">
        <Button variant="outlined" onClick={() => { navigateTo("/companyprofile/location/edit", {state: {id: l.id}}); }}>{t('label_edit')}</Button>
        <Button onClick={() => confirmDeleteLoc(l.id)} variant="outlined" color="error">{t('label_delete')}</Button>
      </div>
    </div>);
    displayList.push(<Divider variant="middle" sx={{ marginTop: 1, marginBottom: 1 }} />);
  })

  return (
    <div>
      <h2>{t('location_list')}</h2>
      <Divider variant="middle" sx={{ marginTop: 1, marginBottom: 1 }} />
      <div className="locList">
        {displayList}
      </div>

      <div className="locMemo">
        {locMemo}
      </div>
    </div>
  );
}

export default CompanyLocation;
