import { useState, useRef, useEffect } from "react";
import "../../profile-components/profile.css";
// import { makeStyles } from "@mui/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Machine from "../../userprofile-component/Machine";
import { makeStyles } from "@material-ui/core/styles";

import { ProfileContext } from "../../../profile";
import { useContext } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//ask: legyenek ikonok?
const useStyles = makeStyles({
  root: {
    display: "none",
  },
});

//checkbox
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const UserProfile = () => {
  const [user, setUser] = useContext(ProfileContext);
  const classes = useStyles();

  //textfiel states
  //comoany name
  const [userName, setUserName] = useState(user[0].name);
  const [userName2, setUserName2] = useState(userName);
  const handleChangeUserName = (event, v) => {
    setUserName(event.target.value);
  };
  //email
  const [email, setEmail] = useState(user[0].email);
  const [email2, setEmail2] = useState(email);
  const handleChangeEmail = (event, v) => {
    setEmail(event.target.value);
  };
  //phone
  const [phone, setPhone] = useState(user[0].phone);
  const [phone2, setPhone2] = useState(phone);
  const handleChangePhone = (event, v) => {
    setPhone(event.target.value);
  };
  //county
  const [county, setCounty] = useState(user[0].county);
  const [county2, setCounty2] = useState(county);
  const handleChangeCounty = (event, v) => {
    setCounty(event.target.value);
  };
  //city
  const [city, setCity] = useState(user[0].city);
  const [city2, setCity2] = useState(city);
  const handleChangeCity = (event, v) => {
    setCity(event.target.value);
  };

  //button stuff
  const [edit, setEdit] = useState(true);
  const [variantType, setVariantType] = useState("standard");
  const handleEdit = () => {
    setEdit(!edit);
    setVariantType(variantType == "standard" ? "filled" : "standard");
    if (!edit) {
      //handle cancel edit
      setUserName(userName2);
      setEmail(email2);
      setPhone(phone2);
      setCounty(county2);
      setCity(city2);
    } else {
      //save previous value for cancel
      setUserName2(userName);
      setEmail2(email);
      setCounty2(county);
      setCity2(city);
    }
  };
  const handleSave = () => {
    setEdit(!edit);
    setVariantType(variantType == "standard" ? "filled" : "standard");
  };

  const onEdit = (id) => {
    console.log("itt");
  };
  return (
    <div className="main-container">
      <div className="p-container">
        <div className="p-element p-element-left">
          <h2>{user[0].label}</h2>
          <img
            src="https://via.placeholder.com/150"
            className="profile-img"
          ></img>
          <h2>Sajat adataim</h2>
          <TextField
            id="outlined-read-only-input"
            label="Name"
            className="profile-input"
            value={userName}
            InputProps={{
              readOnly: edit,
            }}
            margin="normal"
            variant={variantType}
            onChange={handleChangeUserName}
          />

          <TextField
            id="outlined-read-only-input"
            label="Email"
            className="profile-input"
            value={email}
            InputProps={{
              readOnly: edit,
            }}
            margin="normal"
            variant={variantType}
            onChange={handleChangeEmail}
          />
          <TextField
            id="outlined-read-only-input"
            label="Phone nr"
            className="profile-input"
            value={phone}
            InputProps={{
              readOnly: edit,
            }}
            margin="normal"
            variant={variantType}
            onChange={handleChangePhone}
          />
          <Button onClick={handleEdit}>{edit ? "Edit" : "Cancel"}</Button>
          <Button onClick={handleSave} className={edit ? classes.root : null}>
            Save
          </Button>
        </div>
        <div className="p-element">
          <h2>Lakhely</h2>
          <TextField
            id="outlined-read-only-input"
            className="profile-input"
            label="Megye"
            value={county}
            InputProps={{
              readOnly: edit,
            }}
            margin="normal"
            variant={variantType}
            onChange={handleChangeCounty}
          />
          <TextField
            id="outlined-read-only-input"
            className="profile-input"
            label="Megye"
            value={city}
            InputProps={{
              readOnly: edit,
            }}
            margin="normal"
            variant={variantType}
            onChange={handleChangeCity}
          />
        </div>
        <div className="p-element">
          <h2>Meg valami?</h2>
        </div>
      </div>
      <div>
        {user[0].myaddresses.map((address, indexAddress) => (
          <Accordion key={address.address_id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography key={address.address_id}>{address.label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {address.machines != undefined
                  ? address.machines.map((machine, index) => (
                    <h2>gep</h2>
                      // <Machine
                      //   key={machine.id}
                      //   index={index}
                      //   machine={machine}
                      //   onEdit={onEdit}
                      // />
                    ))
                  : "nincs elmentett cucc"}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default UserProfile;
