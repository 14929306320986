import { useNavigate } from "react-router";

const apiURL = "https://api.findplus.info/v1";
const apiGeoURL = "https://api.findplus.info/v1";
const WebURL = 'http://findplus.info/';

/*const apiURL = "http://localhost/api/v1";
const apiGeoURL = "http://localhost/api/v1";
const WebURL = 'http://localhost/';*/

//megekerseni amit kitoroltem get set lng

// export async function APIQuery(loc, params){
//     const response = await fetch(`${apiDomain}/${loc}/`,{
//       method: 'GET',
//       body: JSON.stringify(data)
//     })
//     return response;
// }

//api endpoints
// QDistance  float32 `json:"d"`   // km
// 			QLatLong   [2]int  `json:"oc"`  // Origin coordinates [lat, long] (ONLY for logged in users)
// 			QCityId    string  `json:"o"`   // city id
// 			QCatId     int     `json:"c"`   // category id
// 			QSubCatId  int     `json:"sc"`  // subcategory id
// 			QSPlaceId  string  `json:"osp"` // Save place ID
// 			QUserToken string  `json:"t"`   // User login token (some query features require a valid login)
// a city id, az a listabol az id, category id az 1-10ig
// tehat city id-t az "o"  -val kuldod, a category id-t a "c" -ben
// az "oc" meg kell menjen, kikommenteltem a user ellenorzest, de meg az sem fontos most

// a: /query
// Params: json structure
//     a - Action:
//     cc - CountyCode:
//     Examples:
//         {"a": "c"} - get combined county/city list (it's over 1.5MB)
//         {"a": "ct"} - gets the county list
//         {"a": "ci", "cc": "HR"} - get list of cities for a given county (cc param)
//todo ezyt export'lom
function APIQuery(loc, postdata, fnOnSuccess, fnOnFail) {

    var isJONPost = false;
    if((typeof(postdata) === 'object') && !(postdata instanceof FormData)) {
        postdata = JSON.stringify(postdata);
        isJONPost = true;
    }
    
    var url = apiURL;
    if(loc === '/search')
        url = apiGeoURL;
    
    var params = {
        cache: 'no-cache',
        credentials: 'include',
        mode: 'cors',
        body: postdata,
        method: 'post'
    };
    if(isJONPost) params['Content-Type'] = 'application/json';

    const dFetch = async () => {

        var rawData, jsonData, fetchObj;
        try {
            fetchObj = await fetch(url + loc, params);
            rawData = fetchObj;
            jsonData = await(rawData.json());
        } catch(error) {
            console.log('fetch error', error, fetchObj.text());
            if(typeof(fnOnFail) === 'function') fnOnFail(rawData, error);
            return;
        }
        
        if(!fetchObj.ok || !jsonData.status) {
            if(typeof(fnOnFail) === 'function') fnOnFail(jsonData, 'inv_resp');
            return;
        }

        if(HandleStdResponse(jsonData)) return; // Message was processed by HandleStdResponse
        if(typeof(fnOnSuccess) === 'function') fnOnSuccess(jsonData);
      };

      dFetch();
}

function HandleStdResponse(jsonResp) {
    // const navigateTo = useNavigate();

    if(typeof(jsonResp) === 'undefined') return false;

    // Check for redirect command from API
    if(typeof(jsonResp.redirect) != 'undefined') {
        // navigateTo(jsonResp.redirect);
        return true;
    }

    return false;
}

export {APIQuery, HandleStdResponse, WebURL};
    
/*
  switch(loc) {

      // Kereses eredmenye
      case '/search':
          return {ok: true, status: 200, data:
          [{
              name: "Vizszerelo SRL",
              cid: '0000-0000-111111',
              type: 1,
              categories: [1],
              subcategories: [1,3],
              loc: [46.358264, 25.805312],
              labels: { 
                  ro: "Sediul Vizszerelo SRL", 
                  hu: "Vizszerelo SRL szekhelye",
                  en:"Vizsyerelo En SRL"
                },
              contact: {
                  email: "kelemenhajnlak99@gmail.com",
                  phone: "+40750789515",
                  website: "http://vizszerelo.ro",
                  verified: "1"
              },
              logo: 'https://placeimg.com/320/320/tech',
              rating: {
                  value: 45,
                  count: 152
              },
              open: {
                  1: [8, 16],
                  2: [8, 16],
                  3: [8, 16],
                  4: [8, 12, 14, 18], // 8-12 es 14-18
                  5: [8, 16],
                  6: [8, 13],
                  7: [], // zarva
              },
          },
          {"name":"Szamitogep szerviz SRL","cid":"0000-0000-111114","type":0,"categories":[4],"subcategories":[10],"loc":[46.360530, 25.808855],"labels":{"ro":"Sediul Szamitogep SRL","hu":"Szamitogep SRL szekhelye"},"contact":{"email":"contact@szamitogep.ro","phone":"0711 111 444","website":"http://szamitogep.ro","verified": "1"},"logo":"https://placeimg.com/320/320/tech","rating":{"value":20,"count":7},"open":{"1":[10,12],"2":[10,14],"3":[10,14],"4":[],"5":[10,12],"6":[8,10],"7":[10, 12]}},
          {"name":"Gazszerelo SRL","cid":"0000-0000-111112","type":2,"categories":[2],"subcategories":[4,5],"loc":[46.357775, 25.801233],"labels":{"ro":"Sediul Gazszerelo SRL","hu":"Gazszerelo SRL szekhelye"},"contact":{"email":"contact@vizszerelo.ro","phone":"0711 123 456","website":"http://gazzerelo.ro","verified": "2"},"logo":"https://placeimg.com/320/320/tech","rating":{"value":35,"count":29},"open":{"1":[8,14],"2":[8,14],"3":[8,14],"4":[8,14],"5":[8,12],"6":[],"7":[]}},
          {"name":"Autoszerviz SRL","cid":"0000-0000-111113","type":1,"categories":[3],"subcategories":[7,8,9],"loc":[46.358190, 25.808662],"labels":{"ro":"Sediul Autoszerelo SRL","hu":"Autoszerelo SRL szekhelye"},"contact":{"email":"contact@vizszerelo.ro","phone":"0711 111 333","website":"http://autoszerv.ro","verified": "0"},"logo":"https://placeimg.com/320/320/tech","rating":{"value":40,"count":73},"open":{"1":[8,17],"2":[8,17],"3":[8,17],"4":[8,17],"5":[8,17],"6":[8,12],"7":[]}},
      ]};
  
  // Valami egyeb api hivas
  case '/valamiegyeb':
      return {}

  // Hibas API hivas szimulalasa
  default:
      return {ok: false, status: 404}
  }

}*/