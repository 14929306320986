import React from 'react';
import { Parallax } from 'react-parallax';
// import Picture from '../../../assets/gps1.jpg'
import Picture from '../../../asset/gps1.jpg';
import './BackroundImage.css';

const BackroundImage = () => (
    <div className='parallax-image'>
        {/* <div className='content2'>
            <span className='img-text'>Lorem Ipsum</span>
        </div> */}
    </div>
);

export default BackroundImage