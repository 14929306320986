import { useTranslation } from 'react-i18next';
import {TranslateLabel, ArrayEquals} from '../../utils/utils'
import { useState, useContext } from 'react';
import { LanguageContext } from "../../languages";

import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

import { Box, Card, Divider } from '@mui/material';

import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import './SearchResults.css';
import { WebURL } from '../../api';


/*
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions'; */



function GenerateWorkingHours(list, t) {
    var resStr = '';

    if(list.length === 0) return '';
    var sameweekhours = true;
    var sameweekendhours = (ArrayEquals(list[5], list[6])) ? true : false;

    for(var i = 1; i < 5; i++) {
        if(!ArrayEquals(list[i-1], list[i])) sameweekhours = false;
    }
    
    if(sameweekhours) 
        resStr += t('label_mon_s') + " - " + t('label_fri_s') + ': ' + list[0].join(',') + '; ';
    else {
        resStr += t('label_mon_s') + ': ' + list[0].join(',') + ', ';
        resStr += t('label_tue_s') + ': ' + list[1].join(',') + ', ';
        resStr += t('label_wed_s') + ': ' + list[2].join(',') + ', ';
        resStr += t('label_thu_s') + ': ' + list[3].join(',') + ', ';
        resStr += t('label_fri_s') + ': ' + list[4].join(',') + '; ';
    }

    // console.log(sameweekendhours);
    if(sameweekendhours) {
        var szv = list[5].join(',');
        if(szv === '') szv = '-';
        resStr += t('label_sat_s') + " & " + t('label_sun_s') + ': ' + szv;
    }
    else {
        resStr += t('label_sat_s') + ': ' + ((list[5].length) ? list[5].join(',') : '-') + ', ';
        resStr += t('label_sun_s') + ': ' + ((list[6].length) ? list[6].join(',') : '-') + '; ';
    }

    return resStr;
}

function SearchResults(props) {

    const [tabValue, setTabValue] = useState('1');
    const {t} = useTranslation();
    const uiLangContext = useContext(LanguageContext);
    const uiLang = uiLangContext[0];
    const resList = props.data;
    const mapcenter = props.searchCity ? props.searchCity.mc : [46.35, 25.8];

    // No result return
    if(!Array.isArray(resList) || resList.length === 0)
        return (
            <div className='search_results' style={{textAlign: 'center'}}>{t('searchPage_noresult')}</div>
        );
    
    /* Sample result
    {
        "dist": "1458.108244524008",
        "id": "2e869379-5d3c-4ed9-a312-cf6989aee682",
        "name": "SNOWY PINE SRL",
        "labels": {
            "ro": "Software development"
        },
        "loccontact": {
            "p": "0720123321",
            "w": "https://snowypine.ro",
            "s": "https://facebook.com/snowypinesrl",
            "e": "mcoffice@snowypine.ro"
        },
        "contact": {
            "email": "office@snowypine.ro",
            "web": "https://www.snowypine.ro",
            "phone": "722111333",
            "social": "{}"
        },
        "type": "1",
        "cats": [
            "2db864ea"
        ],
        "scats": [
            "26a9243c",
            "05a982a7"
        ],
        "logo": null,
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer maximus risus vitae eleifend eleifend. Sed iaculis justo nec egestas gravida. Nullam non velit lacinia, ullamcorper nulla a, placerat turpis. Maecenas fermentum diam vitae ante bibendum aliquet. Integer commodo tristique eros. In sed ullamcorper urna. Mauris a diam maximus, pretium nunc ac, convallis augue. Aliquam erat volutpat.",
        "open": [
            ["8-16"],
            ["8-16"],
            ["8-16"],
            ["8-16"],
            ["8-16"],
            [],
            []
        ],
        "loc": [
            46.36066774252948,
            25.80575289006378
        ],
        "addr": "str. Petőfi Sándor, 7",
        "cid": "33e647b4",
        "cname": {"hu":"Cs\u00edkszereda","ro":"Miercurea-Ciuc"},
        "cc": "HR"
    }
    
    */

    var mapMarkerList = [];

    // Build result list
    var results = [];
    resList.forEach( el => {

        var label = TranslateLabel(el.labels, uiLang);
        var contact = el.contact;
        if(el.loccontact.p) contact.phone = el.loccontact.p;
        if(el.loccontact.w) contact.web = el.loccontact.w;
        if(el.loccontact.e) contact.email = el.loccontact.e;
        // if(el.loccontact.s) contact.social = el.loccontact.s; // TODO: do a proper social media merge
        contact.phoneLink = "tel://" + contact.phone;
        contact.emailLink = "mailto:" + contact.email;

        var cnameStr = TranslateLabel(el.cname, uiLang);

        // Address
        var supAddrStr = (<span>{el.name? el.name + ', ': ''}{el.addr}, {cnameStr}, {el.cc} {el.zip} </span>);

        // Description
        var desc = el.desc;
        if(typeof(el.desc) === 'object') desc = TranslateLabel(el.desc, uiLang);

        // Social media links
        var contactSocialMedia = [];
        if(typeof(contact.social) === 'object') {
            for(var social in contact.social) {
                console.log(contact);
                if(contact.social.hasOwnProperty(social)) {
                    var smicon = WebURL + 'img/icons/socialmedia/' + social + '.svg';
                    contactSocialMedia.push(<a href={contact.social[social]}><img width="32px" src={smicon} alt={label} /></a>);
                }
            }
        }

        // Contact part
        var contactDisplay = (<table><tbody>
            {contact.phone &&
            <tr>
                <td width="50%">{t('label_phone')}</td>
                <td><a href="{contact.phoneLink}">{contact.phone}</a></td>
            </tr>
            }
            {contact.email &&
            <tr>
                <td>{t('label_email')}</td>
                <td><a href={contact.emailLink}>{contact.email}</a></td>
            </tr>
            }
            {contact.web &&
            <tr>
                <td>{t('label_web')}</td>
                <td><a href={contact.web}>{contact.web}</a></td>
            </tr>
            }
            {contactSocialMedia.length > 0 && <tr><td colSpan="2" className='sm_icons'>{contactSocialMedia}</td></tr>}
            </tbody></table>);
        
        // Opening hours
        var workHours = '';
        if(el.type == 1) // Only for companies
            workHours = (<p className='sup_open_hours'><b>{t('label_openhour')} : </b>{GenerateWorkingHours(el.open, t)}</p>);

        // Generate card
        var logoImg = WebURL;
        if(typeof(el.logo) === 'object' && el.logo !== null) 
            logoImg += 'data/' + el.id + '/' + el.logo.s ? el.logo.s : el.logo.orig;
        else
            logoImg += 'img/logo-pl.png';

        var card = (
            <div className='card_inner'>
                <h2>{(el.name) ? el.name + ' - ' : ''}{label}</h2>
                <Divider variant="middle" sx={{ marginTop: 1, marginBottom: 1 }} />
                <div className='card_content'>
                    <div className='card_logo'>
                        <img src={logoImg} alt={label} />    
                    </div>
                    <div className='card_detail'>
                        <p className='card_addr'>{supAddrStr}</p>
                        <div className='card_contact'>
                            {contactDisplay}
                        </div>
                        <div className='card_openhours'>
                            {workHours}
                        </div>
                    </div>
                </div>
            </div>);
        
        // Result list
        results.push(<Card key={el.id}>{card}</Card>);

        // Map list
        mapMarkerList.push({id: el.id, loc: el.loc, popup: card});
    })

    // Output result
    
    return (
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={(e,v) => setTabValue(v)}
              aria-label="lab API tabs example"
            >
              <Tab label={t("searchPage_search_search_result_result")} value="1" />
              <Tab label={t("searchPage_search_search_result_map")} value="2" />
            </TabList>
          </Box>

          <TabPanel value="1" className="p0">
            
            <div className='search_results'>
                <h2 className="header2">{t("searchPage_search_search_result_title")}</h2>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    {results}
                </div>
            </div>

          </TabPanel>
          <TabPanel value="2">

            <MapContainer center={mapcenter} zoom={14} style={{ height: '75vh'}}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              {mapMarkerList.map((item)=> (
                <Marker position={item.loc} key={item.id}>
                
                <Popup minWidth='300px' maxWidth="90%" maxHeight="auto" key={item.id}>
                  {item.popup}
                </Popup>
              </Marker>
              ))}
              
            </MapContainer>

          </TabPanel>

        </TabContext>
    );

}

export default SearchResults;