import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import DoneIcon from "@mui/icons-material/Done";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import AddCategory from "./AddCategory";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import "./profile.css";

const useStyles = makeStyles({
  hide: {
    display: "none !important",
  },
});

const TestCat = () => {
  //to use styles
  const classes = useStyles();

  //company subcat id from server
  const [subCategoriesCompany, setSubCategoriesCompany] = useState([
    {
      mainId: 1,
      subCats: [1, 3],
    },
    {
      mainId: 2,
      subCats: [2, 3],
    },
  ]);

  //osszeset lekerdezni es innen kiszedni a nevet
  const cats = [
    {
      id: 1,
      label: {
        ro: "Instalator Apa",
        en: "Water stuff",
        hu: "Viz dolog",
      },
      subCategories: [
        {
          id: 0,
          label: {
            ro: "Apa",
            en: "Water",
            hu: "Viz",
          },
        },
        {
          id: 1,
          label: {
            ro: "Apa 1",
            en: "Water 1",
            hu: "Viz 1",
          },
        },
        {
          id: 2,
          label: {
            ro: "Apa 2",
            en: "Water 2",
            hu: "Viz 2",
          },
        },
        {
          id: 3,
          label: {
            ro: "Apa 3",
            en: "Water 3",
            hu: "Viz 3",
          },
        },
      ],
    },
    {
      id: 2,
      label: {
        ro: "Calculator chestii",
        en: "Computer stuff",
        hu: "Szamitogep",
      },
      subCategories: [
        {
          id: 0,
          label: {
            ro: "Monitor",
            en: "Monitor en",
            hu: "Monitor hu",
          },
        },
        {
          id: 1,
          label: {
            ro: "Acumlator",
            en: "Battery",
            hu: "Aksi",
          },
        },
        {
          id: 2,
          label: {
            ro: "Processor RO",
            en: "Processor",
            hu: "Processor hu",
          },
        },
        {
          id: 3,
          label: {
            ro: "Processor RO 3",
            en: "Processor 3",
            hu: "Processor hu 3",
          },
        },
      ],
    },
    {
      id: 3,
      label: {
        ro: "Alte chestii",
        en: "Other stuff",
        hu: "mas cucc",
      },
      subCategories: [
        {
          id: 0,
          label: {
            ro: "Alte chestii 1",
            en: "Other stuff 1",
            hu: "mas cucc 1",
          },
        },
        {
          id: 1,
          label: {
            ro: "Alte chestii 2",
            en: "Other stuff 2",
            hu: "mas cucc 2",
          },
        },
        {
          id: 2,
          label: {
            ro: "Alte chestii 3",
            en: "Other stuff 3",
            hu: "mas cucc 3",
          },
        },
      ],
    },
  ];

  //max cat
  const [maxCat, setMaxCat] = useState(2);
  //max sub cat
  const [maxSubCat, setSubMaxCat] = useState(3);

  //for edit button
  const [edit, setEdit] = useState(false);

  //checkbox states
  const [checked, setChecked] = useState(true);
  const [unchecked, setUnchecked] = useState(false);

  //errors true if more than maxSubCat checked or less than 1
  const [errors, setErrors] = useState([]);

  //edit & save button
  const handleEdit = () => {
    if (edit) {
      const subCatLenght = [];
      subCategoriesCompany.forEach((element) =>
        subCatLenght.push(
          element.subCats.length <= 3 && element.subCats.length >= 1
        )
      );
      if (!subCatLenght.includes(false)) {
        //on save send data to server here
        console.log(subCategoriesCompany);
      }
    }
    setEdit(!edit);
  };

  //on edit subcat list
  const handleChange = (event, mainIndex) => {
    const value = parseInt(event.target.value);
    //new array to set state
    var newArray = subCategoriesCompany;
    var subCatLenght = 0;
    newArray.forEach((element) => {
      if (element.mainId == mainIndex) {
        event.target.checked
          ? element.subCats.push(value)
          : (element.subCats = element.subCats.filter((a) => a != value));

          subCatLenght = element.subCats.length;
      }
    });
    setSubCategoriesCompany([...newArray]);

    //errors if lenght problem
    var newError = errors;
    subCatLenght > maxSubCat || subCatLenght < 1
      ? (newError[mainIndex] = true)
      : (newError[mainIndex] = false);
    setErrors([...newError]);
  };

  //add Category
  const handleAddCategory = () => {
    console.log("itt");
  };

  const [addState, setAddState] = useState(false);

  return (
    <div className="p-element">
      <h2>Kategoriak</h2>
      {subCategoriesCompany.map((category, index) => (
        <div key={category.mainId}>
          <h2>Fo kategoria</h2>
          <p>
            {cats.map((cat) =>
              cat.id == category.mainId ? cat.label.ro : ""
            )}
          </p>
          <h3>Al kategoriak</h3>
          <FormControl
            required
            error={errors[category.mainId]}
            component="fieldset"
            sx={{ m: 3 }}
            variant="standard"
          >
            <FormGroup>
              {cats.map((cat) =>
                cat.id == category.mainId
                  ? cat.subCategories.map((subCat) =>
                      category.subCats.includes(subCat.id) ? (
                        <FormControlLabel
                          disabled={!edit}
                          key={subCat.id}
                          control={
                            <Checkbox
                              value={subCat.id}
                              checked={checked}
                              onChange={(event) =>
                                handleChange(event, category.mainId)
                              }
                            />
                          }
                          label={subCat.label.ro}
                        />
                      ) : (
                        <FormControlLabel
                          className={edit ? null : classes.hide}
                          disabled={!edit}
                          key={subCat.id}
                          control={
                            <Checkbox
                              value={subCat.id}
                              onChange={(event) =>
                                handleChange(event, category.mainId)
                              }
                              checked={unchecked}
                            />
                          }
                          label={subCat.label.ro}
                        />
                      )
                    )
                  : ""
              )}
            </FormGroup>
            <FormHelperText>
              Maximum 3 kategoriat valasythatsy a csomagodba. Minimum 1-et
              jelolj.
            </FormHelperText>
          </FormControl>
        </div>
      ))}
      <Button
        variant="contained"
        className="edit-btn"
        onClick={handleEdit}
        disabled={errors.includes(true)}
        startIcon={edit ? <DoneIcon /> : <EditSharpIcon />}
      >
        {edit ? "Save" : "Edit"}
      </Button>
      <Button
        variant="contained"
        disabled={subCategoriesCompany.length > maxCat}
        onClick={(event) => {setAddState(!addState)}}
      >
        Add Category
      </Button>
      {subCategoriesCompany.length > maxCat ? <p>Vegyel elofizetest tovabbi fo kategoria hozza adasahoz</p> : ""} 
      <div className={addState ? null : classes.hide}>
        <AddCategory cats={cats} maxSubCat={maxSubCat}/>
      </div>
    </div>
  );
};

export default TestCat;
