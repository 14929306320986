import React from "react";
import Hero from "../Hero/Hero";
import TopCategories from "../TopCategories/TopCategories";
import BackroundImage from "../BackroundImage/BackroundImage";
import Rating from "../Rating/Rating";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { GiPipes } from "react-icons/gi";
import { MdElectricalServices } from "react-icons/md";
import { GiWashingMachine } from "react-icons/gi";
import { GiGasStove } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import TopServices from "./TopServices/TopServices";
import Help from "../Help/Help";
import Footer from "../Footer/Footer";
import Banner from "../../Banner/Banner";
import Storage from "../../../dataprovider";



export default function Home() {

  console.log(Storage.cats);

  const { i18n } = useTranslation();

  function changeLanguage(e) {
    i18n.changeLanguage(e.currentTarget.value);
  }
  const { t } = useTranslation();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const responsiveHero = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const items = [
    {
      imageSource:"assets/7.jpg",
       name: t("frontPage_hero_text"),
       text:t("frontPage_hero_bottom"),
       button:"Segítséget kérek!",
  },
  {
    imageSource:"assets/construction-worker.jpg",
      name: t("Home_hero_title_second_slide"),
      text: t("Home_hero_text_second_slide"),
      button:"Segítséget kérek!",
},
{
    imageSource:"assets/sport.jpg",
      name:t("Home_hero_title_third_slide"),
      text:t("Home_hero_text_third_slide"),
      button:"Segítséget kérek!",
},
{
  imageSource:"assets/hatter.jpg",
    name:t("Home_hero_title_fourth_slide"),
    text: t("Home_hero_text_fourth_slide"),
    button:"Segítséget kérek!",
},
    
]

  return (
    <>
    {/* <div className="carousel-wrappers"> */}
      {/* <Carousel responsive={responsive}> */}
      {/* <Hero   />
      //  imageSource={require("../../../assets/7.jpg")}
      //  name="SEGÍTSÉG, BAJ VAN"
      //  text="Legyen szó vízvezetékekről, villanyról vagy háztartási gépekről a szerelők csapata rendelkezésére áll azok javításában vagy akár cseréjében. Forduljon hozzánk bizalommal."
      //  button="Segítséget kérek!"
     */}
      {/* </Carousel> */}
      {/* </div> */}
      <Carousel responsive={responsiveHero}>
            {
                items.map( (item, i) => <Hero key={i} imageSource={item.imageSource} name={item.name} text={item.text} button={item.button} /> )
            }
        </Carousel>
      
      <h2 className="title_categories">{t("Home_top_services_title")}</h2>
    <div className="desc-wrapper">
    <TopServices
            title="Csomag1" 
            description={t("Home_top_services_first_paragraph")}

            />
    <TopServices
            title="Csomag2"
            description={t("Home_top_services_second_paragraph")}
    />
    <TopServices
            title="Csomag3"
            description={t("Home_top_services_third_paragraph")}
            />
    <TopServices
            title="Csomag4"
            description={t("Home_top_services_fourth_paragraph")}
      />
      </div>


      <BackroundImage
      />
   
      <h2 className="title_categories">{t("Home_top_categories_title")}</h2>
      <div className="desc-wrapper">
        <TopCategories
          title={t("frontPage_cat1")}
          description={t("frontPage_cat1Text")}
          icon={MdElectricalServices}
        />
        <TopCategories
          title={t("frontPage_cat2")}
          description={t("frontPage_cat2Text")}
          icon={GiPipes}
        />
        <TopCategories
          title={t("frontPage_cat3")}
          description={t("frontPage_cat3Text")}
          icon={GiGasStove}
        />
        <TopCategories
          title={t("frontPage_cat4")}
          description={t("frontPage_cat4Text")}
          icon={GiWashingMachine}
        />
      </div>
      {/* <Banner></Banner> */}
      <Help></Help>
  
      {/* <h2 className="title_rating">{t("frontPage_topReview")}</h2> */}
      

      {/*  tiny slider: https://www.npmjs.com/package/react-multi-carousel */}*{" "}
      {/* <div className="carousel-wrapper"> */}
        {/* <Carousel
          responsive={responsive}
          //hogy végtelenszer ismétlődjön:
          //infinite
        > */}
          {/* <Rating
            rating={4}
            review={
              "Lorem Ipsum is simply dummy text of the printing, is simply dummy text of the printing and typesetting industry."
            }
            job="villanyszerelő"
            name="Kis János"
            imageSource={require("../../../assets/szerelo3.jpg")}
          />
          <Rating
            rating={5}
            review={
              "Lorem Ipsum is simply dummy text of the printing, is simply dummy text of the printing and ."
            }
            job="vízszerelő"
            name="Kedves Előd"
            imageSource={require("../../../assets/szerelo2.jpg")}
          />
          <Rating
            rating={5}
            review={
              "Lorem Ipsum is simply dummy text of the printing,is simply dummy text of the printing and typesetting"
            }
            job="gázszerelő"
            name="Lakatos Norbert"
            imageSource={require("../../../assets/szerelo3.jpg")}
          />
          <Rating
            rating={5}
            review={
              "Lorem Ipsum is simply dummy text of the printing, is simply dummy text of the printing and typesetting industry."
            }
            job="háztartásigép szerelő"
            name="Mihály Árpád"
            imageSource={require("../../../assets/szerelo4.jpg")}
          />
          <Rating
            rating={3}
            review={
              "Lorem Ipsum is simply dummy text of the printing, is simply dummy text of the printing and typesetting industry."
            }
            job="villanyszerelő"
            name="Nagy Péter"
            imageSource={require("../../../assets/szerelo5.jpg")}
          />
        </Carousel> */}
      {/* </div> */}
    </>
  );
}
