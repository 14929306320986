import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HelpUser from "../../../asset/HelpUser.jpeg";
import HelpProvider from "../../../asset/HelpProvider.jpeg";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import './HelpPage.css'


const HelpPage = (props) => {

    const { i18n } = useTranslation();

    function changeLanguage(e) {
        i18n.changeLanguage(e.currentTarget.value);
    }
    const { t } = useTranslation();
    const icon = React.createElement(props.icon, {});
    //popup
    const [isOpen, setIsOpen] = useState(false);
    // const [HelpUser, HelpProvider] = useState(0);
    return (
        <div class="help-page">
            <h1 className="help_page_title"> {t("help_page_title")}</h1>
            <div className='logAll'>
                <div className='butt1'>
                    <div className='buttons'>
                        {t("HelpPage_help_user_title")}
                    </div>
                    <img className="help" onClick={() => setIsOpen(true)} src={HelpUser}></img>
                    {/* popup oncklick for help, picture modals, lightbox */}
                    {isOpen && <Lightbox
                        imageTitle={"Help Find"}
                        imageCaption={"help for user"}
                        mainSrc={HelpUser}
                        onCloseRequest={() => setIsOpen(false)}
                    />}
                </div>
                <div className='butt2'>
                    <div className='buttons'>
                        {t("HelpPage_help_provider_title")}
                    </div>
                    <img className="help" onClick={() => setIsOpen(true)} src={HelpProvider}></img>
                    {isOpen && <Lightbox
                        imageTitle={"Help registration for Provider"}
                        imageCaption={"help for provider"}
                        mainSrc={HelpProvider}
                        onCloseRequest={() => setIsOpen(false)}
                    />}
                </div>
            </div>
        </div>
    )

}

export default HelpPage