
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { ImWrench } from "react-icons/im";
import {AiFillFacebook} from 'react-icons/ai'
import {AiFillInstagram} from 'react-icons/ai'
import {AiFillLinkedin} from 'react-icons/ai'
import {SiGmail} from 'react-icons/si'
import { SiGooglemaps } from "react-icons/si"
import { useTranslation } from "react-i18next";
import Logo from '../../../asset/FindPlus.png';
import TandC from './TermsAndConditions';

function Footer() {

  const { i18n } = useTranslation();

  function changeLanguage(e) {
    i18n.changeLanguage(e.currentTarget.value);
  }
 const { t } = useTranslation();

  return (
    <div className='footer-container'>
     {/* <section className='footer-subscription'>
        <p className='footer-subscription-heading'> 
        Szeretnel ertesulni a legujabb szolgaltatasainkrol?
        Irakozz fel, hogy tudj valogatni a kulonbozo ujdonsagokbol.</p>
        <div className='input-areas'>
          <form>
            <input
                className='footer-input'
                name='email'
                type='email'
                placeholder='Email cim'
                >
            </input>
            <button
            className='footer-btn'
            >mentes
            </button>
          </form>
        </div>
     </section> */}
     <div className='footer-links'>
     <div className='footer-link-wrapper'>
        <div className='footer-link-items'>
          <h3>{t("Footer_first_column_info")}</h3>
          <Link to='/TermsAndConditions'>{t("Footer_first_column_first")}</Link>
          {/* <Link to='/'>Segitseg</Link> */}
          <Link to='/'>{t("Footer_first_column_second")}</Link>
          <Link to='/'>{t("Footer_first_column_third")}</Link>
        </div>
        <div className='footer-link-items'>
          <h3>{t("Footer_second_column_our_services")}</h3>
          <Link to='/'>{t("Footer_second_column_first")}</Link>
          <Link to='/'>{t("Footer_second_column_second")}</Link>
          {/* <Link to='/'>{t("Footer_second_column_third")}</Link> */}
          {/* <Link to='/'>Csomagjanink</Link> */}
        </div>
        <div className='footer-link-items'>
          <h3>{t("Footer_thid_column_our_contact")}</h3>
          {/* <Link to='/'>{t("Footer_third_column_first")}</Link> */}
          <Link to='/'>Email</Link>
          <Link to='/'>Facebook</Link>
          {/* <Link to='/'>Fizetes</Link> */}
        </div>
        {/* <div className='footer-link-items'>
          <h3>{t("Footer_fourth_column_about_us")}</h3>
          <Link to='/'>Kezdet</Link>
          <Link to='/'>Telefon</Link>
          <Link to='/'>Email</Link>
          <Link to='/'>Csomagjanink</Link>
        </div> */}
     </div>
     </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-log'>
            <Link to='/' className='social-logo'>
            <img className="logofooter" src={Logo}></img> FIND PLUS
            </Link>
          </div>
          <small className='website-rights'>Copyright  © 2023</small>
          <div className='social-icons'>
            <Link
              className='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'>
                  <AiFillFacebook />
            </Link>
            <Link
              className='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Instagram'>
                  <AiFillInstagram />
            </Link>
            <Link
              className='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Instagram'>
                  <AiFillLinkedin />
            </Link>
            <Link
              className='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Instagram'>
                  <SiGmail />
            </Link>
          </div>
        </div>
      </section>

    </div>
  
  )
}

export default Footer