import React, { Component } from 'react';
import { Stepper } from 'react-form-stepper';
import './SingUp.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { withTranslation } from 'react-i18next';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {TranslateCountyList, TranslateCityList} from '../../../../utils/utils'
import { useState } from 'react';
import { useEffect } from 'react';

// import MaterialTable from 'material-table';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';



class CourseDetails extends Component {

  state = {
    uiLang: 'ro',
    countyList: [],
    cityList: [],
    forceReload: true,
    selCityObj: null,
    selCountyObj: null,
  }

  continue = e => {
    e.preventDefault();

    const isPhoneValid = this.props.validatePhone();
    const isContactEmailValid = true;
    const isCuiValid = true;
    const isAddressValid = true;
    const isbankName = true;
    const isbankAccount = true;
    const validateZIP = true;
    const isAccountType = this.props.validateAccountType();
    const isDescription = this.props.validateDescription();

    if(isAccountType != 'individual'){
        const isContactEmailValid = this.props.validateContactEmail();
        const isCuiValid = this.props.validateCui();
        const isAddressValid = this.props.validateAddress();
        const isbankName = this.props.validatebankName();
        const isbankAccount = this.props.validatebankAccount();
        const validateZIP = this.props.validateZIP();
    }
    if (isPhoneValid && isCuiValid && isAddressValid && isbankName && isbankAccount && isAccountType && isContactEmailValid && validateZIP) {
      this.props.nextStep();
    }
    if (isAccountType == 'individual' && isPhoneValid && isCuiValid && isAddressValid && isAccountType && isContactEmailValid ) {
      this.props.nextStep();
    }
  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }

  constructor(props) {
    super(props);

    this.state.uiLang = props.uiLang;

    this.dataProvider = props.dataProvider;
  }

  componentDidMount() {
    var _countyList = TranslateCountyList(this.dataProvider.ListCounties(), this.uiLang);
    this.setState({countyList: _countyList });
    this.setState({selCountyObj: _countyList[0] });
    this.state.selCityObj = {};

    const me = this;
    this.dataProvider.ListCitiesForCounty(_countyList[0], (list) => {
      var _list = TranslateCityList(list, me.state.uiLang);
      me.setState({cityList: _list});
      me.setState({selCityObj: _list[0]});
      // TODO: BUG (javitva): a komponensek felepitese nem engedi betolteskor az elso varos beallitasat (ez hiba, ha valaki
      // pont a lista elso varosabol van, nem fog tudni feliratkozni)
      me.props.handleChange('selCityObj')({target: { value: _list[0] } });
    });
  }


  render() {
    const { t } = this.props;
    const me = this;

    const {
      contactPhone,
      contactemail,
      handleChange,
      validateDescription,
      value,
      cui,
      cityObj,
      address,
      zip,
      bankName,
      bankAccount,
      company,
      describe,
      accountType,
      isErrorPhone,
      isErrorContactEmail,
      isErrorCui,
      isErrorAddress,
      isErrorZIP,
      isErrorbankName,
      isErrorbankAccount,
      isErrorDescription,
      isErrorCompany,
      isErrorAccountType,
      errorMessagePhone,
      errorMessageContactEmail,
      errorMessageCui,
      errorMessageAddress,
      errorMessageZIP,
      errorMessageDescription,
      errorMessagebankName,
      errorMessagebankAccount,
      errorMessageCompany,
      errorMessageAccountType,
      validatePhone,
      validateContactEmail,
      validateCui,
      validateAddress,
      validateZIP,
      validateAccountType,
      validatebankName,
      validatebankAccount,
      isPhoneValid,

      countyList,
      cityList,
      selCountyObj,
    } = this.props;

    const handleCountyChange = (e, v) => {
      me.setState({selCountyObj: v});
      me.dataProvider.ListCitiesForCounty(v, (list) => {
        var _list = TranslateCityList(list, me.state.uiLang);
        me.setState({cityList: _list});
        me.setState({selCityObj: _list[0]});
      });
    }
    const handleCityChange = (e, v) => {
      me.setState({selCityObj: v});
      handleChange('selCityObj')({target: { value: v } });
    }

    return (
      <div className='form'>
        <form>

          <Stepper
            steps={[{ label: t("Summary_company_personal_details") }, { label: t('Summary_company_information') }, { label: t('Summary_company_summary') }]}
            activeStep={1}
            styleConfig={{
              activeBgColor: '#2b7cff',
              activeTextColor: '#fff',
              inactiveBgColor: '#fff',
              inactiveTextColor: '#2b7cff',
              completedBgColor: '#fff',
              completedTextColor: '#2b7cff',
              size: '3em'
            }}
            className={'stepper'}
            stepClassName={'stepper__step'}
          />



          <div className='form-group'>
          <h3>{t("CourseDetails_title_choose_radiogroup")}</h3>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">{t("Summary_company_personal_details")}</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="accountType"
                value={accountType}
                onChange={handleChange('accountType')}
                isOptionEqualToValue={(v1, v2) => v1.id === v2.id}
              >
                <FormControlLabel className='individual' value="individual" control={<Radio />} label={t("CourseDeatils_coose_individual")} />
                <FormControlLabel value="company" control={<Radio />} label={t("CourseDeatils_coose_company")} />
              </RadioGroup>
            </FormControl>
            <p className='error'>{isErrorAccountType && errorMessageAccountType}</p>

            <div className='form-group__element'>
              <label htmlFor='county' className='form-group__label'>
                {t("CourseDeatils_company_location")} {accountType == 'company' ? ('(kötelező)') : ('(opcionális)')}
              </label>
              <div className="searched-notlogged">
                <Autocomplete
                  disablePortal
                  id="combo-box-county"
                  className="input-combo"
                  options={this.state.countyList}
                  value={this.state.selCountyObj}
                  onChange={handleCountyChange}
                  isOptionEqualToValue={(v1, v2) => (v1 && v2) && v1.cc === v2.cc}
                  sx={{ width: '100%' }}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label={t("Permis_search_firstsection_county")}/>}
                />
              </div>
            </div>

            <div className='form-group__element'>
              <label htmlFor='city' className='form-group__label'>
                {/* {t("CourseDeatils_company_location")} {accountType == 'company' ? ('(kötelező)') : ('(opcionális)')} */}
              </label>
              <div className="searched-notlogged">
                <Autocomplete
                  disablePortal
                  id="combo-box-city"
                  noOptionsText="No labels"
                  className="input-combo"
                  value={this.state.selCityObj}
                  options={this.state.cityList}
                  onChange={handleCityChange}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => (option && value) && (option.id === value.id)}
                  sx={{ width: '100%' }}
                  renderInput={(params) => <TextField {...params} label={t("Permis_search_firstsection_location")} />}
                />
              </div>
            </div>

            <div className='form-group__element'>
              <label htmlFor='adress' className='form-group__label'>
              {t("CourseDeatils_company_location")} {t("CourseDeatils_phone_number_obligatory")}
              </label>
              <input type='text' placeholder='ex. Kossuth Lajos 5/A' value={address} name='address' onChange={handleChange('address')} onBlur={validateAddress} className='form-group__input' />
              <p className='error'>{isErrorAddress && t(errorMessageAddress)}</p>
            </div>

            <div className='form-group__element'>
              <label htmlFor='zip' className='form-group__label'>
                {t("CourseDeatils_company_zip")} {t("CourseDeatils_phone_number_obligatory")}
              </label>
              <input type='text' placeholder='ex 530113' value={zip} name='zip' onChange={handleChange('zip')} onBlur={validateZIP} className='form-group__input' />
              <p className='error'>{isErrorZIP && t(errorMessageZIP)} </p>
            </div>

            <div className='form-group__element'>
              <label htmlFor='phone' className='form-group__label'>
              {t("CourseDeatils_phone_number")} {t("CourseDeatils_phone_number_obligatory")}
              </label>
              <input type='number' placeholder='ex 0747599325' value={contactPhone} name='phone' onChange={handleChange('contactPhone')} onBlur={validatePhone} className='form-group__input' />
              <p className='error'>{isErrorPhone && t(errorMessagePhone)}</p>
            </div>
            {/* <div className='form-group__element'>
              <label htmlFor='describtion' className='form-group__label'>
                {t("ServiceDetails_company_decription")} {t("CourseDeatils_phone_number_obligatory")}
              </label>
              <textarea type='text' rows="7" cols="50" placeholder='ex. A vállalkozásunk teljeskörű házfelújításokat vállal, legyen szó külső, vagy belső munkálatokról.
                ex. Compania noastră se ocupă de renovarea completă a locuinței, fie că este vorba de renovare exterioară sau interioară.' value={describe} name='describe' onChange={handleChange('describe')} onBlur={validateDescription} className='form-group__input'/>
              <p className='error'>{isErrorDescription && t(errorMessageDescription)}</p>
            </div> */}
            {/*<div className='form-group__element'>*/}
            {/*  <label htmlFor='email' className='form-group__label'>*/}
            {/*  {t("CourseDeatils_contact_email_optional")} {t("CourseDeatils_phone_number_obligatory")}*/}
            {/*  </label>*/}
            {/*  <input type='email' placeholder='ex. home@renovation.com' value={contactemail} name='email' onChange={handleChange('contactemail')} className='form-group__input' />*/}
            {/*  <p className='error'>{isErrorContactEmail && t(errorMessageContactEmail)}</p>*/}
            {/*</div>*/}
            <div className='form-group__element'>
              <label htmlFor='number' className='form-group__label'>
              {t("CourseDeatils_cod_unic_de_inregistrare")} {t("CourseDeatils_phone_number_obligatory")}
              </label>
              <input type='number' placeholder='ex. 46319300' value={cui} name='cui' onChange={handleChange('cui')} onBlur={validateCui} className='form-group__input' />
              <p className='error'>{isErrorCui && t(errorMessageCui)}</p>
            </div>
           
            {/*<div className='form-group__element'>*/}
            {/*  <label htmlFor='bankname' className='form-group__label'>*/}
            {/*  {t("CourseDeatils_company_bank_name")} {accountType == 'company' ? ('(kötelező)') : ('(opcionális)')}*/}
            {/*  </label>*/}
            {/*  <input type='text' placeholder='ex. Banca Transilvania' value={bankName} name='bankName' onChange={handleChange('bankName')} className='form-group__input' />*/}
            {/*  <p className='error'>{isErrorbankName && t(errorMessagebankName)}</p>*/}
            {/*</div>*/}
            {/*<div className='form-group__element'>*/}
            {/*  <label htmlFor='bankaccount' className='form-group__label'>*/}
            {/*  {t("CourseDeatils_company_bank_public_acount")} {accountType == 'company' ? ('(kötelező)') : ('(opcionális)')}*/}
            {/*  </label>*/}
            {/*  <input type='text' placeholder='ex. RO06BTRLRONCRT0642428797' value={bankAccount} name='bankAccount' onChange={handleChange('bankAccount')} className='form-group__input' />*/}
            {/*  <p className='error'>{isErrorbankAccount && t(errorMessagebankAccount)}</p>*/}
            {/*</div>*/}
          </div>
          <div className='buttons'>
            <button className='buttons__button buttons__button--back' onClick={this.back}>{t("Regist_provider_steps_back")}</button>
            <button className='buttons__button buttons__button--next' onClick={this.continue}>{t("Regist_provider_steps_next")}</button>
          </div>

        </form>
      </div>
    )
  }
}

export default withTranslation()(CourseDetails);