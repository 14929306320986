function GetBrowserLanguage() {
    var ln = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

    if(typeof(ln) !== 'string') return 'ro';

    if(ln.indexOf('-') > 0) {
        var t = ln.split('-'); // en-US; etc
        return t[0];
    }
}

function TranslateCountyList(list, lang) {
    var reslist = [];

    for(var i = 0; i < list.length; i++) {
      var item = list[i];
      var label = item.labels[lang] ? item.labels[lang] : item.labels[Object.keys(item.labels)[0]];
      reslist.push({cc: item.cc, label: label });
    }

    return reslist;
  }

  function TranslateCityList(list, lang) {
    var reslist = [];

    for(var i = 0; i < list.length; i++) {
      var item = list[i];
      var label = item.labels[lang] ? item.labels[lang] : item.labels[Object.keys(item.labels)[0]];
      reslist.push({id: item.id, label: label, cc: item.cc, loc: item.loc });
    }

    return reslist;
  }

  function TranslateLabel(labels, lang) {
    return labels[lang] ? labels[lang] : labels[Object.keys(labels)[0]];
  }

  function ArrayEquals(arr1, arr2) {
    if(!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) return false;

    for(var i = 0; i < arr1.length; i++)
      if(arr1[i] !== arr2[i]) return false;
    
    return true;
  }

  function TranslateAndMergeCategoryList(catList, uiLang) {
    var res = [];
    var mcatid = 1;
    for(var i = 0; i < catList.length; i++) {
      var cat = catList[i];
      var gcatLabel = cat.label[uiLang];
      if(!Array.isArray(cat.subCategories) || cat.subCategories.length == 0) continue;

      for(var j = 0; j < cat.subCategories.length; j++) {
        var scat = cat.subCategories[j];
        res.push({
          id: scat.id,
          disabled: false,
          label: scat.labels[uiLang],
          gcatlabel: gcatLabel,
          parcatid: cat.id,
        });
      }
      mcatid++;
    }

    return res;
  }

  function TranslateCategoryList(catList, uiLang) {
    var res = [];

    for(var i = 0; i < catList.length; i++) {
      var cat = catList[i];
      var ncat = {
        id: cat.id,
        label: TranslateLabel(cat.label, uiLang),
        subCategories: []
      }

      for(var j = 0; j < cat.subCategories.length; j++) {
        var scat = cat.subCategories[j];
        ncat.subCategories.push({
          id: scat.id,
          label: TranslateLabel(scat.labels, uiLang)
        });
      }

      res.push(ncat);
    }

    return res;
  }

  function TranslateEC(ec, lang) {
    var labels = {
      'ERR_FUPLOAD': {
        'hu': 'Hiba történt az állomány vagy erőforrás feltöltésében',
        'ro': 'Eroare în încărcarea fișierului sau a resursei',
        'en': 'Error uploading file or resource'
      },

      'ERR_FSIZEB': {
        'hu': 'Az állomány mérete tul nagy',
        'ro': 'Mărimea fișierul este prea mare',
        'en': 'File size is too big'
      },

      'ERR_FSAVE': {
        'hu': 'Hiba történt az állomány vagy erőforrás elmentése során',
        'ro': 'A apărut o eroare în salvarea fișierului sau a resursei',
        'en': 'Unable to save file or resource'
      },

      'ERR_FTYPE': {
        'hu': 'Érvénytelen állomány típus vagy formátum',
        'ro': 'Fișierul nu este de tip imagine sau este invalid',
        'en': 'Invalid file type'
      },

      'ERR_FNIMAGE': {
        'hu': 'Az állomány nem egy érvényes képformátum',
        'ro': 'Fișierul nu are un format valid',
        'en': 'Uploaded file is not a valid image'
      },

      'ERR_NODATA': {
        'hu': 'Nincs vagy hibás adat volt szolgáltatva',
        'ro': 'Lipsă sau date incorecte introduse',
        'en': 'No or invalid data was provided'
      },

      'ERR_LIMIT': {
        'hu': 'Dolgok maximum száma elérve, többet nem lehet hozzáadni',
        'ro': 'Numărul maxim de itemuri a fost atins, nu se mai pot adăuga',
        'en': 'Item limit reached, adding additional items is not possible'
      },

      'ERR_SRV': {
        'hu': 'Szerver hiba, kérem probálja meg késöbb',
        'ro': 'Eroare server, vă rugăm încercați mai târziu',
        'en': 'Server error, please try again later'
      },

      'ERR_U': {
        'hu': 'Ismeretlen hiba',
        'ro': 'Eroare nespecificată',
        'en': 'Unknow error'
      },
     
    }; // end labels

    if(typeof(labels[ec]) === 'undefined') return TranslateLabel(labels['ERR_U'], lang);

    return TranslateLabel(labels[ec], lang);
  }

export {
  GetBrowserLanguage, 
  TranslateCountyList, 
  TranslateCityList, 
  TranslateLabel, 
  TranslateEC,
  TranslateCategoryList, 
  TranslateAndMergeCategoryList, 
  ArrayEquals
}