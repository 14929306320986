import { createContext } from "react";
import {APIQuery} from "./api";
import {GetBrowserLanguage} from "./utils/utils";
import i18next from "i18next";

const DBVER = 3;

class DataProviderObj {
    constructor() {

        this.cats = []; // categories
        this.counties = []; // stored counties (locations)
        this.locs = []; // stored locations
        this.dbver = DBVER;
        this.inited = false;

        this.appConfig = {
            lang: 'ro',
            defCounty: {cc: 'HR', label: 'Harghita'},
            defCity: null,
        };
    }


    RefreshOnlineData(fnStageCallback) {

        var dataObj = this;

        // Load categories
        APIQuery('/app', {"a" : "f"}, 
            function(data) {
                console.log(data.data.cats);
                dataObj.cats = data.data.cats;
                dataObj.counties = data.data.cc;
                dataObj.locs = data.data.l;
                dataObj.Save();
                fnStageCallback(1);
            },
            function(error) {
                console.error("Error (ST1): ", error);
                fnStageCallback(-1);
            }
        );
    }

    /*
    fnStageCallback value given 
        -1 on error
        0 - first time run
        1 - regular startup
    */
    Init(fnStageCallback) {

        if(this.inited) return;
        this.inited = true;

        var findPlusDataStr = localStorage.getItem("fpd");
        if(findPlusDataStr === null) {
            this.RefreshOnlineData(fnStageCallback);
            return;
        }

        try {
            var findPlusData = JSON.parse(findPlusDataStr);
            
            this.dbver = findPlusData.v;
            this.counties = findPlusData.cc;
            this.cats = findPlusData.c;
            this.locs = findPlusData.l;
            
            if(typeof(findPlusData.appConfig) === 'object') {
                this.appConfig.lang = findPlusData.appConfig.lang || GetBrowserLanguage();
                this.appConfig.defCounty = findPlusData.appConfig.dcc || '';
                this.appConfig.defCity = findPlusData.appConfig.dc || '';
                this.appConfig.datatimestamp = findPlusData.appConfig.t || 0;
            } else {
                this.appConfig.lang = '';
                this.appConfig.defCounty = '';
                this.appConfig.defCity = '';
                this.appConfig.datatimestamp = Date.now();
            }

            if(this.dbver < DBVER || this.appConfig.datatimestamp < Date.now() - 86400000) {
                var dtPrv = this;
                console.log('dataProvider: begin data refresh');
                this.appConfig.datatimestamp = Date.now();
                this.RefreshOnlineData(() => { console.log('dataProvider: Data refresh completed'); fnStageCallback(); })
                return;
            }
            fnStageCallback(1 + Math.floor(Math.random() * 1000));

        } catch(e) {
            console.error("Storage: error (ST2): ", e);
            fnStageCallback(-1);
        }

        i18next.changeLanguage(this.appConfig.lang);
    }

    Save() {
        var findPlusData = {
            v: this.dbver,
            cc: this.counties,
            c: this.cats,
            l: this.locs,
            appConfig: {
                lang: this.appConfig.lang,
                dcc: this.appConfig.defCounty,
                dc: this.appConfig.defCity,
                t: this.appConfig.datatimestamp,
            },
        }
        var findPlusDataStr = JSON.stringify(findPlusData);
        localStorage.setItem('fpd', findPlusDataStr);
    }

    // -------------------------------------------
    GetAppLanguage(defLn = 'ro') {
        if(typeof(this.appConfig.lang) === 'undefined' || this.appConfig.lang === '') return defLn;
        return this.appConfig.lang;
    }
    SetAppLanguage(ln) {
        this.appConfig.lang = ln;
        this.Save();
    }

    GetDefaultCounty(defCC) {
        if(typeof(this.appConfig.defCounty) === 'undefined' || this.appConfig.defCounty === null || this.appConfig.defCounty === '') return defCC;
        return this.appConfig.defCounty;
    }
    SetDefaultCounty(ccObj) {
        this.appConfig.defCounty = ccObj;
        this.Save();
    }

    GetDefaultCity(defC) {
        if(typeof(this.appConfig.defCity) === 'undefined' || this.appConfig.defCity === null || this.appConfig.defCity === '') return defC;
        return this.appConfig.defCity;
    }
    SetDefaultCity(defCObj) {
        this.appConfig.defCity = defCObj;
        this.Save();
    }

    // -------------------------------------------
    ListCategories() {
        return this.cats;
    }

    ListCounties() {
        return this.counties;
    }

    ListCitiesForCounty(cc, fnCallback) {
        if(typeof(cc) === 'object') {
            cc = cc.cc;
        }

        if(typeof(cc) !== 'string' || cc.length !== 2) {
            console.error('CC is invalid');
            if(typeof(fnCallback) == 'function') fnCallback([]);
            return [];
        }

        if(Array.isArray(this.locs[cc]) && this.locs[cc].length > 0) {
            if(typeof(fnCallback) == 'function') fnCallback(this.locs[cc]);
            return this.locs[cc];
        }
        
        var dataObj = this;
        // Query backend
        APIQuery('/loc', {"a": "l", "c": cc}, (resp) => {
            if(Array.isArray(resp.data)) {
                dataObj.locs[cc] = resp.data;
                dataObj.Save();
                if(typeof(fnCallback) == 'function') fnCallback(resp.data);
            }
            else {
                console.error('Invalid "data" response');
                if(typeof(fnCallback) == 'function') fnCallback([]);
            }
        });

        return [];
    }
}


export const DataProviderContext = createContext(null);

export default DataProviderObj;