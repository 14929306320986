import React, {useContext, useState, useEffect} from "react";
import Button from "@material-ui/core/Button";
/*icon*/
import { HiOutlineMenu } from "react-icons/hi";
import { FaRegTimesCircle } from "react-icons/fa";
import { GrLanguage } from "react-icons/gr";
import { SiGooglemaps } from "react-icons/si"
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
// import FindPlus from '../../../public/assets/FindPlus.png';
import Logo from '../../asset/FindPlus.png';
import "./Navbar.css";
// import { Link } from "@mui/material";
import { Link } from "react-router-dom";
//languge chage
import { useTranslation } from "react-i18next";
import {LanguageContext} from "../../languages";
import {UserRoleContext} from "../../userRole";
import { APIQuery } from "../../api";
import { DataProviderContext } from "../../dataprovider";
import { ProfileContext } from "../../profile";


const Navbar = () => {
  const { i18n } = useTranslation();
  const [profile, setProfile] = useContext(ProfileContext);
  const dataProvider = useContext(DataProviderContext);

  useEffect(() => {

  }, []);

  const uiLangContext  = useContext(LanguageContext);

  function changeLanguage(e) {
    i18n.changeLanguage(e.currentTarget.value);

    uiLangContext[0] = e.currentTarget.value;

    dataProvider.SetAppLanguage(e.currentTarget.value);

    window.location.reload();
  }

  const { t } = useTranslation();
  //lenyilo kezdooladal illetve kereses
  const [click, setClick] = useState(false);
  const handleClickOn = () => setClick(!click);
  //nyelvvaltas
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    APIQuery('/dlo', {}); // Server side logout
    setProfile(null);
    localStorage.removeItem('profile');
  }
  

  return (
    <div className="navbar">
      <div className="container">
        <Link to="Home">
        <h1 className="logoName">
        <img className="logo" src={Logo}></img>
          <span>
            {/* <ImWrench /> */}
            {/* <SiGooglemaps /> */}
            Find{" "}
          </span>
          Plus
        </h1>
        </Link>
        <nav>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li >
              <Link to="/" onClick={handleClickOn}>{t("navbarhome")}</Link>
            </li>
            <li>
              <Link to="/searchpage" onClick={handleClickOn}>{t("navbarsearch")}</Link>
            </li>
            {profile === null && <li>
              <Link to="LoginAll" onClick={handleClickOn}>
                <button className="btn login-btn login-mobile">{t("home")}</button>
              </Link>
            </li>
            }
            { !!profile && profile.hasOwnProperty("role") &&  profile.role === 'provider' &&
            <li>
              <Link to="/companyprofile" onClick={handleClickOn}>{t("companyprofile")}</Link>
            </li>
            }
            {/* <li><Link to='/statistics'>Statisztikák</Link></li>
                    <li><Link to="/CraftMenList">Szerelők listája</Link></li>
                    <li><Link to='/services'>Szerelő keresés</Link></li>
                    <li><Link to='/services'>Szolgáltatásaink</Link></li> */}
          </ul>
        </nav>
        {profile === null ?
          <Link to="LoginAll">
            <button className="btn login-btn login-desktop">{t("home")}</button>
          </Link>
            :
          <button className="btn login-btn login-desktop" onClick={handleLogout}>{t("logout")}</button>
        }

        <div>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <GrLanguage className="langicon"></GrLanguage>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClose}><Button size="small" onClick={changeLanguage} value="ro">
          Ro
        </Button></MenuItem>
          <MenuItem onClick={handleClose}> <Button size="small" onClick={changeLanguage} value="en">
          Eu
        </Button></MenuItem>
          <MenuItem onClick={handleClose}><Button size="small" onClick={changeLanguage} value="hu">
          Hu
        </Button></MenuItem>
        </Menu>
      </div>
        <div className="hambi" onClick={handleClickOn}>
          {click ? (
            <FaRegTimesCircle className="icon" />
          ) : (
            <HiOutlineMenu className="icon"></HiOutlineMenu>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
