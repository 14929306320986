import React from "react";
import './SearchPage.css'
import { useState, useEffect, useContext } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchLocation from "../../search-components/SearchLocation";
import Box from "@mui/material/Box";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import SearchLocationSaved from "../../search-components/SearchLocationSaved";
import SearchResults from "../../search-components/SearchResults.js";
import TextField from "@mui/material/TextField";
import Help from "../Help/Help";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
// import useAnalyticsEventTracker from "../../Analytics/useAnalyticsEventTracker"
import { LanguageContext } from "../../../languages";
import { APIQuery } from "../../../api";
import { Autocomplete } from "@material-ui/lab";
import { DataProviderContext } from '../../../dataprovider';
// import ReactGA from 'react-ga';

// ReactGA.initialize("UA-267961577-1");


const SearchPage = () => {
  // const gaEventTracker = useAnalyticsEventTracker('SearchPage');
  const [accSearchStep2, setAccSearchStep2] = useState(true);
  const [accSearchStep3, setAccSearchStep3] = useState(true);
  const { t } = useTranslation();

  // --------------------------------------------
  const dataProvider = useContext(DataProviderContext);
  const [selSearchData, setSelSearchData] = useState({type: '', cc: '', cid: '', slocid: '', scat: '', mc: [46.35, 25.8]});
  const [sCatId, setSCatID] = useState(null);
  const [btnSearchDisabled, setBtnSearchDisabled] = useState(true);
  const [searchResultData, setSearchResultData] = useState([]);

  const uiLangContext = useContext(LanguageContext);
  var uiLang = uiLangContext[0];

  function TranslateCategoryList(catList, uiLang) {
    var res = [];
    var mcatid = 1;
    for(var i = 0; i < catList.length; i++) {
      var cat = catList[i];
      var gcatLabel = cat.label[uiLang];
      if(!Array.isArray(cat.subCategories) || cat.subCategories.length == 0) continue;

      for(var j = 0; j < cat.subCategories.length; j++) {
        var scat = cat.subCategories[j];
        res.push({
          id: scat.id,
          disabled: false,
          label: scat.labels[uiLang],
          gcatlabel: gcatLabel,
        });
      }
      mcatid++;
    }

    return res;
  }

  const [Tab1Value, setTab1Value] = useState('1');
  const handleTab1Change = (e, v) => {
    setTab1Value(v);
  };

  // Category selection
  function cmbCatListChange(v, e) {
    setSCatID(e.id);
    onLocationValueUpdate({type: 'scat', scat: e.id});
  }
  const cmbCatList = TranslateCategoryList(dataProvider.ListCategories(), uiLang);

  // Effective search
  function onLocationValueUpdate(data) {
    /*
      data: {
        type: loc | savedLoc

        // case: type == loc
        cc: 'HR'
        c: '8abc333'
        // case type == savedLoc
        slocid: 'uuid of saved location'

        // cat:
        scatid: 'uuid of subcategory'
      }
    */
    var searchOk = false;

    if(typeof(data) === 'undefined') {
      // Clear search
      setSelSearchData({type: '', cc: '', cid: '', slocid: '', scat: '', mc: [46.35, 25.8]});
      setBtnSearchDisabled(false);
      return;
    }
    var sdata = selSearchData;
    if(data.type === 'scat') {
      sdata.scat = data.scat; // Store category
    }
    if(Array.isArray(data.mc)) sdata.mc = data.mc;

    if(data.type === 'loc') {
      sdata.type = 'loc';
      sdata.cc = data.cc; // County
      sdata.cid = data.cid; // City
      setSelSearchData(sdata);

      setAccSearchStep2(false);
    }
    if(data.type === 'savedLoc') {
        sdata.slocid = data.slocid;
        setSelSearchData(sdata);

        setAccSearchStep2(false);
    }

    // Check if enable search button
    if(sdata.type === 'loc' && sdata.cc != '' && sdata.cid != '' && sdata.scat != '')
      searchOk = true;
    if(sdata.type === 'savedLoc' && sdata.slocid != '' && sdata.scat != '')
      searchOk = true;

    // console.log('Search=', data, sdata, searchOk);

    setBtnSearchDisabled(!searchOk);
  }

  function doSearch() {
    // gaEventTracker('doSearch');
    var sdata={
      d: 10,
    };

    if(selSearchData.type == 'loc') sdata.o = selSearchData.cid;
    if(selSearchData.type == 'savedLoc') sdata.osp = selSearchData.slocid;

    sdata.sc = selSearchData.scat;

    APIQuery('/search', sdata, (res) => {
      // console.log('Search results:', res.data);
      setSearchResultData(res.data);
      setAccSearchStep3(false);
    },
    (err) => { console.error(err); }
    );
  }

  // Render part

  return (
    <div className="search-container">

      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="search-header">
            <span className="search-nr">1</span>
            <h2 className="search-title">{t("searchPage_search_firstsection")}</h2>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={Tab1Value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleTab1Change}
                  aria-label="lab API tabs example"
                >
                  <Tab label={t("searchPage_search_firstsection_city")} value="1" />
                   {/*<Tab label={t("searchPage_search_firstsection_ownresidence")} value="2" /> */}
                </TabList>
              </Box>
              <TabPanel value="1">
                <SearchLocation
                  onLocationValueUpdate={onLocationValueUpdate}
                />
              </TabPanel>
              <TabPanel value="2">
                <SearchLocationSaved
                  onLocationValueUpdate={onLocationValueUpdate}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion disabled={accSearchStep2} expanded={!accSearchStep2}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1b-content"
          id="panel1b-header"
        >
          <div className="search-header">
            <span className="search-nr">2</span>
            <h2 className="search-title">{t("searchPage_search_secondsection_category")}</h2>
          </div>
        </AccordionSummary>

        <AccordionDetails>

          <Autocomplete
            disablePortal
            noOptionsText="No labels"
            options={cmbCatList}
            onChange={cmbCatListChange}
            getOptionSelected={(option, value) => option.label === value.label}
            sx={{ width: 300 }}
            getOptionLabel={(option) => option.label}
            getOptionDisabled={(option) => option.disabled}
            groupBy={(option) => option.gcatlabel}
            renderGroup={(params) => (
              <li key={params.key}>
                <div style={{paddingLeft: 20}}>{params.group}</div>
                <ul style={{paddingLeft: 40}}>{params.children}</ul>
              </li>
            )}

            renderInput={(params) => (
              <TextField {...params} label={t("searchPage_search_secondsection_maincategory")} />
            )}
          >
          </Autocomplete>

          <div style={{display: 'flex', justifyContent:'center', marginTop: '1em'}}>
          <Button
            variant="contained"
            disabled={btnSearchDisabled}
            className="continue-btn"
            onClick={doSearch}
          >
            {t("searchPage_search_secondsection_nextbutton")}
          </Button>
          </div>

        </AccordionDetails>
      </Accordion>

      <Accordion disabled={accSearchStep3} expanded={!accSearchStep3}>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className="search-header">
            <span className="search-nr">3</span>
            <h2 className="search-title">{t("searchPage_search_secondsection_result")}</h2>
          </div>
        </AccordionSummary>

        <AccordionDetails>
          <SearchResults data={searchResultData} searchCity={selSearchData}></SearchResults>
        </AccordionDetails>
      </Accordion>

      {/*<Help></Help> TODO: Tolti hibaval a console-t */}

    </div>
  );
};

export default SearchPage;
