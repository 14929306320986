import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
//search css
import './components/search-components/SearchPage.css';
import './i18n/config';
import DataProviderObj from './dataprovider';

var dataProvider = new DataProviderObj();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <App dprovider={dataProvider} />
    </BrowserRouter>
  </React.StrictMode>
);

