import React from 'react'
import { useLocation } from 'react-router-dom';
import { Navigate, useNavigate } from "react-router-dom";
import { useState } from 'react';

import './MyProfile.css';


const MyProfile = ({ }) => {
    const { state } = useLocation();
    const [ user, setUser ] = useState({});
    
function handleSignOut(event) {
// ures, mert nincs bejelentkezve
    setUser({});
    document.getElementById("profile").hidden = false;
  }
console.log(state)
    if (state == null) {
        return <Navigate replace to="/login" />;
    } else {
        const { user } = state;
        return (
            <div className='profile'>
                <h2>Saját profil</h2>
                <img className='userpic' src={user.picture}></img>
                <h3>{user.name}</h3>
                {/* <Navigate replace to="/login">
                <button className='btn' onClick={ (e) => handleSignOut(e)}>Kijelentkezés</button>
                </Navigate> */}
            </div>
        );
    }
}

export default MyProfile