import FormErrors from "../../addrequest-components/FormErrors";
import { Component } from "react";
import "../../addrequest-components/AddRequest.css";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import { useState } from "react";


// import { ProfileContext } from "../../../profile";
// import { LocationContext } from "../../../location";

//note: this is a Class baset react component
class AddRequest extends Component {
  // an other way to consume context. step 1.
  // static contextType = ProfileContext;
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      description:
        "Jo napot! \n A kovetkezo datumok kozott szeretnek segitseget kerni: 2022.11.11-2022.11.10. A ... romlott el. \n Koszonettel, ",
      formErrors: { description: "" },
      descriptionValid: false,
      formValid: false,
      mainCats: [
        {
          id: 1,
          label: "Viz szerelo",
          subCategory: [
            { id: 1, label: "Csap" },
            { id: 2, label: "Cso" },
            { id: 3, label: "Folyas" },
          ],
        },
        {
          id: 2,
          label: "Gaz szerelo",
          subCategory: [
            { id: 4, label: "Aragaz" },
            { id: 5, label: "Butelia" },
            { id: 6, label: "Kabel" },
          ],
        },
        {
          id: 3,
          label: "Autoszerviz",
          subCategory: [
            { id: 7, label: "Huto" },
            { id: 8, label: "TV" },
            { id: 9, label: "Telefon" },
          ],
        },
        {
          id: 4,
          label: "Szamitogep szerviz",
          subCategory: [
            { id: 10, label: "Proci" },
            { id: 11, label: "Merevlemez" },
            { id: 12, label: "Bongeszo" },
          ],
        },
        {
          id: 5,
          label: "Haztartasi gep szerelo",
          subCategory: [
            { id: 13, label: "Huto" },
            { id: 14, label: "TV" },
            { id: 15, label: "Telefon" },
          ],
        },
        {
          id: 6,
          label: "Villany szerelo",
          subCategory: [
            { id: 16, label: "Korte", loc: [46.3594, 25.8016] },
            { id: 17, label: "Csillar" },
            { id: 18, label: "Kabel" },
          ],
        },
      ],
      //gets the id of selected main cat
      selectedMainCat: {},
      //after set it gets the sub categories of the selected main cat
      selectedSubCat: [],
      //selected sub categories
      selectedSubCats: [],
      //stores the selected loc id
      selectedLocId: [],
      userProfile: [],
      location: props.location,
      user: props.user,
    };
  }
  //an other way to consume context. step 2.
  // componentDidMount() {
  //   const profile = this.context
  //   this.setState({userProfile: profile[0][0].myaddresses});

  //   console.log(this.state.location[0])
  //   console.log(this.state.user[0][0].myaddresses)
  // }

  //input handler for onChange
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  handleCatInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ selectedMainCat: value });
    const sCat = this.state.mainCats.find((x) => x.id == value);
    this.setState({ selectedSubCat: sCat.subCategory });
  };


  handleChange = (event) => {
    console.log(event);
    this.setState({ checked: event.target.checked });
    // this.setState(selectedSubCats => ({
    //   arrayvar: [...selectedSubCats.arrayvar, newelement]
    // }))
    //todo: radiobox value: 0
  };

  handleChangeSubTogggleBtn = (event, newAlignment) => {
    //newAlignment: contains the list of selected subcats
    this.setState({ selectedSubCats: newAlignment });
  };

  //check if the user submitted right values. if not add errors
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let descriptionValid = this.state.descriptionValid;

    switch (fieldName) {
      case "description":
        descriptionValid = value.length >= 10;
        fieldValidationErrors.description = descriptionValid
          ? ""
          : " Tul Rovid Leiras. Legalabb 10 karakter hasznalj";
        break;
      default:
        break;
    }
    this.setState(
      { formErrors: fieldValidationErrors, descriptionValid: descriptionValid },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({ formValid: this.state.descriptionValid });
  }

  //add error-class for more visible errors
  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  handleButtonClicked() {
    var selectedMainCat = this.state.selectedMainCat;
    var selectedSubCats = this.state.selectedSubCats;
    var selectedLocId = this.state.selectedLocId;
    var description = this.state.description;
    console.log(selectedMainCat )


    window.location.href = "https://youtube.com/results?search_query" + selectedMainCat;
  }

  render() {
    return (
      <div className="add-request-container">
        <div>
        {/* <form className="demoForm" action="https://www.youtube.com/results" method="GET"> */}
          <h2>Keres</h2>
          <br></br>
          <label htmlFor="selectedMainCat">Fo kategoria:</label>
          <select
            name="selectedMainCat"
            id="selectedMainCat"
            onChange={this.handleCatInput}
            value={this.state.selectedMainCat}
          >
            {this.state.mainCats.map((mainCat) => (
              <option key={mainCat.id} value={mainCat.id}>
                {mainCat.label}
              </option>
            ))}
          </select>
          <p>Al kategoria:</p>
          {this.state.selectedSubCat.length > 0 ? (
            <ToggleButtonGroup
              value={this.state.selectedSubCats}
              onChange={this.handleChangeSubTogggleBtn}
              aria-label="text formatting"
            >
              {this.state.selectedSubCat.map((subCat) => (
                <ToggleButton key={subCat.id} value={subCat.id} aria-label="color">
                  <span>{`${subCat.label}`}</span>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          ) : (
            <p>valassz fo kategoriat</p>
          )}
          <p>Cim:</p>
          <div onChange={event =>  this.setState({ selectedLocId: event.target.id })}>
          {this.state.user[0][0].myaddresses.map((addres) => (
            < >
              <input
                type="radio"
                id={addres.id}
                name="addres"
                value={addres.id}
                key={addres.id}
              />
               <label htmlFor={addres.id}>{addres.label}</label>
            </>
          ))}
          </div>
          
          <Link to="/userprofile" style={{ textDecoration: 'underline', color: 'blue' }}>
            Cimek szerkesztese/ Uj Cim hozzaadasa
          </Link>
          <FormErrors formErrors={this.state.formErrors} />
          <div
            className={`form-group-add-request ${this.errorClass(
              this.state.formErrors.description
            )}`}
          >
            <label htmlFor="description">Leiras</label>
            <textarea
              required
              className="form-control-textarea"
              name="description"
              rows="4"
              cols="50"
              maxLength="500"
              placeholder="Leiras"
              value={this.state.description}
              onChange={this.handleUserInput}
            />
            <p>{this.state.description.length} karakter</p>
          </div>
          <button
            className="btn btn-primary"
            disabled={!this.state.formValid}
            onClick={this.handleButtonClicked}
          >
            Kuldes
          </button>
        </div>
      </div>
    );
  }
}

export default AddRequest;
