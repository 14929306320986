import './Login.css';
import { useState, useContext } from 'react';
import { useEffect } from 'react';
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import { UserRoleContext} from "../../../../userRole";
import { APIQuery } from '../../../../api';
import api from '../../../../api';
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProfileContext } from '../../../../profile';


export default function LoginProvider() {


    // Bejelentkezes állapot
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
   
    // Hiba ellenőrzés
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
   
    // Név változtatás kezelés
    const handleName = (e) => {
      setEmail(e.target.value);
      setSubmitted(false);
    }; 
   
   
    // Jelszo valtoztatas kezeles
    const handlePassword = (e) => {
      setPassword(e.target.value);
      setSubmitted(false);
    };

    const navigateTo = useNavigate();
    const [profile, setProfile] = useContext(ProfileContext);
   
    // Forms submit kezeles
    const handleSubmit = (e) => {
      e.preventDefault();
      
      var sha1 = require('sha1');

      APIQuery('/login/preflight', null, 
        (data) => { 
          if(typeof(data.s) != 'string') {
            // Server response error, cannot continue the login process
            console.error("Invalid server response", data);
            // TODO: display error
            return;
          }
          // Continue login
          var pw = sha1(data.s + sha1(password) + data.s);
          console.log('sending data: ' + pw)
          APIQuery('/login/dsl', {e: email, p: pw}, 
            (data) => {
                if(!data.status) { 
                  console.error('Login error', data);
                  // TODO: set display message
                  return; 
                }
                // Login success, redirect to dashboard
                
                // data contains all supplier information
                // data.role is 'supplier' or 'user'
                console.log(data); 
                delete data.status;
                setProfile(data); 
                localStorage.setItem('profile', JSON.stringify(data));
              navigateTo("/companyprofile");
            }, 
            (err) => {
              // Login failed, display message
              // TODO: set display message
              console.error('Login failed:', err);
            }
          );
        },
        (err) => { console.error('We have an error:', err); }
      );
      
    };
   
    // Sikeres bejeletkezes uzenet
    const successMessage = () => {
      return (
        <div
          className="success"
          style={{
            display: submitted ? '' : 'none',
          }}>
          <h1>Sikeresen bejeletkezés!😀</h1>
        </div>
      );
    };

    const { i18n } = useTranslation();

    function changeLanguage(e) {
      i18n.changeLanguage(e.currentTarget.value);
    }
   const { t } = useTranslation();
   
    // Ha a hiba igaz error uzenet
    const errorMessage = () => {
   
    return (
    <div
          className="error"
          style={{
            display: error ? '' : 'none',
          }}>
          <h1>Nincs minden mező kitöltve!</h1>
        </div>
      );
    };


    const [ user, setUser ] = useState({});

    function handleCallbackResponse(response) {
      console.log("Encoded JWT ID token: " + response.credential);
      var userObject = jwt_decode(response.credential);
      console.log(userObject);
      setUser(userObject);
      //a bejelentkezes utan eltunik
      // document.getElementById("signInDiv").hidden = true;
    }

    function handleSignOut(event) {
      //ures, mert nincs bejelentkezve
      setUser({});
      document.getElementById("signInDiv").hidden = false;
    }

    
    return (
      <div className="form">
        <h1 className='loginTitle'>{t("Login_login_tiltle_as_provider")}</h1>
        <div className='login-wrapper'>
        <div className="left">
          
        </div>
        </div>
     
        {/* Calling to the methods */}
        <div className="messages">
          {errorMessage()}
          {successMessage()}
        </div>
   
        <form>
            <div>
          {/* Labels and inputs for form data */}
          <label className="label">Email </label>
          <input onChange={handleName} className="input"
            value={email} type="text" />
            </div> 
            <div>
          <label className="label">{t("Login_login_password_as_provider")}</label>
          <input onChange={handlePassword} className="input"
            value={password} type="password" />
            </div>
            <div className='login'>
            <Link to='../MyProfile'><button onClick={handleSubmit} className="btn" type="submit">
            {t("Login_login_tiltle_as_provider")}
          </button></Link>
          <br></br>
          <div>
          </div>
          <div className='Log'>
            <div id="signInDiv"></div>
            { Object.keys(user).length !=0 &&
                             <Navigate replace to="/Profile" state={{user}}/>

              // <button onClick={ (e) => handleSignOut(e)}>Sign Out</button>
            }
{/*           
           

            } */}
          </div>
          </div>
          <div className='registration'>
            <h3>{t("Login_login_tiltle_as_user_registrate")}</h3>
          <Link to='../SingUpProvider'><button className="btn2" type="submit">
          {t("Login_login_tiltle_as_user_registrate_button")}
          </button></Link>
          </div>
        </form>
      </div>
    )
}