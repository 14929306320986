import React from "react";
import { useTranslation } from "react-i18next";
import './TopServices.css'

function TopServices (props) {

    const { i18n } = useTranslation();

    function changeLanguage(e) {
        i18n.changeLanguage(e.currentTarget.value);
    }
    const { t } = useTranslation();
    const icon = React.createElement(props.icon, {});

    return (
        <div className="descriptions2">
            <div className="card2">
                <div className="card_body2">
                    {/* <h2 className="card_title2">{props.title}</h2> */}
                    <p className="card_description2">{props.description}</p>
                </div>
                <div className="card_icon2">
                    {/* <button className="btn">Érdekel</button> */}
                </div>
            </div>
        </div>
    )

}

export default TopServices