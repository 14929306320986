import React from "react";
import { useTranslation } from "react-i18next";
import { FiHelpCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import './Help.css'
import { light } from "@mui/material/styles/createPalette";


const Help = (props) => {

    const { i18n } = useTranslation();

    function changeLanguage(e) {
        i18n.changeLanguage(e.currentTarget.value);
    }
    const { t } = useTranslation();
    const icon = React.createElement(props.icon, {});
    return (
        <div className="help-button-wrapper">
            <Link to='/HelpPage'>
            <button className="help-button">
                <span><FiHelpCircle></FiHelpCircle></span>
                Get Help
            </button>
            </Link>
        </div>
    )

}

export default Help