import React, { Component } from 'react';
import { Stepper } from 'react-form-stepper';
import Profile from '../Profile';
import { Link, useNavigate } from "react-router-dom";
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import i18next, { t } from 'i18next';
import '../../Footer/TermsAndConditions';
import './SingUp.css';
import ReactGA from 'react-ga';

ReactGA.initialize("UA-267961577-1");

class Summary extends Component {

  constructor(props){
    super(props)
    this.state={
      agree:false
    }
  }

  checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    this.setState({agree:!this.state.agree});
    // Don't miss the exclamation mark
  }

  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }



  render() {
   

    const {

      company,
      individual,
      companyname,
      email,
      phone,
      cui,
      address,
      bankName,
      website,
      describe,
      socialmedia,
      submitData,
      isErrorSignup

    } = this.props;



    return (

      <div className='form'>    
        <div>
          <Stepper
            steps={[{ label: t("Summary_company_personal_details") }, { label: t('Summary_company_information') }, { label: t('Summary_company_summary') }]}
            activeStep={2}
            styleConfig={{
              activeBgColor: '#2b7cff',
              activeTextColor: '#fff',
              inactiveBgColor: '#fff',
              inactiveTextColor: '#2b7cff',
              completedBgColor: '#fff',
              completedTextColor: '#2b7cff',
              size: '3em'
            }}
            className={'stepper'}
            stepClassName={'stepper__step'}
          />

          <div className='summary'>
            <h2 className='summary__heading'>{t('Summary_confirm_your_personal_data')}</h2>
            <div>
              {/* <div>
                <p><span className='summary__item-title'>Pozicíó:</span> { company } </p>
              </div> */}
              <div>
                <p><span className='summary__item-title'> {t('Summary_companyname')}:</span> {companyname} </p>
              </div>
              <div>
                <p><span className='summary__item-title'>Email:</span> {email}</p>
              </div>
              <div>
                <p><span className='summary__item-title'>{t("CourseDeatils_phone_number")}:</span> {phone}</p>
              </div>
              <div>
                <p><span className='summary__item-title'> {t("CourseDeatils_cod_unic_de_inregistrare")}:</span> {cui}</p>
              </div>
              <div>
                <p><span className='summary__item-title'>{t("CourseDeatils_company_location")}:</span> {address}</p>
              </div>
              {/* <div>
                <p><span className='summary__item-title'>{t("ServiceDetails_company_decription")}:</span> {describe}</p>
              </div> */}
            </div>
          </div>
          { isErrorSignup == true ?
            <div class="alert alert-danger" role="alert">
              {t("Summary_registration_fail")}
            </div>
            :
            ''
          }
          
          {/* <div className='summary'>
            <h2 className='summary__heading'>Confirm your course details</h2>
            <div>
              <div>
                <p><span className='summary__item-title'>Level:</span> { chosenLevel }</p>
              </div>
              <div>
                <div><span className='summary__item-title'>Courses:</span> { coursesChosenSummary }</div>
              </div>
            </div>
          </div> */}
             <div>
          <input type="checkbox" id="agree" onChange={this.checkboxHandler} checked={this.state.agree} />
          <label htmlFor="agree"> I agree to <Link to='/TermsAndConditions' target="_blank" rel="noopener noreferrer">terms and conditions</Link></label>
        </div>
          <div className='buttons'>
            <button className='buttons__button buttons__button--back' onClick={this.back}>{t("Regist_provider_steps_back")}</button>
            <Link to='../Profile'>
              <button className='buttons__button buttons__button--next' disabled={!this.state.agree} type='submit' onClick={submitData}>{t("Regist_provider_steps_submit")}</button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Summary;